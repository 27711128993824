import moment from 'moment';
import { LogApproach, IBackendLogApproach, ILogApproach } from './log-approach.model';
import { LogHold, IBackendLogHold, ILogHold } from './log-hold.model';
import { LogTask, IBackendLogTask, ILogTask } from './log-task.model';

export interface IBackendLog {

	id                    : number;
	event_id              : number | string;
	user_id               : number;
	default_values        : number;
	flight_date           : Date;
	flight_from           : string;
	flight_stops          : string;
	flight_to             : string;
	time_day              : number;
	time_night            : number;
	takeoffs_day          : number;
	takeoffs_night        : number;
	takeoffs_tower_day    : number;
	takeoffs_tower_night  : number;
	landings_day          : number;
	landings_tower_day    : number;
	landings_night        : number;
	landings_tower_night  : number;
	total_time            : number;
	time_cross            : number;
	time_solo             : number;
	time_pic              : number;
	time_crew             : number;
	time_actual           : number;
	time_simulation       : number;
	remarks              ?: string;
	instructor_name       : string;
	student_name          : string;
	scenario_name         : string;
	post_flight_review    : number;
	asel                 ?: number;
	amel                 ?: number;
	pilot_instructor     ?: number;
	flight_id            ?: number;
	equipment_model       ?: string;
	equipment_tail_number ?: string;
	course                ?: string;
	lesson                ?: string;
	time_dual             ?: number;
	simulator             ?: number;

	event?: any;
	holds?: IBackendLogHold[];
	approaches?: IBackendLogApproach[];
	tasks?: IBackendLogTask[];

	notes              ?: string;
	post_recommended   ?: number;
}

export interface ILog{

	id                   : number;
	event_id             : number | string;
	user_id              : number;
	default_values       : number;
	flight_date          : Date;
	flight_from          : string;
	flight_stops         : string;
	flight_to            : string;
	time_day             : number;
	time_night           : number;
	takeoffs_day         : number;
	takeoffs_night       : number;
	takeoffs_tower_day   : number;
	takeoffs_tower_night : number;
	landings_day         : number;
	landings_tower_day   : number;
	landings_night       : number;
	landings_tower_night : number;
	total_time           : number;
	time_cross           : number;
	time_solo            : number;
	time_pic             : number;
	time_crew            : number;
	time_actual          : number;
	time_simulation      : number;
	remarks             ?: string;
	instructor_name      : string;
	student_name         : string;
	scenario_name        : string;
	post_flight_review   : number;
	asel                 ?: number;
	amel                 ?: number;
	pilot_instructor     ?: number;
	flight_id            ?: number;
	equipment_model       ?: string;
	equipment_tail_number ?: string;
	course                ?: string;
	lesson                ?: string;
	time_dual             ?: number;
	simulator             ?: number;

	event?: any;
	holds?: ILogHold[];
	approaches?: ILogApproach[];
	tasks?: ILogTask[];

	notes?: string;
	post_recommended?: number;
}

export class Log implements ILog {
	static readonly clean = Object.freeze(new Log());

	id                   = 0;
	event_id             = undefined;
	user_id              = 0;
	default_values       = 0;
	flight_date          : Date;
	flight_from          = '';
	flight_stops         = '';
	flight_to            = '';
	time_day             = 0;
	time_night           = 0;
	takeoffs_day         = 0;
	takeoffs_tower_day   = 0;
	takeoffs_night       = 0;
	takeoffs_tower_night = 0;
	landings_day         = 0;
	landings_tower_day   = 0;
	landings_night       = 0;
	landings_tower_night = 0;
	total_time           = 0;
	time_cross           = 0;
	time_solo            = 0;
	time_pic             = 0;
	time_crew            = 0;
	time_actual          = 0;
	time_simulation      = 0;
	remarks              = '';
	instructor_name      = '';
	student_name         = '';
	scenario_name        = '';
	post_flight_review   = 0;
	asel                 = 0;
	amel                 = 0;
	pilot_instructor     = 0;
	flight_id            = 0;
	equipment_model       = '';
	equipment_tail_number = '';
	course                = '';
	lesson                = '';
	time_dual             = 0;
	simulator             = 0;

	event?: any;
	holds?: LogHold[] = [];
	approaches?: LogApproach[] = [];
	tasks?:  ILogTask[] = [];

	notes              = '';
	post_recommended =  0;

	parse(obj: IBackendLog) {
		this.id             = Number(obj.id              || Log.clean.id             );
		this.event_id       =       (obj.event_id        || Log.clean.event_id       );
		this.user_id        = Number(obj.user_id         || Log.clean.user_id        );
		this.default_values = Number(obj.default_values  || Log.clean.default_values );

		/**
		 * These moment.utc functions are behaving strangely, were an hour off :c
		 * so we're using offset to convert to local times
		 */
		// const offset = Number(new Date().getTimezoneOffset() / 60);
		// this.flight_date = obj.flight_date ? moment(obj.flight_date ).subtract(offset, 'hours').toDate() : Log.clean.flight_date ;
		this.flight_date = obj.flight_date ? moment(obj.flight_date )                           .toDate() : Log.clean.flight_date ;


		this.flight_from          = String(obj.flight_from          || Log.clean.flight_from          );
		this.flight_stops         = String(obj.flight_stops         || Log.clean.flight_stops         );
		this.flight_to            = String(obj.flight_to            || Log.clean.flight_to            );
		this.time_day             = Number((Number(obj.time_day             || Log.clean.time_day             )).toFixed(2));
		this.time_night           = Number((Number(obj.time_night           || Log.clean.time_night           )).toFixed(2));
		this.takeoffs_day         = Number((Number(obj.takeoffs_day         || Log.clean.takeoffs_day         )).toFixed(2));
		this.takeoffs_tower_day   = Number((Number(obj.takeoffs_tower_day   || Log.clean.takeoffs_tower_day   )).toFixed(2));
		this.takeoffs_night       = Number((Number(obj.takeoffs_night       || Log.clean.takeoffs_night       )).toFixed(2));
		this.takeoffs_tower_night = Number((Number(obj.takeoffs_tower_night || Log.clean.takeoffs_tower_night )).toFixed(2));
		this.landings_day         = Number((Number(obj.landings_day         || Log.clean.landings_day         )).toFixed(2));
		this.landings_tower_day   = Number((Number(obj.landings_tower_day   || Log.clean.landings_tower_day   )).toFixed(2));
		this.landings_night       = Number((Number(obj.landings_night       || Log.clean.landings_night       )).toFixed(2));
		this.landings_tower_night = Number((Number(obj.landings_tower_night || Log.clean.landings_tower_night )).toFixed(2));
		this.total_time           = Number((Number(obj.total_time           || Log.clean.total_time           )).toFixed(2));
		this.time_cross           = Number((Number(obj.time_cross           || Log.clean.time_cross           )).toFixed(2));
		this.time_solo            = Number((Number(obj.time_solo            || Log.clean.time_solo            )).toFixed(2));
		this.time_pic             = Number((Number(obj.time_pic             || Log.clean.time_pic             )).toFixed(2));
		this.time_crew            = Number((Number(obj.time_crew            || Log.clean.time_crew            )).toFixed(2));
		this.time_actual          = Number((Number(obj.time_actual          || Log.clean.time_actual          )).toFixed(2));
		this.time_simulation      = Number((Number(obj.time_simulation      || Log.clean.time_simulation      )).toFixed(2));
		this.remarks              = String(obj.remarks ? (obj.remarks === 'null' ? '' : obj.remarks) : Log.clean.remarks              ); // TODO: backend should not return string 'null' when its an empty string
		this.post_flight_review   = Number((Number(obj.post_flight_review   || Log.clean.post_flight_review   )).toFixed(2));
		this.instructor_name      = String(obj.instructor_name      || Log.clean.instructor_name      );
		this.student_name         = String(obj.student_name         || Log.clean.student_name         );
		this.scenario_name        = String(obj.scenario_name        || Log.clean.scenario_name        );
		this.asel                 = Number((Number(obj.asel                 || Log.clean.asel                 )).toFixed(2));
		this.amel                 = Number((Number(obj.amel                 || Log.clean.amel                 )).toFixed(2));
		this.pilot_instructor     = Number((Number(obj.pilot_instructor     || Log.clean.pilot_instructor     )).toFixed(2));
		this.flight_id            = Number((Number(obj.flight_id            || Log.clean.flight_id            )).toFixed(2));
		this.equipment_model       = String(obj.equipment_model       || Log.clean.equipment_model       );
		this.equipment_tail_number = String(obj.equipment_tail_number || Log.clean.equipment_tail_number );
		this.course                = String(obj.course ? (obj.course === 'null' ? '' : obj.course) : Log.clean.course                ); // TODO: backend should not return string 'null' when its an empty string
		this.lesson                = String(obj.lesson ? (obj.lesson === 'null' ? '' : obj.lesson) : Log.clean.lesson                ); // TODO: backend should not return string 'null' when its an empty string
		this.time_dual             = Number((Number(obj.time_dual             || Log.clean.time_dual             )).toFixed(2));
		this.simulator             = Number((Number(obj.simulator             || Log.clean.simulator             )).toFixed(2));

		this.event                = (obj.event      || Log.clean.event);
		this.holds                = (obj.holds      || []).map((item ) => new LogHold     ().parse(item));
		this.approaches           = (obj.approaches || []).map((item ) => new LogApproach ().parse(item));
		this.tasks                = (obj.tasks      || []).map((item ) => new LogTask     ().parse(item));

		this.notes              = String(obj.notes ? (obj.notes === 'null' ? '' : obj.notes) : Log.clean.notes              );
		this.post_recommended   = Number((Number(obj.post_recommended   || Log.clean.post_recommended   )).toFixed(2));
		return this;
	}

	set(obj: ILog) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);
		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = Log.clean[key];
		});
		return this;
	}

	clone() {
		return new Log().set(this);
	}
}
