import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../../models';
import { Event } from '../../models/event.model';

@Pipe({
	name: 'showEventCancelButton'
})
export class ShowEventCancelButtonPipe implements PipeTransform {

	transform(event: Event, user: User): boolean {

		if (
			event.client?.id === user?.id ||
			event.crewIds.length && event.crewIds.some ((id: number) => id === user?.id)
		) {
			// console.log(`Role ${(user?.roleId || 'no role!')}`, 'reservationsCancelOwn', (user?.permissions?.['reservationsCancelOwn']));
			return Boolean(user?.permissions?.['reservationsCancelOwn']);
		}

		return true;
	}

}
