{

	"instructorsAvailabilityScheduleView": false,
	"instructorsAvailabilityScheduleCreateEdit": false,
	
	"loaRequestView": false,
	"loaRequestAccceptOrDeny": false,
	"graduationRequestView": false,
	"graduationRequestAccceptOrDeny": false,
	"checkRideRequestView": false,
	"checkRideRequestAccceptOrDeny": false,

	"viewAllReservations": false,
	"viewSelfReservations": true,
	"viewReservationOfAssignedStudents": false,
	"schedOMatic": false,
	"availabilityFinder": false,

	"reservationsCreateOwn": true,
	"reservationsEditOwn": true,
	"reservationsCancelOwn": true,
	"reservationsCreateForOthers": false,
	"reservationsEditForOthers": false,
	"reservationsCancelForOthers": false,
	"reservationsCreateForAssignedStudents": false,
	"reservationsEditForAssignedStudents": false,
	"reservationsCancelForAssignedStudents": false,
	"reservationsCheckOutOther": false,
	"reservationsCheckInOther": false,
	"reservationsCheckOutOwn": false,
	"reservationsCheckInOwn": false,
	"reservationsCheckOutAssignedStudents": false,
	"reservationsCheckInAssignedStudents": false,

	"reservationsDispatchSheet": true,
	"reservationsGenerateInvoices": true,
	"reservationsViewInvoices": true,

	"usersCreateClient": false,
	"usersCreateTeamMember": false,
	"usersViewAllClientList": true,
	"usersViewAllTeamMemberList": true,
	"usersViewOnlyAsignedClientList": false,
	"usersViewOnlySelfClientOrTeamMember": false,

	"clientViewGeneralInfo": true,

	"clientDeleteDisable": false,
	"clientEditUpdate": false,
	"clientEditSelf": false,
	"clientEnroll": false,
	"clientViewRecord": false,
	"clientRequestGraduation": false,
	"clientRequestCheckRide": false,

	"clientCredentialsView": false,
	"clientCredentialsAdd": false,
	"clientCredentialsResolve": false,
	"clientCredentialsDisableEnable": false,

	"clientCurrencyCheckoutsEnableDisable": false,
	"clientCurrencyCheckoutsView": false,

	"clientFlightLogAdd": false,
	"clientFlightLogEdit": false,
	"clientFlightLogDelete": false,
	"clientFlightLogView": false,

	"clientLessonLogEdit": false,
	"clientLessonLogView": false,

	"teamMemberUsersGeneralView": true,
	"teamMemberUsersDeleteDisable": false,
	"teamMemberUsersEditUpdate": false,
	"teamMemberUsersEditSelf": true,

	"teamMemberUserCredentialsView": false,
	"teamMemberUserCredentialsAdd": false,
	"teamMemberUserCredentialsResolve": false,
	"teamMemberUserCredentialsDisableEnable": false,

	"teamMemberUserCurrencyCheckoutsEnableDisable": false,
	"teamMemberUserCurrencyCheckoutsView": false,

	"teamMemberFlightLogAddOwnEntry": false,
	"teamMemberFlightLogEditOwnEntry": false,
	"teamMemberFlightLogViewAllEntries": false,




	"coursesCreate": false,
	"coursesEdit": false,
	"coursesDisable": false,
	"coursesViewDetails": false,
	"coursesCheckRideFailReasonsDropdowns": false,

	"equipmentCreate": false,
	"equipmentEdit": false,
	"equipmentDisable": false,
	"equipmentViewDetails": true,
	"equipmentCreateSquawks": false,
	"equipmentResolveSquawks": false,
	"equipmentViewSquawks": false,
	"equipmentLeaseback": true,

	"equipmentCredentialsView": false,
	"equipmentCredentialsAdd": false,
	"equipmentCredentialsResolve": false,
	"equipmentCredentialsDisableEnable": false,




	"credentialsCreate": false,
	"credentialsEdit": false,
	"credentialsDisable": false,
	"credentialsDelete": false,
	"credentialsViewDetails": false,

	"groupsCreateEquipmentGroup": false,
	"groupsEditEquipmentGroup": false,
	"groupsDeleteEquipmentGroup": false,
	"groupsViewEquipmentGroup": false,
	"groupsCreateInstructorGroup": false,
	"groupsEditInstructorGroup": false,
	"groupsDeleteInstructorGroup": false,
	"groupsViewInstructorGroup": false,

	"ftaStudentsViewStudentList": true,

	"ftaComplianceFromFtaToTheUniversityUpload": false,
	"ftaComplianceFromFtaToTheUniversityResolve": false,
	"ftaComplianceFromFtaToTheUniversityDisable": false,
	"ftaComplianceFromFtaToTheUniversityViewDocuments": true,

	"universityComplianceFromTheUniversityToTheFtaAcknowledgementMarkAsUnread": false,
	"universityComplianceFromTheUniversityToTheFtaViewDocuments": true,

	"ftaAccountInfoViewAccountInfo": true,
	"ftaAccountInfoEditAccountInfo": true,

	"settingsEditGeneralSettings": false,
	"settingsEditPermissions": false,

	"billingAccountingSettings": true,

	"gradingGradeStudent": false
}