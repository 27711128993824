import { CheckrideRequest, IBackendCheckrideRequest } from 'src/app/_core/models/checkride-request.model';
import moment from 'moment';

export interface IBackendPivot {
	start_date      ?: Date;
	end_date        ?: Date;
	check_ride_date ?: Date;
	user_id              ?: number;
	status               ?: number;
	is_complete          ?: number;
	syllabus_course_id   ?: number;
	instructor_id ?: number;
	instructor_name ?: string;
	user_subtype ?: number;
	course_key ?: string;
	checkrideRequest ?: IBackendCheckrideRequest;
}

export interface IPivot {
	startDate     ?: Date;
	endDate       ?: Date;
	checkRideDate ?: Date;
	userId        ?: number;
	status        ?: number;
	isComplete    ?: number;
	courseId      ?: number;
	instructorId ?: number;
	instructorName ?: string;
	courseRegulation ?: number;
	courseKey ?: string;
	checkrideRequest ?: CheckrideRequest;
}

export class Pivot implements IPivot {
	static readonly clean = Object.freeze(new Pivot());
	startDate     ?: Date;
	endDate       ?: Date;
	checkRideDate ?: Date;
	userId     = 0;
	status     = 0;
	isComplete = 0;
	courseId   = 0;
	instructorId = 0;
	instructorName = "";
	courseRegulation = 0;
	courseKey = "";
	checkrideRequest?: CheckrideRequest;

	parse(obj: IBackendPivot) {
		this.startDate     = obj.start_date      ? moment.utc(obj.start_date     ).toDate() : Pivot.clean.startDate    ;
		this.endDate       = obj.end_date        ? moment.utc(obj.end_date       ).toDate() : Pivot.clean.endDate      ;
		//this.checkRideDate = obj.check_ride_date ? moment.utc(obj.check_ride_date).toDate() : Pivot.clean.checkRideDate;
		this.checkRideDate = obj.check_ride_date ? moment(obj.check_ride_date).toDate() : Pivot.clean.checkRideDate;

		/**
		 * These moment.utc functions are behaving strangely, were an hour off :c
		 * so we're using offset to convert to local times
		 */
		const offset = Number(new Date().getTimezoneOffset() / 60);
		this.startDate     = obj.start_date      ? moment(obj.start_date      ).subtract(offset, 'hours').toDate() : Pivot.clean.startDate     ;
		this.endDate       = obj.end_date        ? moment(obj.end_date        ).subtract(offset, 'hours').toDate() : Pivot.clean.endDate       ;
		//this.checkRideDate = obj.check_ride_date ? moment(obj.check_ride_date ).subtract(offset, 'hours').toDate() : Pivot.clean.checkRideDate ;

		this.userId     = Number(obj.user_id            || Pivot.clean.userId    );
		this.status     = Number(obj.status             || Pivot.clean.status    );
		this.isComplete = Number(obj.is_complete        || Pivot.clean.isComplete);
		this.courseId   = Number(obj.syllabus_course_id || Pivot.clean.courseId  );
		this.instructorId   = Number(obj.instructor_id || Pivot.clean.instructorId  );
		this.instructorName   = String(obj.instructor_name || Pivot.clean.instructorName  );
		this.courseRegulation   = Number(obj.user_subtype || Pivot.clean.courseRegulation  );
		this.courseKey   = String(obj.course_key || Pivot.clean.courseKey  );

		this.checkrideRequest = 'checkrideRequest' in obj ?  new CheckrideRequest().parse(obj.checkrideRequest) : new CheckrideRequest ();

		return this;
	}

	set(obj: IPivot) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = Pivot.clean[key];
		});

		return this;
	}

	clone() {
		return new Pivot().set(this);
	}
}
