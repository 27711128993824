import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../../models';

@Pipe({
	name: 'permission'
})
export class PermissionPipe implements PipeTransform {


	/**
	 * Returns a boolean depending if current user
	 * can or not perform a certain functionallity
	 */
	transform(actionName: string, user: User): boolean {
		// console.log(`Role ${(user?.roleId || 'no role!')}`, actionName, (user?.permissions?.[actionName]));
		return Boolean(user?.permissions?.[actionName]);
	}

}
