import moment from 'moment';

export interface IBackendCheckout {
	created_at      ?: Date;
	updated_at      ?: Date;
	expires_on      ?: Date;
	expires         ?: number;
	id              ?: number;
	can_fly         ?: number;
	can_teach       ?: number;
	can_grade       ?: number;
	manufacturer    ?: string;
	model           ?: string;
	tail_number     ?: string;
	type            ?: string;
	name            ?: string;
	abbreviation    ?: string;
	description     ?: string;
	faa_course_type ?: string;
	title           ?: string;
	text            ?: string;
	updated_by      ?: string;
	logs            ?: IBackendCheckout[];
}

export interface ICheckout {
	createdAt     ?: Date;
	updatedAt     ?: Date;
	expiresOn     ?: Date;
	expires       ?: boolean;
	id            ?: number;
	canFly        ?: number;
	canTeach      ?: boolean;
	canGrade      ?: boolean;
	manufacturer  ?: string;
	model         ?: string;
	tailNumber    ?: string;
	type          ?: string;
	name          ?: string;
	abbreviation  ?: string;
	description   ?: string;
	faaCourseType ?: string;
	title         ?: string;
	text          ?: string;
	updatedBy     ?: string;
	logs          ?: ICheckout[];
}

export class Checkout implements ICheckout {
	static readonly clean = Object.freeze(new Checkout());
	createdAt     ?: Date;
	updatedAt     ?: Date;
	expiresOn     ?: Date;
	expires       = false;
	id            = 0;
	canFly        = 0;
	canTeach      = false;
	canGrade      = false;
	manufacturer  = '';
	model         = '';
	tailNumber    = '';
	type          = '';
	name          = '';
	abbreviation  = '';
	description   = '';
	faaCourseType = '';
	title         = '';
	text          = '';
	updatedBy     = '';
	logs          : Checkout[] = [];

	hasExpired   = false;

	parse(obj: IBackendCheckout) {
		// this.createdAt    = obj.created_at ? moment.utc(obj.created_at).toDate() : Checkout.clean.createdAt;
		// this.updatedAt    = obj.updated_at ? moment.utc(obj.updated_at).toDate() : Checkout.clean.updatedAt;
		// this.expiresOn    = obj.expires_on ? moment.utc(obj.expires_on).toDate() : Checkout.clean.expiresOn;
		this.expiresOn    = obj.expires_on ? moment(obj.expires_on).toDate() : Checkout.clean.expiresOn;

		/**
		 * These moment.utc functions are behaving strangely, were an hour off :c
		 * so we're using offset to convert to local times
		 */
		const offset = Number(new Date().getTimezoneOffset() / 60);
		this.createdAt = obj.created_at ? moment(obj.created_at).toDate() : Checkout.clean.createdAt;
		this.updatedAt = obj.updated_at ? moment(obj.updated_at).toDate() : Checkout.clean.updatedAt;

		this.expires        = Boolean(obj.expires         || Checkout.clean.expires      );
		this.id             = Number (obj.id              || Checkout.clean.id           );
		this.canFly         = Number (obj.can_fly         || Checkout.clean.canFly       );
		this.canTeach       = Boolean(obj.can_teach       || Checkout.clean.canTeach     );
		this.canGrade       = Boolean(obj.can_grade       || Checkout.clean.canGrade     );
		this.manufacturer   = String (obj.manufacturer    || Checkout.clean.manufacturer );
		this.model          = String (obj.model           || Checkout.clean.model        );
		this.tailNumber     = String (obj.tail_number     || Checkout.clean.tailNumber   );
		this.type           = String (obj.type            || Checkout.clean.type         );
		this.name           = String (obj.name            || Checkout.clean.name         );
		this.abbreviation   = String (obj.abbreviation    || Checkout.clean.abbreviation );
		this.description    = String (obj.description     || Checkout.clean.description  );
		this.faaCourseType  = String (obj.faa_course_type || Checkout.clean.faaCourseType);
		this.title          = String (obj.title           || Checkout.clean.title        );
		this.text           = String (obj.text            || Checkout.clean.text         );
		this.updatedBy      = String(obj.updated_by       || Checkout.clean.updatedBy    );
		this.logs           = (obj.logs    || []).map((item) => new Checkout().parse(item));

		this.hasExpired = !this.expiresOn || moment(this.expiresOn).isSameOrBefore(moment());

		return this;
	}

	set(obj: ICheckout) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = Checkout.clean[key];
		});

		return this;
	}

	clone() {
		return new Checkout().set(this);
	}
}
