export interface IBackendRate {
	id    : number;
	amount: number;
	name  : string;
}

export interface IRate {
	id    : number;
	amount: number;
	name  : string;
}

export class Rate implements IRate {
	static readonly clean = Object.freeze(new Rate());
	id     = 0 ;
	amount = 0 ;
	name   = '';	

	parse(obj: IBackendRate) {
		this.id     = Number(obj.id 	|| Rate.clean.id        );
		this.amount = Number(obj.amount || Rate.clean.amount	);
		this.name   = String(obj.name 	|| Rate.clean.name   	);
		return this;
	}

	set(obj: IRate) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = Rate.clean[key];
		});

		return this;
	}

	clone() {
		return new Rate().set(this);
	}
}
