{
	
	"instructorsAvailabilityScheduleView": false,
	"instructorsAvailabilityScheduleCreateEdit": false,
	
	"loaRequestView": false,
	"loaRequestAccceptOrDeny": false,
	"graduationRequestView": false,
	"graduationRequestAccceptOrDeny": false,
	"checkRideRequestView": false,
	"checkRideRequestAccceptOrDeny": false,

	"viewAllReservations": false,
	"viewSelfReservations": true,
	"viewReservationOfAssignedStudents": false,
	"schedOMatic": false,
	"availabilityFinder": false,

	"reservationsCreateOwn": false,
	"reservationsEditOwn": false,
	"reservationsCancelOwn": false,
	"reservationsCreateForOthers": false,
	"reservationsEditForOthers": false,
	"reservationsCancelForOthers": false,
	"reservationsCreateForAssignedStudents": false,
	"reservationsEditForAssignedStudents": false,
	"reservationsCancelForAssignedStudents": false,
	"reservationsCheckOutOther": false,
	"reservationsCheckInOther": false,
	"reservationsCheckOutOwn": false,
	"reservationsCheckInOwn": false,
	"reservationsCheckOutAssignedStudents": false,
	"reservationsCheckInAssignedStudents": false,

	"reservationsDispatchSheet": false,
	"reservationsGenerateInvoices": false,
	"reservationsViewInvoices": true,

	"usersCreateClient": false,
	"usersCreateTeamMember": false,
	"usersViewAllClientList": false,
	"usersViewAllTeamMemberList": false,
	"usersViewOnlyAsignedClientList": false,
	"usersViewOnlySelfClientOrTeamMember": true,

	"clientViewGeneralInfo": true,

	"clientDeleteDisable": false,
	"clientEditUpdate": false,
	"clientEditSelf": true,
	"clientEnroll": false,
	"clientViewRecord": true,
	"clientRequestGraduation": false,
	"clientRequestCheckRide": false,

	"clientCredentialsView": true,
	"clientCredentialsAdd": false,
	"clientCredentialsResolve": false,
	"clientCredentialsDisableEnable": false,

	"clientCurrencyCheckoutsEnableDisable": false,
	"clientCurrencyCheckoutsView": true,

	"clientFlightLogAdd": true,
	"clientFlightLogEdit": true,
	"clientFlightLogDelete": false,
	"clientFlightLogView": true,

	"clientLessonLogEdit": false,
	"clientLessonLogView": true,

	"teamMemberUsersGeneralView": false,
	"teamMemberUsersDeleteDisable": false,
	"teamMemberUsersEditUpdate": false,
	"teamMemberUsersEditSelf": false,

	"teamMemberUserCredentialsView": true,
	"teamMemberUserCredentialsAdd": false,
	"teamMemberUserCredentialsResolve": false,
	"teamMemberUserCredentialsDisableEnable": false,

	"teamMemberUserCurrencyCheckoutsEnableDisable": false,
	"teamMemberUserCurrencyCheckoutsView": true,

	"teamMemberFlightLogAddOwnEntry": true,
	"teamMemberFlightLogEditOwnEntry": true,
	"teamMemberFlightLogViewAllEntries": true,




	"coursesCreate": false,
	"coursesEdit": false,
	"coursesDisable": false,
	"coursesViewDetails": true,
	"coursesCheckRideFailReasonsDropdowns": false,

	"equipmentCreate": false,
	"equipmentEdit": false,
	"equipmentDisable": false,
	"equipmentViewDetails": false,
	"equipmentCreateSquawks": false,
	"equipmentResolveSquawks": false,
	"equipmentViewSquawks": false,
	"equipmentLeaseback": false,

	"equipmentCredentialsView": false,
	"equipmentCredentialsAdd": false,
	"equipmentCredentialsResolve": false,
	"equipmentCredentialsDisableEnable": false,




	"credentialsCreate": false,
	"credentialsEdit": false,
	"credentialsDisable": false,
	"credentialsDelete": false,
	"credentialsViewDetails": false,

	"groupsCreateEquipmentGroup": false,
	"groupsEditEquipmentGroup": false,
	"groupsDeleteEquipmentGroup": false,
	"groupsViewEquipmentGroup": false,
	"groupsCreateInstructorGroup": false,
	"groupsEditInstructorGroup": false,
	"groupsDeleteInstructorGroup": false,
	"groupsViewInstructorGroup": false,

	"ftaStudentsViewStudentList": false,

	"ftaComplianceFromFtaToTheUniversityUpload": false,
	"ftaComplianceFromFtaToTheUniversityResolve": false,
	"ftaComplianceFromFtaToTheUniversityDisable": false,
	"ftaComplianceFromFtaToTheUniversityViewDocuments": false,

	"universityComplianceFromTheUniversityToTheFtaAcknowledgementMarkAsUnread": false,
	"universityComplianceFromTheUniversityToTheFtaViewDocuments": false,

	"ftaAccountInfoViewAccountInfo": false,
	"ftaAccountInfoEditAccountInfo": false,

	"settingsEditGeneralSettings": false,
	"settingsEditPermissions": false,

	"billingAccountingSettings": false,

	"gradingGradeStudent": false
}