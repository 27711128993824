import moment from 'moment';
import { IBackendReservationHour, IReservationHour, ReservationHour } from './reservation-hour.model';

export interface IBackendSchedomaticDate {
	// created_at              ?: Date;
	// updated_at              ?: Date;

	date                     ?: any;
	syllabus_lesson_type_id  ?: number;
	client_id                ?: number;
	group_id                 ?: number;
	hours_selected_index     ?: number;
	selected_hour_index      ?: number; // finder-date-option
	// equipment_selected_index ?: number;
	hours                    ?: IBackendReservationHour[];
}

export interface ISchedomaticDate {
	// createdAt        ?: Date;
	// updatedAt        ?: Date;

	date                   ?: any;
	syllabusLessonTypeId   ?: number;
	clientId               ?: number;
	groupId                ?: number;
	/** Used in sched-o-matic */
	hoursSelectedIndex     ?: number;
	selectedHourIndex      ?: number;
	// equipmentSelectedIndex ?: number;
	hours                  ?: IReservationHour[];
}

export class SchedomaticDate implements ISchedomaticDate {
	static readonly clean = Object.freeze(new SchedomaticDate());
	// createdAt?: Date;
	// updatedAt?: Date;
	date                     : any;
	syllabusLessonTypeId     = 0;
	clientId                 = 0;
	groupId                  = 0;
	hoursSelectedIndex       = 0;
	selectedHourIndex        = -1;
	// equipmentSelectedIndex   = 0;
	hours: ReservationHour[] = [];

	parse(obj: IBackendSchedomaticDate) {
		// this.createdAt        = obj.created_at ? moment.utc(obj.created_at).toDate() : SchedomaticDate.clean.createdAt;
		// this.updatedAt        = obj.updated_at ? moment.utc(obj.updated_at).toDate() : SchedomaticDate.clean.updatedAt;

		// this.date                 = obj.date ? moment.utc(obj.date).toDate() : SchedomaticDate.clean.date;
		// this.date                   = obj.date ? new Date(obj.date) : SchedomaticDate.clean.date;
		// console.log('%c obj.date ', 'background: #111; color: #bada55');
		// console.log( obj.date );
		this.date                   = obj.date ? moment(obj.date).toDate() : SchedomaticDate.clean.date;
		// console.log( `this.date --> `, this.date );

		this.syllabusLessonTypeId   = Number(obj.syllabus_lesson_type_id  || SchedomaticDate.clean.syllabusLessonTypeId);
		this.clientId               = Number(obj.client_id                || SchedomaticDate.clean.clientId            );
		this.groupId                = Number(obj.group_id                 || SchedomaticDate.clean.groupId             );
		this.hoursSelectedIndex     = Number(obj.hours_selected_index     || SchedomaticDate.clean.hoursSelectedIndex  );
		this.selectedHourIndex      = Number(obj.selected_hour_index      || SchedomaticDate.clean.selectedHourIndex   );
		// this.equipmentSelectedIndex = Number(obj.equipment_selected_index || SchedomaticDate.clean.equipmentSelectedIndex );
		this.hours                  = (obj.hours     || []).map((element) => new ReservationHour().parse(element));

		return this;
	}

	set(obj: ISchedomaticDate) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = SchedomaticDate.clean[key];
		});

		return this;
	}

	clone() {
		return new SchedomaticDate().set(this);
	}
}
