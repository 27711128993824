import moment from 'moment';
import { IBackendLesson, ILesson, Lesson } from './lesson.model';

export interface IBackendStage {
	created_at         ?: Date;
	updated_at         ?: Date;
	id                 ?: number;
	syllabus_course_id ?: number;
	lessons_count      ?: number;
	is_king            ?: number;
	name               ?: string;
	objectives         ?: string;
	complete_standards ?: string;
	stage              ?: string; // kingStageName
	lessons            ?: IBackendLesson[];
}

export interface IStage {
	createdAt         ?: Date;
	updatedAt         ?: Date;
	id                ?: number;
	syllabusCourseId  ?: number;
	lessonsCount      ?: number;
	isKing            ?: number;
	name              ?: string;
	objectives        ?: string;
	completeStandards ?: string;
	kingStageName     ?: string;
	lessons           ?: ILesson[];
}

/**
 * Stages are named "phases" in King courses
 */
export class Stage implements IStage {
	static readonly clean = Object.freeze(new Stage());
	createdAt?: Date;
	updatedAt?: Date;
	id                = 0 ;
	syllabusCourseId  = 0;
	lessonsCount      = 0;
	isKing            = 0;
	name              = '';
	objectives        = '';
	completeStandards = '';
	/** used to show to what "Stage" this king "Phase" belongs */
	kingStageName     = '';
	lessons: Lesson[] = [];

	parse(obj: IBackendStage) {
		// this.createdAt         = obj.created_at ? moment.utc(obj.created_at).toDate() : Stage.clean.createdAt;
		// this.updatedAt         = obj.updated_at ? moment.utc(obj.updated_at).toDate() : Stage.clean.updatedAt;

		/**
		 * These moment.utc functions are behaving strangely, were an hour off :c
		 * so we're using offset to convert to local times
		 */
		const offset = Number(new Date().getTimezoneOffset() / 60);
		this.createdAt = obj.created_at ? moment(obj.created_at ).subtract(offset, 'hours').toDate() : Stage.clean.createdAt ;
		this.updatedAt = obj.updated_at ? moment(obj.updated_at ).subtract(offset, 'hours').toDate() : Stage.clean.updatedAt ;


		this.id                = Number(obj.id                 || Stage.clean.id               );
		this.syllabusCourseId  = Number(obj.syllabus_course_id || Stage.clean.syllabusCourseId );
		this.lessonsCount      = Number(obj.lessons_count      || Stage.clean.lessonsCount     );
		this.isKing            = Number(obj.is_king === 1 ? obj.is_king : Stage.clean.isKing   );
		this.name              = String(obj.name               || Stage.clean.name             );
		this.objectives        = String(obj.objectives         || Stage.clean.objectives       );
		this.completeStandards = String(obj.complete_standards || Stage.clean.completeStandards);
		this.kingStageName     = String(obj.stage              || Stage.clean.kingStageName    );
		this.lessons           = (obj.lessons  || []).map((task) => new Lesson().parse(task));
		return this;
	}

	set(obj: IStage) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = Stage.clean[key];
		});

		return this;
	}

	clone() {
		return new Stage().set(this);
	}
}
