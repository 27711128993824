<div class="snack-container"
	(mouseenter)="mouseEnter()"
	(mouseleave)="mouseLeave()">

	<!-- <div class="icon-container" *ngIf="data.type && data.type !== ''">
		<img [src]="'assets/img/icons/toast_' + data.type + '.svg'" alt="">
	</div> -->

	<div class="info-container">

		<p class="message" [innerHTML]="data.message"></p>

		<div class="buttons-container">
			<button class="close-snack" (click)="action()">
				<img src="assets/img/icons/toast_close.svg" alt="">
			</button>
		</div>

	</div>

</div>
