/* eslint-disable @typescript-eslint/no-var-requires */
import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Event } from '../../models/event.model';
import { User } from '../../models/user.model';
import { PermissionPipe } from '../../shared/pipes/permission.pipe';
declare const require: any;
const permissions1 = require('./../../../../assets/json/permissions-1.json');
const permissions2 = require('./../../../../assets/json/permissions-2.json');
const permissions3 = require('./../../../../assets/json/permissions-3.json');
const permissions4 = require('./../../../../assets/json/permissions-4.json');
const permissions5 = require('./../../../../assets/json/permissions-5.json');
const permissions6 = require('./../../../../assets/json/permissions-6.json');
const permissions7 = require('./../../../../assets/json/permissions-7.json');
const permissions8 = require('./../../../../assets/json/permissions-8.json');
const permissions9 = require('./../../../../assets/json/permissions-9.json');
const permissions10 = require('./../../../../assets/json/permissions-10.json');
const permissions11 = require('./../../../../assets/json/permissions-11.json');
const permissions12 = require('./../../../../assets/json/permissions-12.json');

/** The keys used to store user data in local storage. */
export const USER_STORAGE_KEYS = {
	SESSION_USER: 'FLIGHT_NINJA_SESSION_USER',
	USER_ID: 'FLIGHT_NINJA_SESSION_USER_ID',
	SESSION_TOKEN: 'FLIGHT_NINJA_SESSION_TOKEN',
	REFRESH_TOKEN: 'FLIGHT_NINJA_REFRESH_TOKEN',
	LAST_REQUEST_TIME: 'FLIGHT_NINJA_LAST_REQUEST_TIME'
} as const;

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
	userChange$ = new BehaviorSubject<User>(new User());
	tokenChange = new EventEmitter<string>();

	private _token = '';
	private _user: User = null;

	/**
	 * Clears local and session storage of application used keys
	 */
	public clear(): void {
		this.user = null;
		this.token = '';

		Object.keys(USER_STORAGE_KEYS).forEach((key) => {
			localStorage.removeItem(USER_STORAGE_KEYS[key]);
		});
	}


	constructor(
		private _permissionPipe: PermissionPipe,
	) { }


	public get token() {
		if (this._token) {
			return this._token;
		}

		this._token = localStorage.getItem(USER_STORAGE_KEYS.SESSION_TOKEN) || '';
		return this._token;
	}

	public set token(value) {
		this._token = value;
		// console.log( `this._token --> `, this._token );
		this.tokenChange.emit(this._token);
		localStorage.setItem(USER_STORAGE_KEYS.SESSION_TOKEN, this._token);
	}

	public get user() {
		if (this._user) {
			return this._user;
		}

		let encodedUser = undefined;
		encodedUser = localStorage.getItem(USER_STORAGE_KEYS.SESSION_USER);

		if (encodedUser) {
			this._user = JSON.parse(encodedUser);
			return this._user;
		}

		return null;
	}

	public set user(value) {
		// console.log(`user value --> `, value);
		this._user = value;
		// this._user.permissions = this.defineUserPermissions(this._user);
		this.userChange$.next(this._user);
		localStorage.setItem(USER_STORAGE_KEYS.SESSION_USER, JSON.stringify(this._user));
		localStorage.setItem(USER_STORAGE_KEYS.USER_ID, String(this._user['id']));
		// localStorage.setItem(this.keys.SESSION_TOKEN, this._user['token']);
	}

	public isLogged(): boolean {
		return Boolean(this.token && this.user.id > 0);
	}

	// Helpers

	/** Returns a permissions obj  */
	private defineUserPermissions(user: User): any {
		switch (user.roleId) {
			case 1:
				return permissions1;
				break;
			case 2:
				return permissions2;
				break;
			case 3:
				return permissions3;
				break;
			case 4:
				return permissions4;
				break;
			case 5:
				return permissions5;
				break;
			case 6:
				return permissions6;
				break;
			case 7:
				return permissions7;
				break;
			case 8:
				return permissions8;
				break;
			case 9:
				return permissions9;
				break;
			case 10:
				return permissions10;
				break;
			case 11:
				return permissions11;
				break;
			case 12:
				return permissions12;
				break;
			default:
				break;
		}
	}

	/**
	 * Returns true if should prevent.
	 * ### Checking permissions:
	 * * Reservations create own
	 * * Reservations edit own
	 */
	preventUserCreateEditOwnReservations(clientId: any, editing: boolean): boolean {
		// console.log(`clientId --> `, clientId);
		// console.log(`user.id  --> `, this.user.id);
		// console.log(`editing  --> `, editing);
		return (
			clientId &&
			(
				this.user.id === clientId ||
				this.user.id === clientId.value ||
				this.user.id === clientId.id ||
				(
					Array.isArray(clientId) &&
					clientId.some((id: number) => id === this.user.id)
				)
			) &&
			(
				editing ?
					this._permissionPipe.transform('reservationsEditOwn', this.user) === false :
					this._permissionPipe.transform('reservationsCreateOwn', this.user) === false
			)
		);
	}

	/**
	 * Returns true if should prevent.
	 * ### Checking permissions:
	 * * Reservations create for others
	 * * Reservations edit for others
	 */
	preventUserCreateOthersReservations(clientId: any, editing: boolean): boolean {
		// console.log(`clientId --> `, clientId);
		// console.log(`this.user.id --> `, this.user.id);
		return (
			clientId &&
			(
				this.user.id !== clientId &&
				this.user.id !== clientId.value &&
				this.user.id !== clientId.id &&
				(
					!Array.isArray(clientId) ||
					!clientId.some((id: number) => id === this.user.id)
				)
			) &&
			(
				editing ?
					this._permissionPipe.transform('reservationsEditForOthers', this.user) === false :
					this._permissionPipe.transform('reservationsCreateForOthers', this.user) === false
			)
		);
	}

}
