import moment from 'moment';


export interface IBackendFaaCourseType {
	id         ?: number;
	created_at ?: Date;
	updated_at ?: Date;
	name       ?: string;
	reasons    ?: any;
}

export interface IFaaCourseType {
	id        ?: number;
	createdAt ?: Date;
	updatedAt ?: Date;
	name      ?: string;
	reasons   ?: any;
}

export class FaaCourseType implements IFaaCourseType {
	static readonly clean = Object.freeze(new FaaCourseType());
	id                  = 0;
	createdAt           ?: Date;
	updatedAt           ?: Date;
	name                = '';
	reasons             = undefined;


	parse(obj: IBackendFaaCourseType) {
		this.id       = Number(obj.id       || FaaCourseType.clean.id);
		this.name     = String(obj.name     || FaaCourseType.clean.name    );
		this.reasons  =       (obj.reasons  || FaaCourseType.clean.reasons );

		// this.createdAt         = obj.created_at          ? moment.utc(obj.created_at          ).toDate() : FaaCourseType.clean.createdAt         ;
		// this.updatedAt         = obj.updated_at          ? moment.utc(obj.updated_at          ).toDate() : FaaCourseType.clean.updatedAt         ;
		// this.loaExpirationDate = obj.loa_expiration_date ? moment.utc(obj.loa_expiration_date ).toDate() : FaaCourseType.clean.loaExpirationDate ;

		/**
		 * These moment.utc functions are behaving strangely, were an hour off :c
		 * so we're using offset to convert to local times
		 */
		const offset = Number(new Date().getTimezoneOffset() / 60);
		this.createdAt         = obj.created_at          ? moment(obj.created_at         ).subtract(offset, 'hours').toDate() : FaaCourseType.clean.createdAt        ;
		this.updatedAt         = obj.updated_at          ? moment(obj.updated_at         ).subtract(offset, 'hours').toDate() : FaaCourseType.clean.updatedAt        ;
		return this;
	}

	set(obj: IFaaCourseType) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = FaaCourseType.clean[key];
		});

		return this;
	}

	clone() {
		return new FaaCourseType().set(this);
	}
}
