import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {

  constructor() { }


	/**
	 * Add zeros to pin if needed
	 */
   updatePin(pin: number | string) : string {
		let newPin = String(pin);
		let missingZeros = 4 - newPin.length;
		missingZeros = (missingZeros > 4) ? 4 : (missingZeros < 0) ? 0 : 0;
		console.log(`missingZeros`, missingZeros);

		if (missingZeros) {
			for (let index = 0; index < missingZeros; index++) {
				newPin = '0' + newPin;
			}
		}

		return newPin;
	}

	/**
	 * Returns a number using toFixed
	 * @param num 
	 * @param decimals defalut : 2
	 */
	roundNumber(num: number, decimals = 2):number {
		return Number(num.toFixed(decimals));
	}

}
