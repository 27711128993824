import moment from 'moment';

export interface IBackendKingStage {
	created_at           ?: Date;
	updated_at           ?: Date;
	id                   ?: number;
	title                ?: string;
}

export interface IKingStage {
	createdAt          ?: Date;
	updatedAt          ?: Date;
	id                 ?: number;
	title              ?: string;
}

export class KingStage implements IKingStage {
	static readonly clean = Object.freeze(new KingStage());
	createdAt          ?: Date;
	updatedAt          ?: Date;
	id                 = 0;
	title              = '';

	parse(obj: IBackendKingStage) {
		const offset = Number(new Date().getTimezoneOffset() / 60);
		this.createdAt     = obj.created_at      ? moment(obj.created_at      ).subtract(offset, 'hours').toDate() : KingStage.clean.createdAt     ;
		this.updatedAt     = obj.updated_at      ? moment(obj.updated_at      ).subtract(offset, 'hours').toDate() : KingStage.clean.updatedAt     ;
		this.id                 = Number(obj.id                   || KingStage.clean.id                 );
		this.title              = String(obj.title                || KingStage.clean.title              );
		return this;
	}

	set(obj: IKingStage) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = KingStage.clean[key];
		});

		return this;
	}

	clone() {
		return new KingStage().set(this);
	}
}
