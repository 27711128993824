import moment from 'moment';
import { IBackendLeasebackInsurancePayment, ILeasebackInsurancePayment, LeasebackInsurancePayment } from './leaseback-insurance-payment.model';
import { IBackendLeasebackStatementSpan, ILeasebackStatementSpan, LeasebackStatementSpan } from './leaseback-statement-span.model';
import { IBackendLeasebackTier, ILeasebackTier, LeasebackTier } from './leaseback-tier.model';

export interface IBackendLeaseback {
	[key: string]                    : any;
	created_at                      ?: Date;
	updated_at                      ?: Date;
	id                              ?: number;
	company_name                    ?: string;
	name                            ?: string;
	tax_id_ein                      ?: string;
	phone_number                    ?: string;
	email                           ?: string;
	address                         ?: string;
	bank_information                ?: string;
	mailing_and_billing_address     ?: string;
	rate_type                       ?: string;
	hour_rate_type                  ?: string;
	custom_rate_value               ?: number;
	percent_of_current_rate         ?: number;
	minimum_hours                   ?: number;
	maintenance_hour                ?: string;
	school_pays_value               ?: number;
	owner_hour_rate                 ?: string;
	discount_percentage             ?: number;
	tax                             ?: string;
	fuel                            ?: string;
	oil                             ?: string;
	nav_subscription                ?: string;
	nav_subscription_payment_option ?: string;
	nav_subscription_option_date    ?: Date;
	nav_subscription_option_value   ?: number;
	xm_subscription                 ?: string;
	xm_subscription_payment_option  ?: string;
	xm_subscription_option_date     ?: Date;
	xm_subscription_option_value    ?: number;
	registration_renewal            ?: string;
	registration_renewal_date       ?: Date;
	registration_renewal_value      ?: number;
	admin_fees                      ?: string;
	admin_fees_option               ?: string;
	admin_fees_value                ?: number;
	admin_fees_date                 ?: Date;
	tie_down                        ?: string;
	tie_down_option                 ?: string;
	tie_down_value                  ?: number;
	tie_down_date                   ?: Date;
	hangar_fees                     ?: string;
	hangar_fees_option              ?: string;
	hangar_fees_value               ?: number;
	hangar_fees_date                ?: Date;
	third_party_markup              ?: string;
	third_party_percentage          ?: number;
	labor_markup                    ?: string;
	labor_markup_percentage         ?: number;
	instructor_rate                 ?: string;
	discount_rate                   ?: number;
	insurance                       ?: string;
	insurance_option                ?: string;
	insurance_monthly_installments  ?: number;
	insurance_payments              ?: number;
	insurance_annual_amount         ?: string;
	insurance_annual_paid_in_full   ?: string;
	insurance_annual_updated_by     ?: string;
	insurance_annual_updated_at     ?: Date;
	insurance_renewal_date          ?: Date;

	insurance_monthly_payments      ?: IBackendLeasebackInsurancePayment [];
	tiers                           ?: IBackendLeasebackTier             [];
	statements                      ?: IBackendLeasebackStatementSpan    [];
}

export interface ILeaseback {
	[key: string]                 : any;
	createdAt                    ?: Date;
	updatedAt                    ?: Date;
	id                           ?: number;
	companyName                  ?: string;
	name                         ?: string;
	taxIdEin                     ?: string;
	phoneNumber                  ?: string;
	email                        ?: string;
	address                      ?: string;
	bankInformation              ?: string;
	mailingAndBillingAddress     ?: string;
	rateType                     ?: string;
	hourRateType                 ?: string;
	customRateValue              ?: number;
	percentOfCurrentRate         ?: number;
	minimumHours                 ?: number;
	maintenanceHour              ?: string;
	schoolPaysValue              ?: number;
	ownerHourRate                ?: string;
	discountPercentage           ?: number;
	tax                          ?: string;
	fuel                         ?: string;
	oil                          ?: string;
	navSubscription              ?: string;
	navSubscriptionPaymentOption ?: string;
	navSubscriptionOptionDate    ?: Date;
	navSubscriptionOptionValue   ?: number;
	xmSubscription               ?: string;
	xmSubscriptionPaymentOption  ?: string;
	xmSubscriptionOptionDate     ?: Date;
	xmSubscriptionOptionValue    ?: number;
	registrationRenewal          ?: string;
	registrationRenewalDate      ?: Date;
	registrationRenewalValue     ?: number;
	adminFees                    ?: string;
	adminFeesOption              ?: string;
	adminFeesValue               ?: number;
	adminFeesDate                ?: Date;
	tieDown                      ?: string;
	tieDownOption                ?: string;
	tieDownValue                 ?: number;
	tieDownDate                  ?: Date;
	hangarFees                   ?: string;
	hangarFeesOption             ?: string;
	hangarFeesValue              ?: number;
	hangarFeesDate               ?: Date;
	thirdPartyMarkup             ?: string;
	thirdPartyPercentage         ?: number;
	laborMarkup                  ?: string;
	laborMarkupPercentage        ?: number;
	instructorRate               ?: string;
	discountRate                 ?: number;
	insurance                    ?: string;
	insuranceOption              ?: string;
	insuranceMonthlyInstallments ?: number;
	insurancePayments            ?: number;
	insuranceAnnualAmount        ?: string;
	insuranceAnnualPaidInFull    ?: boolean;
	insuranceAnnualUpdatedBy     ?: string;
	insuranceAnnualUpdatedAt     ?: Date;
	InsuranceRenewalDate         ?: Date;

	insuranceMonthlyPayments     ?: ILeasebackInsurancePayment [];
	tiers                        ?: ILeasebackTier             [];
	statements                   ?: ILeasebackStatementSpan    [];
}

export class Leaseback implements ILeaseback {
	static readonly clean = Object.freeze(new Leaseback());
	[key: string]                : any;
	createdAt                    ?: Date;
	updatedAt                    ?: Date;
	id                           = 0;
	/** Company name */
	companyName                  = '';
	/** Person Name or Client name */
	name                         = '';
	/** Tax ID/EIN */
	taxIdEin                     = '';
	/** Phone number */
	phoneNumber                  = '';
	/** Email */
	email                        = '';
	/** Address */
	address                      = '';
	/** Bank information */
	bankInformation              = '';
	/** Mailing and billing address */
	mailingAndBillingAddress     = '';
	/** Rate type */
	rateType                     = '';
	/** Hour Rate Type */
	hourRateType                 = '';
	/** Custom rate value */
	customRateValue              = 0;
	/** Percent of current rate */
	percentOfCurrentRate         = 0;
	/** Minimum Hours */
	minimumHours                 = 0;
	/** Maintenance hour */
	maintenanceHour              = '';
	/** School pays value */
	schoolPaysValue              = 0;
	/** Owner Hour Rate */
	ownerHourRate                = '';
	/** Discount percentage */
	discountPercentage           = 0;
	/** Tax */
	tax                          = '0';
	/** Fuel */
	fuel                         = '';
	/** Oil */
	oil                          = '';
	/** Nav Subscription */
	navSubscription              = '';
	/** Nav Subscription payment option */
	navSubscriptionPaymentOption = '';
	/** Nav Subscription option date */
	navSubscriptionOptionDate    ?: Date;
	/** Nav Subscription option Value */
	navSubscriptionOptionValue   = 0;
	/** XM Subscription */
	xmSubscription               = '';
	/** XM Subscription payment option */
	xmSubscriptionPaymentOption  = '';
	/** XM Subscription option date */
	xmSubscriptionOptionDate     ?: Date;
	/** XM Subscription option Value */
	xmSubscriptionOptionValue    = 0;
	/** Registration Renewal */
	registrationRenewal          = '';
	/** Registration Renewal Date */
	registrationRenewalDate      ?: Date;
	/** Registration Renewal Value */
	registrationRenewalValue     = 0;
	/** Admin Fees */
	adminFees                    = '';
	/** Admin Fees Option */
	adminFeesOption              = '';
	/** Admin Fees Value */
	adminFeesValue               = 0;
	/** Admin Fees Date */
	adminFeesDate                ?: Date;
	/** Tie Down */
	tieDown                      = '';
	/** Tie Down Option */
	tieDownOption                = '';
	/** Tie Down Value */
	tieDownValue                 = 0;
	/** Tie Down Date */
	tieDownDate                  ?: Date;
	/** Hangar Fees */
	hangarFees                   = '';
	/** Hangar Fees Option */
	hangarFeesOption             = '';
	/** Hangar Fees Value */
	hangarFeesValue              = 0;
	/** Hangar Fees Date */
	hangarFeesDate               ?: Date;
	/** Third Party Markup */
	thirdPartyMarkup             = '';
	/** Third Party Percentage */
	thirdPartyPercentage         = 0;
	/** Labor Markup */
	laborMarkup                  = '';
	/** Labor Markup Percentage */
	laborMarkupPercentage        = 0;
	/** Instructor Rate */
	instructorRate               = '';
	/** Discount Rate */
	discountRate                 = 0;
	/** Insurance */
	insurance                    = '';
	/** Insurance Option */
	insuranceOption              = '';
	/** Insurance Monthly Installments */
	insuranceMonthlyInstallments = 0;
	/** Insurance Down Payment */
	insurancePayments            = 0;
	/** Insurance Annual amount */
	insuranceAnnualAmount        = '';
	/** Insurance paid in full */
	insuranceAnnualPaidInFull    = false;
	/** Insurance annual updated by */
	insuranceAnnualUpdatedBy     = '';
	/** Insurance annual updated at */
	insuranceAnnualUpdatedAt     ?: Date;
	/** Insurance Renewal Date */
	InsuranceRenewalDate         ?: Date;

	insuranceMonthlyPayments     ?:  LeasebackInsurancePayment [] = [];
	tiers                        ?:  LeasebackTier             [] = [];
	statements                   ?:  LeasebackStatementSpan    [] = [];

	parse(obj: IBackendLeaseback) {
		const offset   = Number(new Date().getTimezoneOffset() / 60);
		this.createdAt = obj.created_at ? moment(obj.created_at ).subtract(offset, 'hours').toDate() : Leaseback.clean.createdAt ;
		this.updatedAt = obj.updated_at ? moment(obj.updated_at ).subtract(offset, 'hours').toDate() : Leaseback.clean.updatedAt ;
		this.id                           = Number  (obj.id                               || Leaseback.clean.id                           );
		this.companyName                  = String  (obj.company_name                     || Leaseback.clean.companyName                  );
		this.name                         = String  (obj.name                             || Leaseback.clean.name                         );
		this.taxIdEin                     = String  (obj.tax_id_ein                       || Leaseback.clean.taxIdEin                     );
		this.phoneNumber                  = String  (obj.phone_number                     || Leaseback.clean.phoneNumber                  );
		this.email                        = String  (obj.email                            || Leaseback.clean.email                        );
		this.address                      = String  (obj.address                          || Leaseback.clean.address                      );
		this.bankInformation              = String  (obj.bank_information                 || Leaseback.clean.bankInformation              );
		this.mailingAndBillingAddress     = String  (obj.mailing_and_billing_address      || Leaseback.clean.mailingAndBillingAddress     );
		this.rateType                     = String  (obj.rate_type                        || Leaseback.clean.rateType                     );
		this.hourRateType                 = String  (obj.hour_rate_type                   || Leaseback.clean.hourRateType                 );
		this.customRateValue              = Number  (obj.custom_rate_value                || Leaseback.clean.customRateValue              );
		this.percentOfCurrentRate         = Number  (obj.percent_of_current_rate          || Leaseback.clean.percentOfCurrentRate         );
		this.minimumHours                 = Number  (obj.minimum_hours                    || Leaseback.clean.minimumHours                 );
		this.maintenanceHour              = String  (obj.maintenance_hour                 || Leaseback.clean.maintenanceHour              );
		this.schoolPaysValue              = Number  (obj.school_pays_value                || Leaseback.clean.schoolPaysValue              );
		this.ownerHourRate                = String  (obj.owner_hour_rate                  || Leaseback.clean.ownerHourRate                );
		this.discountPercentage           = Number  (obj.discount_percentage              || Leaseback.clean.discountPercentage           );
		this.tax                          = String  (obj.tax                              || Leaseback.clean.tax                          );
		this.fuel                         = String  (obj.fuel                             || Leaseback.clean.fuel                         );
		this.oil                          = String  (obj.oil                              || Leaseback.clean.oil                          );
		this.navSubscription              = String  (obj.nav_subscription                 || Leaseback.clean.navSubscription              );
		this.navSubscriptionPaymentOption = String  (obj.nav_subscription_payment_option  || Leaseback.clean.navSubscriptionPaymentOption );
		this.navSubscriptionOptionDate    = obj.nav_subscription_option_date ? moment(obj.nav_subscription_option_date ).toDate() : Leaseback.clean.navSubscriptionOptionDate ;
		this.navSubscriptionOptionValue   = Number  (obj.nav_subscription_option_value    || Leaseback.clean.navSubscriptionOptionValue   );
		this.xmSubscription               = String  (obj.xm_subscription                  || Leaseback.clean.xmSubscription               );
		this.xmSubscriptionPaymentOption  = String  (obj.xm_subscription_payment_option   || Leaseback.clean.xmSubscriptionPaymentOption  );
		this.xmSubscriptionOptionDate     = obj.xm_subscription_option_date  ? moment(obj.xm_subscription_option_date  ).toDate() : Leaseback.clean.xmSubscriptionOptionDate  ;
		this.xmSubscriptionOptionValue    = Number  (obj.xm_subscription_option_value     || Leaseback.clean.xmSubscriptionOptionValue    );
		this.registrationRenewal          = String  (obj.registration_renewal             || Leaseback.clean.registrationRenewal          );
		this.registrationRenewalDate      = obj.registration_renewal_date    ? moment(obj.registration_renewal_date    ).toDate() : Leaseback.clean.registrationRenewalDate   ;
		this.registrationRenewalValue     = Number  (obj.registration_renewal_value       || Leaseback.clean.registrationRenewalValue     );
		this.adminFees                    = String  (obj.admin_fees                       || Leaseback.clean.adminFees                    );
		this.adminFeesOption              = String  (obj.admin_fees_option                || Leaseback.clean.adminFeesOption              );
		this.adminFeesValue               = Number  (obj.admin_fees_value                 || Leaseback.clean.adminFeesValue               );
		this.adminFeesDate                = obj.admin_fees_date              ? moment(obj.admin_fees_date              ).toDate() : Leaseback.clean.adminFeesDate             ;
		this.tieDown                      = String  (obj.tie_down                         || Leaseback.clean.tieDown                      );
		this.tieDownOption                = String  (obj.tie_down_option                  || Leaseback.clean.tieDownOption                );
		this.tieDownValue                 = Number  (obj.tie_down_value                   || Leaseback.clean.tieDownValue                 );
		this.tieDownDate                  = obj.tie_down_date                ? moment(obj.tie_down_date                ).toDate() : Leaseback.clean.tieDownDate               ;
		this.hangarFees                   = String  (obj.hangar_fees                      || Leaseback.clean.hangarFees                   );
		this.hangarFeesOption             = String  (obj.hangar_fees_option               || Leaseback.clean.hangarFeesOption             );
		this.hangarFeesValue              = Number  (obj.hangar_fees_value                || Leaseback.clean.hangarFeesValue              );
		this.hangarFeesDate               = obj.hangar_fees_date             ? moment(obj.hangar_fees_date             ).toDate() : Leaseback.clean.hangarFeesDate            ;
		this.thirdPartyMarkup             = String  (obj.third_party_markup               || Leaseback.clean.thirdPartyMarkup             );
		this.thirdPartyPercentage         = Number  (obj.third_party_percentage           || Leaseback.clean.thirdPartyPercentage         );
		this.laborMarkup                  = String  (obj.labor_markup                     || Leaseback.clean.laborMarkup                  );
		this.laborMarkupPercentage        = Number  (obj.labor_markup_percentage          || Leaseback.clean.laborMarkupPercentage        );
		this.instructorRate               = String  (obj.instructor_rate                  || Leaseback.clean.instructorRate               );
		this.discountRate                 = Number  (obj.discount_rate                    || Leaseback.clean.discountRate                 );
		this.insurance                    = String  (obj.insurance                        || Leaseback.clean.insurance                    );
		this.insuranceOption              = String  (obj.insurance_option                 || Leaseback.clean.insuranceOption              );
		this.insuranceMonthlyInstallments = Number  (obj.insurance_monthly_installments   || Leaseback.clean.insuranceMonthlyInstallments );
		this.insurancePayments            = Number  (obj.insurance_payments               || Leaseback.clean.insurancePayments            );
		this.insuranceAnnualAmount        = String  (obj.insurance_annual_amount          || Leaseback.clean.insuranceAnnualAmount        );
		this.insuranceAnnualPaidInFull    = Boolean (obj.insurance_annual_paid_in_full    || Leaseback.clean.insuranceAnnualPaidInFull    );
		this.insuranceAnnualUpdatedBy     = String  (obj.insurance_annual_updated_by      || Leaseback.clean.insuranceAnnualUpdatedBy     );
		this.insuranceAnnualUpdatedAt     = obj.insurance_annual_updated_at  ? moment(obj.insurance_annual_updated_at  ).subtract(offset, 'hours').toDate() : Leaseback.clean.insuranceAnnualUpdatedAt  ;
		this.InsuranceRenewalDate         = obj.insurance_renewal_date       ? moment(obj.insurance_renewal_date       ).toDate() : Leaseback.clean.InsuranceRenewalDate      ;

		this.insuranceMonthlyPayments     = (obj.insurance_monthly_payments || Leaseback.clean.insuranceMonthlyPayments).map((item: any) => new LeasebackInsurancePayment ().parse(item));
		this.tiers                        = (obj.tiers                      || Leaseback.clean.tiers                   ).map((item: any) => new LeasebackTier             ().parse(item));
		this.statements                   = (obj.statements                 || Leaseback.clean.statements              ).map((item: any) => new LeasebackStatementSpan    ().parse(item));

		return this;
	}

	set(obj: ILeaseback) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = Leaseback.clean[key];
		});

		return this;
	}

	clone() {
		return new Leaseback().set(this);
	}

}
