import moment from 'moment';

export interface IBackendLeasebackStatementSpan {
	created_at     ?: Date;
	updated_at     ?: Date;
	start          ?: Date;
	end            ?: Date;
}

export interface ILeasebackStatementSpan {
	createdAt     ?: Date;
	updatedAt     ?: Date;
	start         ?: Date;
	end           ?: Date;
}

export class LeasebackStatementSpan implements ILeasebackStatementSpan {
	static readonly clean = Object.freeze(new LeasebackStatementSpan());
	createdAt     ?: Date;
	updatedAt     ?: Date;
	start         ?: Date;
	end           ?: Date;

	parse(obj: IBackendLeasebackStatementSpan) {
		const offset     = Number(new Date().getTimezoneOffset() / 60);
		this.createdAt   = obj.created_at ? moment(obj.created_at ).subtract(offset, 'hours').toDate() : LeasebackStatementSpan.clean.createdAt ;
		this.updatedAt   = obj.updated_at ? moment(obj.updated_at ).subtract(offset, 'hours').toDate() : LeasebackStatementSpan.clean.updatedAt ;
		this.start       = obj.start      ? moment(obj.start      ).                          toDate() : LeasebackStatementSpan.clean.start     ;
		this.end         = obj.end        ? moment(obj.end        ).                          toDate() : LeasebackStatementSpan.clean.end       ;

		return this;
	}

	set(obj: ILeasebackStatementSpan) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = LeasebackStatementSpan.clean[key];
		});

		return this;
	}

	clone() {
		return new LeasebackStatementSpan().set(this);
	}

}
