import moment from 'moment';

// import { SchedomaticDate, IBackendSchedomaticDate, ISchedomaticDate } from '../equipment.model';
// import { IBackendReservationHour, IReservationHour, ReservationHour } from './reservation-hour.model';
import { IBackendSchedomaticDate, ISchedomaticDate, SchedomaticDate } from "./schedomatic-date.model";


export interface IBackendSchedomaticReservation {
	start ?: Date;
	end   ?: Date;

	id                  ?: string;
	updateListener      ?: number;
	is_new              ?: number;
	is_valid            ?: number;
	date_selected_index ?: number;
	reservation_type_id ?: number;
	dates               ?: IBackendSchedomaticDate[];
	resName 		    ?: string;
	lessonId 		    ?: number;
}

export interface ISchedomaticReservation {
	start ?: Date;
	end   ?: Date;

	id                ?: string;
	updateListener    ?: number;
	isNew             ?: boolean;
	isValid           ?: boolean;
	dateSelectedIndex ?: number;
	reservationTypeId ?: number;
	dates             ?: ISchedomaticDate[];
	resName 		  ?: string;
	lessonId 		  ?: number;
}

export class SchedomaticReservation implements ISchedomaticReservation {
	static readonly clean = Object.freeze(new SchedomaticReservation());
	start ?: Date;
	end   ?: Date;

	id                       = '';
	updateListener           = 0;
	isNew                    = false;
	isValid                  = true;
	dateSelectedIndex        = 0;
	reservationTypeId        = 0;
	dates: SchedomaticDate[] = [];
	resName                  = '';
	lessonId 		     	 = 0;

	parse(obj: IBackendSchedomaticReservation) {
		// this.start = obj.start ? moment.utc(obj.start).toDate() : SchedomaticReservation.clean.start;
		// this.end   = obj.end   ? moment.utc(obj.end  ).toDate() : SchedomaticReservation.clean.end  ;

		/**
		 * These moment.utc functions are behaving strangely, were an hour off :c
		 * so we're using offset to convert to local times
		 */
		const offset = Number(new Date().getTimezoneOffset() / 60);
		this.start = obj.start ? moment(obj.start ).subtract(offset, 'hours').toDate() : SchedomaticReservation.clean.start ;
		this.end   = obj.end   ? moment(obj.end   ).subtract(offset, 'hours').toDate() : SchedomaticReservation.clean.end   ;

		this.id = this.getUniqueId();
		this.updateListener    = Number(obj.updateListener      || SchedomaticReservation.clean.updateListener   );
		this.isNew             = Boolean(obj.is_new             || SchedomaticReservation.clean.isNew            );
		this.isValid           = Boolean(obj.is_valid           || SchedomaticReservation.clean.isValid          );
		this.dateSelectedIndex = Number(obj.date_selected_index || SchedomaticReservation.clean.dateSelectedIndex);
		this.reservationTypeId = Number(obj.reservation_type_id || SchedomaticReservation.clean.reservationTypeId);
		this.dates             = (obj.dates || []).map((element) => new SchedomaticDate().parse(element));
		this.resName           = String(obj.resName                   || SchedomaticReservation.clean.resName          );
		this.lessonId    	   = Number(obj.lessonId      || SchedomaticReservation.clean.lessonId   );

		return this;
	}

	set(obj: ISchedomaticReservation) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = SchedomaticReservation.clean[key];
		});

		return this;
	}

	clone() {
		return new SchedomaticReservation().set(this);
	}

	getUniqueId():string {
		return String(Math.floor(Math.random() * Math.floor(Math.random() * Date.now())));
	}
}
