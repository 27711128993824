export interface IBackendPermissions {
	instructorsAvailabilityScheduleView                                      ? : boolean;
	instructorsAvailabilityScheduleCreateEdit                                ? : boolean;
	loaRequestView                                                           ? : boolean;
	loaRequestAccceptOrDeny                                                  ? : boolean;
	graduationRequestView                                                    ? : boolean;
	graduationRequestAccceptOrDeny                                           ? : boolean;
	checkRideRequestView                                                     ? : boolean;
	checkRideRequestAccceptOrDeny                                            ? : boolean;
	viewAllReservations                                                      ? : boolean;
	viewSelfReservations                                                     ? : boolean;
	viewReservationOfAssignedStudents                                        ? : boolean;
	schedOMatic                                                              ? : boolean;
	availabilityFinder                                                       ? : boolean;
	reservationsCreateOwn                                                    ? : boolean;
	reservationsEditOwn                                                      ? : boolean;
	reservationsCancelOwn                                                    ? : boolean;
	reservationsCreateForOthers                                              ? : boolean;
	reservationsEditForOthers                                                ? : boolean;
	reservationsCancelForOthers                                              ? : boolean;
	reservationsCreateForAssignedStudents                                    ? : boolean;
	reservationsEditForAssignedStudents                                      ? : boolean;
	reservationsCancelForAssignedStudents                                    ? : boolean;
	reservationsCheckOutOther                                                ? : boolean;
	reservationsCheckInOther                                                 ? : boolean;
	reservationsCheckOutOwn                                                  ? : boolean;
	reservationsCheckInOwn                                                   ? : boolean;
	reservationsCheckOutAssignedStudents                                     ? : boolean;
	reservationsCheckInAssignedStudents                                      ? : boolean;
	reservationsDispatchSheet                                                ? : boolean;
	reservationsGenerateInvoices                                             ? : boolean;
	reservationsViewInvoices                                                 ? : boolean;
	usersCreateClient                                                        ? : boolean;
	usersCreateTeamMember                                                    ? : boolean;
	usersViewAllClientList                                                   ? : boolean;
	usersViewAllTeamMemberList                                               ? : boolean;
	usersViewOnlyAsignedClientList                                           ? : boolean;
	usersViewOnlySelfClientOrTeamMember                                      ? : boolean;
	clientViewGeneralInfo                                                    ? : boolean;
	clientDeleteDisable                                                      ? : boolean;
	clientEditUpdate                                                         ? : boolean;
	clientEditSelf                                                           ? : boolean;
	clientEnroll                                                             ? : boolean;
	clientViewRecord                                                         ? : boolean;
	clientRequestGraduation                                                  ? : boolean;
	clientRequestCheckRide                                                   ? : boolean;
	clientCredentialsView                                                    ? : boolean;
	clientCredentialsAdd                                                     ? : boolean;
	clientCredentialsResolve                                                 ? : boolean;
	clientCredentialsDisableEnable                                           ? : boolean;
	clientCurrencyCheckoutsEnableDisable                                     ? : boolean;
	clientCurrencyCheckoutsView                                              ? : boolean;
	clientFlightLogAdd                                                       ? : boolean;
	clientFlightLogEdit                                                      ? : boolean;
	clientFlightLogDelete                                                    ? : boolean;
	clientFlightLogView                                                      ? : boolean;
	clientLessonLogEdit                                                      ? : boolean;
	clientLessonLogView                                                      ? : boolean;
	teamMemberUsersGeneralView                                               ? : boolean;
	teamMemberUsersDeleteDisable                                             ? : boolean;
	teamMemberUsersEditUpdate                                                ? : boolean;
	teamMemberUsersEditSelf                                                  ? : boolean;
	teamMemberUserCredentialsView                                            ? : boolean;
	teamMemberUserCredentialsAdd                                             ? : boolean;
	teamMemberUserCredentialsResolve                                         ? : boolean;
	teamMemberUserCredentialsDisableEnable                                   ? : boolean;
	teamMemberUserCurrencyCheckoutsEnableDisable                             ? : boolean;
	teamMemberUserCurrencyCheckoutsView                                      ? : boolean;
	teamMemberFlightLogAddOwnEntry                                           ? : boolean;
	teamMemberFlightLogEditOwnEntry                                          ? : boolean;
	teamMemberFlightLogViewAllEntries                                        ? : boolean;
	coursesCreate                                                            ? : boolean;
	coursesEdit                                                              ? : boolean;
	coursesDisable                                                           ? : boolean;
	coursesViewDetails                                                       ? : boolean;
	coursesCheckRideFailReasonsDropdowns                                     ? : boolean;
	equipmentCreate                                                          ? : boolean;
	equipmentEdit                                                            ? : boolean;
	equipmentDisable                                                         ? : boolean;
	equipmentViewDetails                                                     ? : boolean;
	equipmentCreateSquawks                                                   ? : boolean;
	equipmentResolveSquawks                                                  ? : boolean;
	equipmentViewSquawks                                                     ? : boolean;
	equipmentLeaseback                                                       ? : boolean;
	equipmentCredentialsView                                                 ? : boolean;
	equipmentCredentialsAdd                                                  ? : boolean;
	equipmentCredentialsResolve                                              ? : boolean;
	equipmentCredentialsDisableEnable                                        ? : boolean;
	credentialsCreate                                                        ? : boolean;
	credentialsEdit                                                          ? : boolean;
	credentialsDisable                                                       ? : boolean;
	credentialsDelete                                                        ? : boolean;
	credentialsViewDetails                                                   ? : boolean;
	groupsCreateEquipmentGroup                                               ? : boolean;
	groupsEditEquipmentGroup                                                 ? : boolean;
	groupsDeleteEquipmentGroup                                               ? : boolean;
	groupsViewEquipmentGroup                                                 ? : boolean;
	groupsCreateInstructorGroup                                              ? : boolean;
	groupsEditInstructorGroup                                                ? : boolean;
	groupsDeleteInstructorGroup                                              ? : boolean;
	groupsViewInstructorGroup                                                ? : boolean;
	ftaStudentsViewStudentList                                               ? : boolean;
	ftaComplianceFromFtaToTheUniversityUpload                                ? : boolean;
	ftaComplianceFromFtaToTheUniversityResolve                               ? : boolean;
	ftaComplianceFromFtaToTheUniversityDisable                               ? : boolean;
	ftaComplianceFromFtaToTheUniversityViewDocuments                         ? : boolean;
	universityComplianceFromTheUniversityToTheFtaAcknowledgementMarkAsUnread ? : boolean;
	universityComplianceFromTheUniversityToTheFtaViewDocuments               ? : boolean;
	ftaAccountInfoViewAccountInfo                                            ? : boolean;
	ftaAccountInfoEditAccountInfo                                            ? : boolean;
	settingsEditGeneralSettings                                              ? : boolean;
	settingsEditPermissions                                                  ? : boolean;
	billingAccountingSettings                                                ? : boolean;
	gradingGradeStudent                                                      ? : boolean;
}

export interface IPermissions {
	instructorsAvailabilityScheduleView                                      ? : boolean;
	instructorsAvailabilityScheduleCreateEdit                                ? : boolean;
	loaRequestView                                                           ? : boolean;
	loaRequestAccceptOrDeny                                                  ? : boolean;
	graduationRequestView                                                    ? : boolean;
	graduationRequestAccceptOrDeny                                           ? : boolean;
	checkRideRequestView                                                     ? : boolean;
	checkRideRequestAccceptOrDeny                                            ? : boolean;
	viewAllReservations                                                      ? : boolean;
	viewSelfReservations                                                     ? : boolean;
	viewReservationOfAssignedStudents                                        ? : boolean;
	schedOMatic                                                              ? : boolean;
	availabilityFinder                                                       ? : boolean;
	reservationsCreateOwn                                                    ? : boolean;
	reservationsEditOwn                                                      ? : boolean;
	reservationsCancelOwn                                                    ? : boolean;
	reservationsCreateForOthers                                              ? : boolean;
	reservationsEditForOthers                                                ? : boolean;
	reservationsCancelForOthers                                              ? : boolean;
	reservationsCreateForAssignedStudents                                    ? : boolean;
	reservationsEditForAssignedStudents                                      ? : boolean;
	reservationsCancelForAssignedStudents                                    ? : boolean;
	reservationsCheckOutOther                                                ? : boolean;
	reservationsCheckInOther                                                 ? : boolean;
	reservationsCheckOutOwn                                                  ? : boolean;
	reservationsCheckInOwn                                                   ? : boolean;
	reservationsCheckOutAssignedStudents                                     ? : boolean;
	reservationsCheckInAssignedStudents                                      ? : boolean;
	reservationsDispatchSheet                                                ? : boolean;
	reservationsGenerateInvoices                                             ? : boolean;
	reservationsViewInvoices                                                 ? : boolean;
	usersCreateClient                                                        ? : boolean;
	usersCreateTeamMember                                                    ? : boolean;
	usersViewAllClientList                                                   ? : boolean;
	usersViewAllTeamMemberList                                               ? : boolean;
	usersViewOnlyAsignedClientList                                           ? : boolean;
	usersViewOnlySelfClientOrTeamMember                                      ? : boolean;
	clientViewGeneralInfo                                                    ? : boolean;
	clientDeleteDisable                                                      ? : boolean;
	clientEditUpdate                                                         ? : boolean;
	clientEditSelf                                                           ? : boolean;
	clientEnroll                                                             ? : boolean;
	clientViewRecord                                                         ? : boolean;
	clientRequestGraduation                                                  ? : boolean;
	clientRequestCheckRide                                                   ? : boolean;
	clientCredentialsView                                                    ? : boolean;
	clientCredentialsAdd                                                     ? : boolean;
	clientCredentialsResolve                                                 ? : boolean;
	clientCredentialsDisableEnable                                           ? : boolean;
	clientCurrencyCheckoutsEnableDisable                                     ? : boolean;
	clientCurrencyCheckoutsView                                              ? : boolean;
	clientFlightLogAdd                                                       ? : boolean;
	clientFlightLogEdit                                                      ? : boolean;
	clientFlightLogDelete                                                    ? : boolean;
	clientFlightLogView                                                      ? : boolean;
	clientLessonLogEdit                                                      ? : boolean;
	clientLessonLogView                                                      ? : boolean;
	teamMemberUsersGeneralView                                               ? : boolean;
	teamMemberUsersDeleteDisable                                             ? : boolean;
	teamMemberUsersEditUpdate                                                ? : boolean;
	teamMemberUsersEditSelf                                                  ? : boolean;
	teamMemberUserCredentialsView                                            ? : boolean;
	teamMemberUserCredentialsAdd                                             ? : boolean;
	teamMemberUserCredentialsResolve                                         ? : boolean;
	teamMemberUserCredentialsDisableEnable                                   ? : boolean;
	teamMemberUserCurrencyCheckoutsEnableDisable                             ? : boolean;
	teamMemberUserCurrencyCheckoutsView                                      ? : boolean;
	teamMemberFlightLogAddOwnEntry                                           ? : boolean;
	teamMemberFlightLogEditOwnEntry                                          ? : boolean;
	teamMemberFlightLogViewAllEntries                                        ? : boolean;
	coursesCreate                                                            ? : boolean;
	coursesEdit                                                              ? : boolean;
	coursesDisable                                                           ? : boolean;
	coursesViewDetails                                                       ? : boolean;
	coursesCheckRideFailReasonsDropdowns                                     ? : boolean;
	equipmentCreate                                                          ? : boolean;
	equipmentEdit                                                            ? : boolean;
	equipmentDisable                                                         ? : boolean;
	equipmentViewDetails                                                     ? : boolean;
	equipmentCreateSquawks                                                   ? : boolean;
	equipmentResolveSquawks                                                  ? : boolean;
	equipmentViewSquawks                                                     ? : boolean;
	equipmentLeaseback                                                       ? : boolean;
	equipmentCredentialsView                                                 ? : boolean;
	equipmentCredentialsAdd                                                  ? : boolean;
	equipmentCredentialsResolve                                              ? : boolean;
	equipmentCredentialsDisableEnable                                        ? : boolean;
	credentialsCreate                                                        ? : boolean;
	credentialsEdit                                                          ? : boolean;
	credentialsDisable                                                       ? : boolean;
	credentialsDelete                                                        ? : boolean;
	credentialsViewDetails                                                   ? : boolean;
	groupsCreateEquipmentGroup                                               ? : boolean;
	groupsEditEquipmentGroup                                                 ? : boolean;
	groupsDeleteEquipmentGroup                                               ? : boolean;
	groupsViewEquipmentGroup                                                 ? : boolean;
	groupsCreateInstructorGroup                                              ? : boolean;
	groupsEditInstructorGroup                                                ? : boolean;
	groupsDeleteInstructorGroup                                              ? : boolean;
	groupsViewInstructorGroup                                                ? : boolean;
	ftaStudentsViewStudentList                                               ? : boolean;
	ftaComplianceFromFtaToTheUniversityUpload                                ? : boolean;
	ftaComplianceFromFtaToTheUniversityResolve                               ? : boolean;
	ftaComplianceFromFtaToTheUniversityDisable                               ? : boolean;
	ftaComplianceFromFtaToTheUniversityViewDocuments                         ? : boolean;
	universityComplianceFromTheUniversityToTheFtaAcknowledgementMarkAsUnread ? : boolean;
	universityComplianceFromTheUniversityToTheFtaViewDocuments               ? : boolean;
	ftaAccountInfoViewAccountInfo                                            ? : boolean;
	ftaAccountInfoEditAccountInfo                                            ? : boolean;
	settingsEditGeneralSettings                                              ? : boolean;
	settingsEditPermissions                                                  ? : boolean;
	billingAccountingSettings                                                ? : boolean;
	gradingGradeStudent                                                      ? : boolean;
}

export class Permissions implements IPermissions {
	static readonly clean = Object.freeze(new Permissions());
	instructorsAvailabilityScheduleView                                      = false;
	instructorsAvailabilityScheduleCreateEdit                                = false;
	loaRequestView                                                           = false;
	loaRequestAccceptOrDeny                                                  = false;
	graduationRequestView                                                    = false;
	graduationRequestAccceptOrDeny                                           = false;
	checkRideRequestView                                                     = false;
	checkRideRequestAccceptOrDeny                                            = false;
	viewAllReservations                                                      = false;
	viewSelfReservations                                                     = false;
	viewReservationOfAssignedStudents                                        = false;
	schedOMatic                                                              = false;
	availabilityFinder                                                       = false;
	reservationsCreateOwn                                                    = false;
	reservationsEditOwn                                                      = false;
	reservationsCancelOwn                                                    = false;
	reservationsCreateForOthers                                              = false;
	reservationsEditForOthers                                                = false;
	reservationsCancelForOthers                                              = false;
	reservationsCreateForAssignedStudents                                    = false;
	reservationsEditForAssignedStudents                                      = false;
	reservationsCancelForAssignedStudents                                    = false;
	reservationsCheckOutOther                                                = false;
	reservationsCheckInOther                                                 = false;
	reservationsCheckOutOwn                                                  = false;
	reservationsCheckInOwn                                                   = false;
	reservationsCheckOutAssignedStudents                                     = false;
	reservationsCheckInAssignedStudents                                      = false;
	reservationsDispatchSheet                                                = false;
	reservationsGenerateInvoices                                             = false;
	reservationsViewInvoices                                                 = false;
	usersCreateClient                                                        = false;
	usersCreateTeamMember                                                    = false;
	usersViewAllClientList                                                   = false;
	usersViewAllTeamMemberList                                               = false;
	usersViewOnlyAsignedClientList                                           = false;
	usersViewOnlySelfClientOrTeamMember                                      = false;
	clientViewGeneralInfo                                                    = false;
	clientDeleteDisable                                                      = false;
	clientEditUpdate                                                         = false;
	clientEditSelf                                                           = false;
	clientEnroll                                                             = false;
	clientViewRecord                                                         = false;
	clientRequestGraduation                                                  = false;
	clientRequestCheckRide                                                   = false;
	clientCredentialsView                                                    = false;
	clientCredentialsAdd                                                     = false;
	clientCredentialsResolve                                                 = false;
	clientCredentialsDisableEnable                                           = false;
	clientCurrencyCheckoutsEnableDisable                                     = false;
	clientCurrencyCheckoutsView                                              = false;
	clientFlightLogAdd                                                       = false;
	clientFlightLogEdit                                                      = false;
	clientFlightLogDelete                                                    = false;
	clientFlightLogView                                                      = false;
	clientLessonLogEdit                                                      = false;
	clientLessonLogView                                                      = false;
	teamMemberUsersGeneralView                                               = false;
	teamMemberUsersDeleteDisable                                             = false;
	teamMemberUsersEditUpdate                                                = false;
	teamMemberUsersEditSelf                                                  = false;
	teamMemberUserCredentialsView                                            = false;
	teamMemberUserCredentialsAdd                                             = false;
	teamMemberUserCredentialsResolve                                         = false;
	teamMemberUserCredentialsDisableEnable                                   = false;
	teamMemberUserCurrencyCheckoutsEnableDisable                             = false;
	teamMemberUserCurrencyCheckoutsView                                      = false;
	teamMemberFlightLogAddOwnEntry                                           = false;
	teamMemberFlightLogEditOwnEntry                                          = false;
	teamMemberFlightLogViewAllEntries                                        = false;
	coursesCreate                                                            = false;
	coursesEdit                                                              = false;
	coursesDisable                                                           = false;
	coursesViewDetails                                                       = false;
	coursesCheckRideFailReasonsDropdowns                                     = false;
	equipmentCreate                                                          = false;
	equipmentEdit                                                            = false;
	equipmentDisable                                                         = false;
	equipmentViewDetails                                                     = false;
	equipmentCreateSquawks                                                   = false;
	equipmentResolveSquawks                                                  = false;
	equipmentViewSquawks                                                     = false;
	equipmentLeaseback                                                       = false;
	equipmentCredentialsView                                                 = false;
	equipmentCredentialsAdd                                                  = false;
	equipmentCredentialsResolve                                              = false;
	equipmentCredentialsDisableEnable                                        = false;
	credentialsCreate                                                        = false;
	credentialsEdit                                                          = false;
	credentialsDisable                                                       = false;
	credentialsDelete                                                        = false;
	credentialsViewDetails                                                   = false;
	groupsCreateEquipmentGroup                                               = false;
	groupsEditEquipmentGroup                                                 = false;
	groupsDeleteEquipmentGroup                                               = false;
	groupsViewEquipmentGroup                                                 = false;
	groupsCreateInstructorGroup                                              = false;
	groupsEditInstructorGroup                                                = false;
	groupsDeleteInstructorGroup                                              = false;
	groupsViewInstructorGroup                                                = false;
	ftaStudentsViewStudentList                                               = false;
	ftaComplianceFromFtaToTheUniversityUpload                                = false;
	ftaComplianceFromFtaToTheUniversityResolve                               = false;
	ftaComplianceFromFtaToTheUniversityDisable                               = false;
	ftaComplianceFromFtaToTheUniversityViewDocuments                         = false;
	universityComplianceFromTheUniversityToTheFtaAcknowledgementMarkAsUnread = false;
	universityComplianceFromTheUniversityToTheFtaViewDocuments               = false;
	ftaAccountInfoViewAccountInfo                                            = false;
	ftaAccountInfoEditAccountInfo                                            = false;
	settingsEditGeneralSettings                                              = false;
	settingsEditPermissions                                                  = false;
	billingAccountingSettings                                                = false;
	gradingGradeStudent                                                      = false;

	parse(obj: IBackendPermissions) { 
		this.instructorsAvailabilityScheduleView                                      = Boolean('instructorsAvailabilityScheduleView'                                      in obj ? obj.instructorsAvailabilityScheduleView                                      : Permissions.clean.instructorsAvailabilityScheduleView                                      );
		this.instructorsAvailabilityScheduleCreateEdit                                = Boolean('instructorsAvailabilityScheduleCreateEdit'                                in obj ? obj.instructorsAvailabilityScheduleCreateEdit                                : Permissions.clean.instructorsAvailabilityScheduleCreateEdit                                );
		this.loaRequestView                                                           = Boolean('loaRequestView'                                                           in obj ? obj.loaRequestView                                                           : Permissions.clean.loaRequestView                                                           );
		this.loaRequestAccceptOrDeny                                                  = Boolean('loaRequestAccceptOrDeny'                                                  in obj ? obj.loaRequestAccceptOrDeny                                                  : Permissions.clean.loaRequestAccceptOrDeny                                                  );
		this.graduationRequestView                                                    = Boolean('graduationRequestView'                                                    in obj ? obj.graduationRequestView                                                    : Permissions.clean.graduationRequestView                                                    );
		this.graduationRequestAccceptOrDeny                                           = Boolean('graduationRequestAccceptOrDeny'                                           in obj ? obj.graduationRequestAccceptOrDeny                                           : Permissions.clean.graduationRequestAccceptOrDeny                                           );
		this.checkRideRequestView                                                     = Boolean('checkRideRequestView'                                                     in obj ? obj.checkRideRequestView                                                     : Permissions.clean.checkRideRequestView                                                     );
		this.checkRideRequestAccceptOrDeny                                            = Boolean('checkRideRequestAccceptOrDeny'                                            in obj ? obj.checkRideRequestAccceptOrDeny                                            : Permissions.clean.checkRideRequestAccceptOrDeny                                            );
		this.viewAllReservations                                                      = Boolean('viewAllReservations'                                                      in obj ? obj.viewAllReservations                                                      : Permissions.clean.viewAllReservations                                                      );
		this.viewSelfReservations                                                     = Boolean('viewSelfReservations'                                                     in obj ? obj.viewSelfReservations                                                     : Permissions.clean.viewSelfReservations                                                     );
		this.viewReservationOfAssignedStudents                                        = Boolean('viewReservationOfAssignedStudents'                                        in obj ? obj.viewReservationOfAssignedStudents                                        : Permissions.clean.viewReservationOfAssignedStudents                                        );
		this.schedOMatic                                                              = Boolean('schedOMatic'                                                              in obj ? obj.schedOMatic                                                              : Permissions.clean.schedOMatic                                                              );
		this.availabilityFinder                                                       = Boolean('availabilityFinder'                                                       in obj ? obj.availabilityFinder                                                       : Permissions.clean.availabilityFinder                                                       );
		this.reservationsCreateOwn                                                    = Boolean('reservationsCreateOwn'                                                    in obj ? obj.reservationsCreateOwn                                                    : Permissions.clean.reservationsCreateOwn                                                    );
		this.reservationsEditOwn                                                      = Boolean('reservationsEditOwn'                                                      in obj ? obj.reservationsEditOwn                                                      : Permissions.clean.reservationsEditOwn                                                      );
		this.reservationsCancelOwn                                                    = Boolean('reservationsCancelOwn'                                                    in obj ? obj.reservationsCancelOwn                                                    : Permissions.clean.reservationsCancelOwn                                                    );
		this.reservationsCreateForOthers                                              = Boolean('reservationsCreateForOthers'                                              in obj ? obj.reservationsCreateForOthers                                              : Permissions.clean.reservationsCreateForOthers                                              );
		this.reservationsEditForOthers                                                = Boolean('reservationsEditForOthers'                                                in obj ? obj.reservationsEditForOthers                                                : Permissions.clean.reservationsEditForOthers                                                );
		this.reservationsCancelForOthers                                              = Boolean('reservationsCancelForOthers'                                              in obj ? obj.reservationsCancelForOthers                                              : Permissions.clean.reservationsCancelForOthers                                              );
		this.reservationsCreateForAssignedStudents                                    = Boolean('reservationsCreateForAssignedStudents'                                    in obj ? obj.reservationsCreateForAssignedStudents                                    : Permissions.clean.reservationsCreateForAssignedStudents                                    );
		this.reservationsEditForAssignedStudents                                      = Boolean('reservationsEditForAssignedStudents'                                      in obj ? obj.reservationsEditForAssignedStudents                                      : Permissions.clean.reservationsEditForAssignedStudents                                      );
		this.reservationsCancelForAssignedStudents                                    = Boolean('reservationsCancelForAssignedStudents'                                    in obj ? obj.reservationsCancelForAssignedStudents                                    : Permissions.clean.reservationsCancelForAssignedStudents                                    );
		this.reservationsCheckOutOther                                                = Boolean('reservationsCheckOutOther'                                                in obj ? obj.reservationsCheckOutOther                                                : Permissions.clean.reservationsCheckOutOther                                                );
		this.reservationsCheckInOther                                                 = Boolean('reservationsCheckInOther'                                                 in obj ? obj.reservationsCheckInOther                                                 : Permissions.clean.reservationsCheckInOther                                                 );
		this.reservationsCheckOutOwn                                                  = Boolean('reservationsCheckOutOwn'                                                  in obj ? obj.reservationsCheckOutOwn                                                  : Permissions.clean.reservationsCheckOutOwn                                                  );
		this.reservationsCheckInOwn                                                   = Boolean('reservationsCheckInOwn'                                                   in obj ? obj.reservationsCheckInOwn                                                   : Permissions.clean.reservationsCheckInOwn                                                   );
		this.reservationsCheckOutAssignedStudents                                     = Boolean('reservationsCheckOutAssignedStudents'                                     in obj ? obj.reservationsCheckOutAssignedStudents                                     : Permissions.clean.reservationsCheckOutAssignedStudents                                     );
		this.reservationsCheckInAssignedStudents                                      = Boolean('reservationsCheckInAssignedStudents'                                      in obj ? obj.reservationsCheckInAssignedStudents                                      : Permissions.clean.reservationsCheckInAssignedStudents                                      );
		this.reservationsDispatchSheet                                                = Boolean('reservationsDispatchSheet'                                                in obj ? obj.reservationsDispatchSheet                                                : Permissions.clean.reservationsDispatchSheet                                                );
		this.reservationsGenerateInvoices                                             = Boolean('reservationsGenerateInvoices'                                             in obj ? obj.reservationsGenerateInvoices                                             : Permissions.clean.reservationsGenerateInvoices                                             );
		this.reservationsViewInvoices                                                 = Boolean('reservationsViewInvoices'                                                 in obj ? obj.reservationsViewInvoices                                                 : Permissions.clean.reservationsViewInvoices                                                 );
		this.usersCreateClient                                                        = Boolean('usersCreateClient'                                                        in obj ? obj.usersCreateClient                                                        : Permissions.clean.usersCreateClient                                                        );
		this.usersCreateTeamMember                                                    = Boolean('usersCreateTeamMember'                                                    in obj ? obj.usersCreateTeamMember                                                    : Permissions.clean.usersCreateTeamMember                                                    );
		this.usersViewAllClientList                                                   = Boolean('usersViewAllClientList'                                                   in obj ? obj.usersViewAllClientList                                                   : Permissions.clean.usersViewAllClientList                                                   );
		this.usersViewAllTeamMemberList                                               = Boolean('usersViewAllTeamMemberList'                                               in obj ? obj.usersViewAllTeamMemberList                                               : Permissions.clean.usersViewAllTeamMemberList                                               );
		this.usersViewOnlyAsignedClientList                                           = Boolean('usersViewOnlyAsignedClientList'                                           in obj ? obj.usersViewOnlyAsignedClientList                                           : Permissions.clean.usersViewOnlyAsignedClientList                                           );
		this.usersViewOnlySelfClientOrTeamMember                                      = Boolean('usersViewOnlySelfClientOrTeamMember'                                      in obj ? obj.usersViewOnlySelfClientOrTeamMember                                      : Permissions.clean.usersViewOnlySelfClientOrTeamMember                                      );
		this.clientViewGeneralInfo                                                    = Boolean('clientViewGeneralInfo'                                                    in obj ? obj.clientViewGeneralInfo                                                    : Permissions.clean.clientViewGeneralInfo                                                    );
		this.clientDeleteDisable                                                      = Boolean('clientDeleteDisable'                                                      in obj ? obj.clientDeleteDisable                                                      : Permissions.clean.clientDeleteDisable                                                      );
		this.clientEditUpdate                                                         = Boolean('clientEditUpdate'                                                         in obj ? obj.clientEditUpdate                                                         : Permissions.clean.clientEditUpdate                                                         );
		this.clientEditSelf                                                           = Boolean('clientEditSelf'                                                           in obj ? obj.clientEditSelf                                                           : Permissions.clean.clientEditSelf                                                           );
		this.clientEnroll                                                             = Boolean('clientEnroll'                                                             in obj ? obj.clientEnroll                                                             : Permissions.clean.clientEnroll                                                             );
		this.clientViewRecord                                                         = Boolean('clientViewRecord'                                                         in obj ? obj.clientViewRecord                                                         : Permissions.clean.clientViewRecord                                                         );
		this.clientRequestGraduation                                                  = Boolean('clientRequestGraduation'                                                  in obj ? obj.clientRequestGraduation                                                  : Permissions.clean.clientRequestGraduation                                                  );
		this.clientRequestCheckRide                                                   = Boolean('clientRequestCheckRide'                                                   in obj ? obj.clientRequestCheckRide                                                   : Permissions.clean.clientRequestCheckRide                                                   );
		this.clientCredentialsView                                                    = Boolean('clientCredentialsView'                                                    in obj ? obj.clientCredentialsView                                                    : Permissions.clean.clientCredentialsView                                                    );
		this.clientCredentialsAdd                                                     = Boolean('clientCredentialsAdd'                                                     in obj ? obj.clientCredentialsAdd                                                     : Permissions.clean.clientCredentialsAdd                                                     );
		this.clientCredentialsResolve                                                 = Boolean('clientCredentialsResolve'                                                 in obj ? obj.clientCredentialsResolve                                                 : Permissions.clean.clientCredentialsResolve                                                 );
		this.clientCredentialsDisableEnable                                           = Boolean('clientCredentialsDisableEnable'                                           in obj ? obj.clientCredentialsDisableEnable                                           : Permissions.clean.clientCredentialsDisableEnable                                           );
		this.clientCurrencyCheckoutsEnableDisable                                     = Boolean('clientCurrencyCheckoutsEnableDisable'                                     in obj ? obj.clientCurrencyCheckoutsEnableDisable                                     : Permissions.clean.clientCurrencyCheckoutsEnableDisable                                     );
		this.clientCurrencyCheckoutsView                                              = Boolean('clientCurrencyCheckoutsView'                                              in obj ? obj.clientCurrencyCheckoutsView                                              : Permissions.clean.clientCurrencyCheckoutsView                                              );
		this.clientFlightLogAdd                                                       = Boolean('clientFlightLogAdd'                                                       in obj ? obj.clientFlightLogAdd                                                       : Permissions.clean.clientFlightLogAdd                                                       );
		this.clientFlightLogEdit                                                      = Boolean('clientFlightLogEdit'                                                      in obj ? obj.clientFlightLogEdit                                                      : Permissions.clean.clientFlightLogEdit                                                      );
		this.clientFlightLogDelete                                                    = Boolean('clientFlightLogDelete'                                                    in obj ? obj.clientFlightLogDelete                                                    : Permissions.clean.clientFlightLogDelete                                                    );
		this.clientFlightLogView                                                      = Boolean('clientFlightLogView'                                                      in obj ? obj.clientFlightLogView                                                      : Permissions.clean.clientFlightLogView                                                      );
		this.clientLessonLogEdit                                                      = Boolean('clientLessonLogEdit'                                                      in obj ? obj.clientLessonLogEdit                                                      : Permissions.clean.clientLessonLogEdit                                                      );
		this.clientLessonLogView                                                      = Boolean('clientLessonLogView'                                                      in obj ? obj.clientLessonLogView                                                      : Permissions.clean.clientLessonLogView                                                      );
		this.teamMemberUsersGeneralView                                               = Boolean('teamMemberUsersGeneralView'                                               in obj ? obj.teamMemberUsersGeneralView                                               : Permissions.clean.teamMemberUsersGeneralView                                               );
		this.teamMemberUsersDeleteDisable                                             = Boolean('teamMemberUsersDeleteDisable'                                             in obj ? obj.teamMemberUsersDeleteDisable                                             : Permissions.clean.teamMemberUsersDeleteDisable                                             );
		this.teamMemberUsersEditUpdate                                                = Boolean('teamMemberUsersEditUpdate'                                                in obj ? obj.teamMemberUsersEditUpdate                                                : Permissions.clean.teamMemberUsersEditUpdate                                                );
		this.teamMemberUsersEditSelf                                                  = Boolean('teamMemberUsersEditSelf'                                                  in obj ? obj.teamMemberUsersEditSelf                                                  : Permissions.clean.teamMemberUsersEditSelf                                                  );
		this.teamMemberUserCredentialsView                                            = Boolean('teamMemberUserCredentialsView'                                            in obj ? obj.teamMemberUserCredentialsView                                            : Permissions.clean.teamMemberUserCredentialsView                                            );
		this.teamMemberUserCredentialsAdd                                             = Boolean('teamMemberUserCredentialsAdd'                                             in obj ? obj.teamMemberUserCredentialsAdd                                             : Permissions.clean.teamMemberUserCredentialsAdd                                             );
		this.teamMemberUserCredentialsResolve                                         = Boolean('teamMemberUserCredentialsResolve'                                         in obj ? obj.teamMemberUserCredentialsResolve                                         : Permissions.clean.teamMemberUserCredentialsResolve                                         );
		this.teamMemberUserCredentialsDisableEnable                                   = Boolean('teamMemberUserCredentialsDisableEnable'                                   in obj ? obj.teamMemberUserCredentialsDisableEnable                                   : Permissions.clean.teamMemberUserCredentialsDisableEnable                                   );
		this.teamMemberUserCurrencyCheckoutsEnableDisable                             = Boolean('teamMemberUserCurrencyCheckoutsEnableDisable'                             in obj ? obj.teamMemberUserCurrencyCheckoutsEnableDisable                             : Permissions.clean.teamMemberUserCurrencyCheckoutsEnableDisable                             );
		this.teamMemberUserCurrencyCheckoutsView                                      = Boolean('teamMemberUserCurrencyCheckoutsView'                                      in obj ? obj.teamMemberUserCurrencyCheckoutsView                                      : Permissions.clean.teamMemberUserCurrencyCheckoutsView                                      );
		this.teamMemberFlightLogAddOwnEntry                                           = Boolean('teamMemberFlightLogAddOwnEntry'                                           in obj ? obj.teamMemberFlightLogAddOwnEntry                                           : Permissions.clean.teamMemberFlightLogAddOwnEntry                                           );
		this.teamMemberFlightLogEditOwnEntry                                          = Boolean('teamMemberFlightLogEditOwnEntry'                                          in obj ? obj.teamMemberFlightLogEditOwnEntry                                          : Permissions.clean.teamMemberFlightLogEditOwnEntry                                          );
		this.teamMemberFlightLogViewAllEntries                                        = Boolean('teamMemberFlightLogViewAllEntries'                                        in obj ? obj.teamMemberFlightLogViewAllEntries                                        : Permissions.clean.teamMemberFlightLogViewAllEntries                                        );
		this.coursesCreate                                                            = Boolean('coursesCreate'                                                            in obj ? obj.coursesCreate                                                            : Permissions.clean.coursesCreate                                                            );
		this.coursesEdit                                                              = Boolean('coursesEdit'                                                              in obj ? obj.coursesEdit                                                              : Permissions.clean.coursesEdit                                                              );
		this.coursesDisable                                                           = Boolean('coursesDisable'                                                           in obj ? obj.coursesDisable                                                           : Permissions.clean.coursesDisable                                                           );
		this.coursesViewDetails                                                       = Boolean('coursesViewDetails'                                                       in obj ? obj.coursesViewDetails                                                       : Permissions.clean.coursesViewDetails                                                       );
		this.coursesCheckRideFailReasonsDropdowns                                     = Boolean('coursesCheckRideFailReasonsDropdowns'                                     in obj ? obj.coursesCheckRideFailReasonsDropdowns                                     : Permissions.clean.coursesCheckRideFailReasonsDropdowns                                     );
		this.equipmentCreate                                                          = Boolean('equipmentCreate'                                                          in obj ? obj.equipmentCreate                                                          : Permissions.clean.equipmentCreate                                                          );
		this.equipmentEdit                                                            = Boolean('equipmentEdit'                                                            in obj ? obj.equipmentEdit                                                            : Permissions.clean.equipmentEdit                                                            );
		this.equipmentDisable                                                         = Boolean('equipmentDisable'                                                         in obj ? obj.equipmentDisable                                                         : Permissions.clean.equipmentDisable                                                         );
		this.equipmentViewDetails                                                     = Boolean('equipmentViewDetails'                                                     in obj ? obj.equipmentViewDetails                                                     : Permissions.clean.equipmentViewDetails                                                     );
		this.equipmentCreateSquawks                                                   = Boolean('equipmentCreateSquawks'                                                   in obj ? obj.equipmentCreateSquawks                                                   : Permissions.clean.equipmentCreateSquawks                                                   );
		this.equipmentResolveSquawks                                                  = Boolean('equipmentResolveSquawks'                                                  in obj ? obj.equipmentResolveSquawks                                                  : Permissions.clean.equipmentResolveSquawks                                                  );
		this.equipmentViewSquawks                                                     = Boolean('equipmentViewSquawks'                                                     in obj ? obj.equipmentViewSquawks                                                     : Permissions.clean.equipmentViewSquawks                                                     );
		this.equipmentLeaseback                                                       = Boolean('equipmentLeaseback'                                                       in obj ? obj.equipmentLeaseback                                                       : Permissions.clean.equipmentLeaseback                                                       );
		this.equipmentCredentialsView                                                 = Boolean('equipmentCredentialsView'                                                 in obj ? obj.equipmentCredentialsView                                                 : Permissions.clean.equipmentCredentialsView                                                 );
		this.equipmentCredentialsAdd                                                  = Boolean('equipmentCredentialsAdd'                                                  in obj ? obj.equipmentCredentialsAdd                                                  : Permissions.clean.equipmentCredentialsAdd                                                  );
		this.equipmentCredentialsResolve                                              = Boolean('equipmentCredentialsResolve'                                              in obj ? obj.equipmentCredentialsResolve                                              : Permissions.clean.equipmentCredentialsResolve                                              );
		this.equipmentCredentialsDisableEnable                                        = Boolean('equipmentCredentialsDisableEnable'                                        in obj ? obj.equipmentCredentialsDisableEnable                                        : Permissions.clean.equipmentCredentialsDisableEnable                                        );
		this.credentialsCreate                                                        = Boolean('credentialsCreate'                                                        in obj ? obj.credentialsCreate                                                        : Permissions.clean.credentialsCreate                                                        );
		this.credentialsEdit                                                          = Boolean('credentialsEdit'                                                          in obj ? obj.credentialsEdit                                                          : Permissions.clean.credentialsEdit                                                          );
		this.credentialsDisable                                                       = Boolean('credentialsDisable'                                                       in obj ? obj.credentialsDisable                                                       : Permissions.clean.credentialsDisable                                                       );
		this.credentialsDelete                                                        = Boolean('credentialsDelete'                                                        in obj ? obj.credentialsDelete                                                        : Permissions.clean.credentialsDelete                                                        );
		this.credentialsViewDetails                                                   = Boolean('credentialsViewDetails'                                                   in obj ? obj.credentialsViewDetails                                                   : Permissions.clean.credentialsViewDetails                                                   );
		this.groupsCreateEquipmentGroup                                               = Boolean('groupsCreateEquipmentGroup'                                               in obj ? obj.groupsCreateEquipmentGroup                                               : Permissions.clean.groupsCreateEquipmentGroup                                               );
		this.groupsEditEquipmentGroup                                                 = Boolean('groupsEditEquipmentGroup'                                                 in obj ? obj.groupsEditEquipmentGroup                                                 : Permissions.clean.groupsEditEquipmentGroup                                                 );
		this.groupsDeleteEquipmentGroup                                               = Boolean('groupsDeleteEquipmentGroup'                                               in obj ? obj.groupsDeleteEquipmentGroup                                               : Permissions.clean.groupsDeleteEquipmentGroup                                               );
		this.groupsViewEquipmentGroup                                                 = Boolean('groupsViewEquipmentGroup'                                                 in obj ? obj.groupsViewEquipmentGroup                                                 : Permissions.clean.groupsViewEquipmentGroup                                                 );
		this.groupsCreateInstructorGroup                                              = Boolean('groupsCreateInstructorGroup'                                              in obj ? obj.groupsCreateInstructorGroup                                              : Permissions.clean.groupsCreateInstructorGroup                                              );
		this.groupsEditInstructorGroup                                                = Boolean('groupsEditInstructorGroup'                                                in obj ? obj.groupsEditInstructorGroup                                                : Permissions.clean.groupsEditInstructorGroup                                                );
		this.groupsDeleteInstructorGroup                                              = Boolean('groupsDeleteInstructorGroup'                                              in obj ? obj.groupsDeleteInstructorGroup                                              : Permissions.clean.groupsDeleteInstructorGroup                                              );
		this.groupsViewInstructorGroup                                                = Boolean('groupsViewInstructorGroup'                                                in obj ? obj.groupsViewInstructorGroup                                                : Permissions.clean.groupsViewInstructorGroup                                                );
		this.ftaStudentsViewStudentList                                               = Boolean('ftaStudentsViewStudentList'                                               in obj ? obj.ftaStudentsViewStudentList                                               : Permissions.clean.ftaStudentsViewStudentList                                               );
		this.ftaComplianceFromFtaToTheUniversityUpload                                = Boolean('ftaComplianceFromFtaToTheUniversityUpload'                                in obj ? obj.ftaComplianceFromFtaToTheUniversityUpload                                : Permissions.clean.ftaComplianceFromFtaToTheUniversityUpload                                );
		this.ftaComplianceFromFtaToTheUniversityResolve                               = Boolean('ftaComplianceFromFtaToTheUniversityResolve'                               in obj ? obj.ftaComplianceFromFtaToTheUniversityResolve                               : Permissions.clean.ftaComplianceFromFtaToTheUniversityResolve                               );
		this.ftaComplianceFromFtaToTheUniversityDisable                               = Boolean('ftaComplianceFromFtaToTheUniversityDisable'                               in obj ? obj.ftaComplianceFromFtaToTheUniversityDisable                               : Permissions.clean.ftaComplianceFromFtaToTheUniversityDisable                               );
		this.ftaComplianceFromFtaToTheUniversityViewDocuments                         = Boolean('ftaComplianceFromFtaToTheUniversityViewDocuments'                         in obj ? obj.ftaComplianceFromFtaToTheUniversityViewDocuments                         : Permissions.clean.ftaComplianceFromFtaToTheUniversityViewDocuments                         );
		this.universityComplianceFromTheUniversityToTheFtaAcknowledgementMarkAsUnread = Boolean('universityComplianceFromTheUniversityToTheFtaAcknowledgementMarkAsUnread' in obj ? obj.universityComplianceFromTheUniversityToTheFtaAcknowledgementMarkAsUnread : Permissions.clean.universityComplianceFromTheUniversityToTheFtaAcknowledgementMarkAsUnread );
		this.universityComplianceFromTheUniversityToTheFtaViewDocuments               = Boolean('universityComplianceFromTheUniversityToTheFtaViewDocuments'               in obj ? obj.universityComplianceFromTheUniversityToTheFtaViewDocuments               : Permissions.clean.universityComplianceFromTheUniversityToTheFtaViewDocuments               );
		this.ftaAccountInfoViewAccountInfo                                            = Boolean('ftaAccountInfoViewAccountInfo'                                            in obj ? obj.ftaAccountInfoViewAccountInfo                                            : Permissions.clean.ftaAccountInfoViewAccountInfo                                            );
		this.ftaAccountInfoEditAccountInfo                                            = Boolean('ftaAccountInfoEditAccountInfo'                                            in obj ? obj.ftaAccountInfoEditAccountInfo                                            : Permissions.clean.ftaAccountInfoEditAccountInfo                                            );
		this.settingsEditGeneralSettings                                              = Boolean('settingsEditGeneralSettings'                                              in obj ? obj.settingsEditGeneralSettings                                              : Permissions.clean.settingsEditGeneralSettings                                              );
		this.settingsEditPermissions                                                  = Boolean('settingsEditPermissions'                                                  in obj ? obj.settingsEditPermissions                                                  : Permissions.clean.settingsEditPermissions                                                  );
		this.billingAccountingSettings                                                = Boolean('billingAccountingSettings'                                                in obj ? obj.billingAccountingSettings                                                : Permissions.clean.billingAccountingSettings                                                );
		this.gradingGradeStudent                                                      = Boolean('gradingGradeStudent'                                                      in obj ? obj.gradingGradeStudent                                                      : Permissions.clean.gradingGradeStudent                                                      );
		
		return this;
	}

	set(obj: IPermissions) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = Permissions.clean[key];
		});

		return this;
	}

	clone() {
		return new Permissions().set(this);
	}
}
