import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';


@Component({
	selector: 'app-snackbar',
	templateUrl: './snackbar.component.html',
	styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {
	timeout: any;


	constructor(
		@Inject(MAT_SNACK_BAR_DATA) public data: any,
		private _snackRef: MatSnackBarRef<SnackbarComponent>,
	) {}

	ngOnInit() {
		this.timeout = setTimeout(() => {
			this._snackRef.dismiss();
		}, this.data.seconds * 1000);
	}

	action() {
		if (this.data.action.toUpperCase() === 'CLOSE') {
			this._snackRef.dismiss();
		}
	}

	mouseEnter() {
		clearTimeout(this.timeout);
	}

	mouseLeave() {
		this.timeout = setTimeout(() => {
			this._snackRef.dismiss();
		}, this.data.seconds * 1000);
	}

}
