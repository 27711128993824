import moment from 'moment';
import { IBackendUser, IUser, User } from './user.model';
import { IBackendLesson, ILesson, Lesson } from './course/lesson.model';
import { IBackendEquipment, Equipment, IEquipment } from './equipment.model';
import { IBackendLog, ILog, Log } from './logs/log.model';
import { EventCredentials, IBackendEventCredentials, IEventCredentials } from './event-credentials.model';
import { IBackendSquawk, ISquawks, Squawk } from './squawk.model';
import { Note } from './note.model';
import { IBackendItem, IItem, Item } from './item.model';

export interface IEventPermissions {
  showInvoice          ?: boolean;
  showReleaseFlight    ?: boolean;
  showCheckIn          ?: boolean;
  showDispatchSheet    ?: boolean;
  showEditDetails      ?: boolean;
  showCancelFlight     ?: boolean;
  showMatMenu          ?: boolean;
}

export interface IBackendEvent {
	id              ?: number;
	created_at      ?: Date;
	updated_at      ?: Date;
	start           ?: Date;
	end             ?: Date;
	dayStart        ?: Date;
	dayEnd          ?: Date;
	note_updated_at ?: Date;

	is_paid?:                 number;
	client_id?:               number;
	client_name?:             string;
	equipment_id?:            number;
	group_id?:                number;
	client_equipment?:        string;
	instructor_id?:           number;
	instructor_name?:         number;

	syllabus_lesson_id?: 	  number;
	syllabus_lesson_type_id?: number;
	cancel_type_id?:          number;
	cancel_note?:             number;
	status?:                  number;
	is_final?:                number;
	is_new?:                  number;
	name?:                    string;
	hour_start?:              number;
	hour_end?:                number;
	check_out?:               Date;
	check_in?:                Date;
	hobbs1_out?:              number;
	hobbs2_out?:              number;
	hobbs1_in?:               number;
	hobbs2_in?:               number;
	hobbs_in?:                number;
	tach1_out?:               number;
	tach2_out?:               number;
	tach1_in?:                number;
	tach2_in?:                number;
	tach_in?:                 number;
	pre_flight_planning?:     number;
	post_flight_review?:      number;
	oil_used?:                number;
	oil_cost?:                number;
	fuel?:                    number;
	landings?:                number;
	notes?:                   string;
	price_flight?:            number;
	has_headset?:             number;
	headset_price?:           number;
	payment_method?:          number;
	check_number?:            number;
	card_type?:               number;
	payment_amount?:          number;
	has_issues?:              number;
	pending_reservation?:     number;
	can_client_fly?:          number;
	can_instructor_fly?:      number;
	missing_instructor?:      number;
	lesson_title?:            string;
	estimated_flight_time?:   number;
	dpe_name?:                string;
	tail_number?:             string;
	created_by?:              string;
	check_out_by?:            string;
	check_in_by?:             string;
	cancelled_by?:            string;

	items  ?:                 IBackendItem   [];
	squawks?:                 IBackendSquawk [];
	crew?:                    IBackendUser   [];
	crew_ids?:                number[];
	array_notes?:             string[];

	updated_by?:             IBackendUser;
	instructor:              IBackendUser;
	note_updated_by:         IBackendUser;
	client?:                 IBackendUser;
	lesson?:                 IBackendLesson;
	equipment?:              IBackendEquipment;
	log?:                    IBackendLog;
	credentials?:            IBackendEventCredentials;

	check_ride_options?: number;
	course_passed_check_ride_file?: string;
	course_failed_check_ride_file?: string;
	ground_or_flight?: number;

	syllabus_course_id?: number;
	missing_enrollment?: boolean;
	location?: number;
}

export interface IEvent {
	id?: number;
	createdAt     ?: Date;
	updatedAt     ?: Date;
	start         ?: Date;
	end           ?: Date;
	dayStart      ?: Date;
	dayEnd        ?: Date;
	noteUpdatedAt ?: Date;

	isPaid              ?: boolean;
	clientId            ?: number;
	clientName          ?: string;
	equipmentId         ?: number;
	equipmentGroupId    ?: number;
	clientEquipment     ?: string;
	instructorId        ?: number;
	instructorName      ?: string;

	lessonTypeId        ?: number;
	lessonId            ?: number;
	cancelTypeId        ?: number;
	cancelType          ?: string;
	cancellationNote    ?: string;
	status              ?: number;
	isFinal             ?: number;
	isNew               ?: number;
	name                ?: string;
	hourStart           ?: number;
	hourStartMinute     ?: number;
	hourEnd             ?: number;
	hourEndMinute       ?: number;
	checkOut            ?: Date;
	checkIn             ?: Date;
	hobbs1Out           ?: number;
	hobbs2Out           ?: number;
	hobbs1In            ?: number;
	hobbs2In            ?: number;
	hobbsIn             ?: number;
	tach1Out            ?: number;
	tach2Out            ?: number;
	tach1In             ?: number;
	tach2In             ?: number;
	tachIn              ?: number;
	preFlightPlanning   ?: number;
	postFlightReview    ?: number;
	oilUsed             ?: number;
	oilCost             ?: number;
	fuel                ?: number;
	landings            ?: number;
	notes               ?: string;
	priceFlight         ?: number;
	hasHeadset          ?: number;
	headsetPrice        ?: number;
	paymentMethod       ?: number;
	checkNumber         ?: number;
	cardType            ?: number;
	paymentAmount       ?: number;
	hasIssues           ?: boolean;
	pendingReservation  ?: boolean;
	canClientFly        ?: boolean;
	canInstructorFly    ?: boolean;
	missingInstructor   ?: boolean;
	lessonTitle         ?: string;
	estimatedFlightTime ?: number;
	dpeName             ?: string;
	tailNumber          ?: string;
	createdBy           ?: string;
	checkOutBy          ?: string;
	checkInBy           ?: string;
	cancelledBy         ?: string;

	items?:              IItem    [];
	squawks?:            ISquawks [];
	crew?:               IUser    [];
	crewIds?:            number[];
	arrayNotes?:         Note[];

	updatedBy?:         IUser;
	instructor?:        IUser;
	noteUpdatedBy?:     IUser;
	client?:            IUser;
	lesson?:            ILesson;
	equipment?:         IEquipment;
	log?:               ILog;
	credentials?:       IEventCredentials;

	checkRideOption?: number;
	coursePassedCheckRideFile?: string,
	courseFailedCheckRideFile?: string,
	groundOrFlight?: number;

	syllabusCourseId?: number;
	missingEnrollment  ?: boolean;

	location?: number;

  newstatus?: number;

}

export class Event implements IEvent {
	static readonly clean = Object.freeze(new Event());
	id          = 0;
	createdAt     ?: Date;
	updatedAt     ?: Date;
	start         ?: Date;
	showStart     ?: Date;
	end           ?: Date;
	showEnd       ?: Date;
	dayStart      ?: Date;
	dayEnd        ?: Date;
	noteUpdatedAt ?: Date;

	isPaid            = false;
	clientId          = 0;
	clientName        = '';
	equipmentId       = 0;
	equipmentGroupId  = 0;
	clientEquipment   = '';
	instructorId      = 0;
	instructorName    = '';
	/**
	 * ### reservation type id
	 * * id: 1 , name: "Instructional Flight (Dual)"
	 * * id: 2 , name: "Instructional Ground"
	 * * id: 3 , name: "Solo Student"
	 * * id: 4 , name: "Solo Rental"
	 * * id: 5 , name: "Discovery Flight"
	 * * id: 6 , name: "Scenic Flight"
	 * * id: 7 , name: "Instructional Client Aircraft"
	 * * id: 8 , name: "Crew"
	 * * id: 9 , name: "Stage Check Flight"
	 * * id: 10, name: "Maintenance"
	 * * id: 11, name: "Check Ride"
	 * * id: 12, name: "Stage Check Ground"
	 * * id: 13, name: "Time Block"
	 * * id: 14, name: "Discovery Flight (Extended)"
	 * * id: 15, name: "Discovery Flight (Ultimate)"
	 */
	lessonTypeId      = 0;
	lessonId          = 0;
	cancelTypeId      = 0;
	cancelType        = '';
	cancellationNote  = '';
	/**
	 * ### Status
	 * * 1 - En progreso // Check out
	 * * 2 - Check in
	 * * 3 - Cancelled
	 * * 4 - Graded
	 */
	status            = 0;
	isFinal           = 0;
	isNew             = 0;
	name              = '';
	hourStart         = 0;
	hourStartMinute   = 0;
	hourEnd           = 0;
	hourEndMinute     = 0;
	checkOut          : Date;
	checkIn           : Date;
	hobbs             = 0;
	hobbs1Out         = 0;
	hobbs2Out         = 0;
	hobbs1In          = 0;
	hobbs2In          = 0;
	hobbsIn           = 0;
	tach1Out          = 0;
	tach2Out          = 0;
	tach1In           = 0;
	tach2In           = 0;
	tachIn            = 0;
	preFlightPlanning = 0;
	postFlightReview  = 0;
	oilUsed           = 0;
	oilCost           = 0;
	fuel              = 0;
	landings          = 0;
	notes             = '';

	priceFlight         = 0;
	hasHeadset          = 0;
	headsetPrice        = 0;
	paymentMethod       = 0;
	checkNumber         = 0;
	cardType            = 0;
	paymentAmount       = 0;
	hasIssues           = false;
	pendingReservation  = false;
	canClientFly        = true;
	canInstructorFly    = true;
	missingInstructor   = false;
	lessonTitle         = '';
	estimatedFlightTime = 0;
	dpeName             = '';
	tailNumber          = '';
	createdBy           = '';
	checkOutBy          = '';
	checkInBy           = '';
	cancelledBy         = '';

	/** Allow to release events that start on current day or 8 hours later */
	canBeReleased      = false;
	isBeforeStartOfDay = true;
	isAfterEndOfDay    = true;

	items:            Item   [] = [];
	squawks:          Squawk [] = [];
	crew:             User   [] = [];
	crewIds           = [];
	arrayNotes        = [];

	atLeastACrewMemberCannotFlyEquipment = false;
	isClientInactive = false;


	updatedBy:        User;
	instructor:       User;
	noteUpdatedBy:    User;
	client:           User;
	lesson:           Lesson;
	equipment:        Equipment;
	log:              Log;
	credentials?:     EventCredentials;
  	permissions?:     IEventPermissions;

	// invoice
	/** hobbs1In - hobbs1Out */
	invoiceHours = 0;

	checkRideOption = 0;
	coursePassedCheckRideFile = '';
	courseFailedCheckRideFile = '';
	groundOrFlight = 0;

	syllabusCourseId = 0;
	missingEnrollment = false;

	location = 0;
  newstatus = 0;

	parse(obj: IBackendEvent) {
		this.id = Number(obj.id || Event.clean.id);

		// this.createdAt = obj.created_at ? moment.utc(obj.created_at ).local().toDate() : Event.clean.createdAt;
		// this.updatedAt = obj.updated_at ? moment.utc(obj.updated_at ).local().toDate() : Event.clean.updatedAt;
		// this.start     = obj.start      ? moment.utc(obj.start      ).local().toDate() : Event.clean.start    ;
		// this.end       = obj.end        ? moment.utc(obj.end        ).local().toDate() : Event.clean.end      ;
		// this.checkIn   = obj.check_in   ? moment.utc(obj.check_in   ).local().toDate() : Event.clean.checkIn  ;
		// this.checkOut  = obj.check_out  ? moment.utc(obj.check_out  ).local().toDate() : Event.clean.checkOut ;


		/**
		 * These moment.utc functions are behaving strangely, were an hour off :c
		 * so we're using offset to convert to local times
		 */
		const offset = Number(new Date().getTimezoneOffset() / 60);
		this.createdAt = obj.created_at ? moment(obj.created_at).subtract(offset, 'hours').toDate() : Event.clean.createdAt;
		/**
		 * Removed the substract from `updatedAt` because is recived differently from backend
		 * like       "2022-01-15T12:00:00.000000Z"
		 * instead of "2022-01-15 12:00:00"
		 */
		this.updatedAt     = obj.updated_at       ? moment(obj.updated_at      ).                          toDate() : Event.clean.updatedAt;
		this.start         = obj.start            ? moment(obj.start           ).subtract(offset, 'hours').toDate() : Event.clean.start    ;
		this.end           = obj.end              ? moment(obj.end             ).subtract(offset, 'hours').toDate() : Event.clean.end      ;
		this.checkIn       = obj.check_in         ? moment(obj.check_in        ).subtract(offset, 'hours').toDate() : Event.clean.checkIn  ;
		this.checkOut      = obj.check_out        ? moment(obj.check_out       ).subtract(offset, 'hours').toDate() : Event.clean.checkOut ;
		this.noteUpdatedAt = obj.note_updated_at  ? moment(obj.note_updated_at ).subtract(offset, 'hours').toDate() : Event.clean.noteUpdatedAt ;


		// getting local dates without hours
		this.dayStart  = obj.start      ? new Date(this.start.getFullYear(), this.start.getMonth(), this.start.getDate()) : Event.clean.start;
		this.dayEnd    = obj.end        ? new Date(this.end.getFullYear(), this.end.getMonth(), this.end.getDate()) : Event.clean.start;

		// getting local dates number of hours
		this.hourStart         = Number(moment(obj.start ? this.start : Event.clean.start).format('HH'));
		this.hourStartMinute   = Number(moment(obj.start ? this.start : Event.clean.start).format('mm'));
		this.hourEnd           = Number(moment(obj.end   ? this.end   : Event.clean.end  ).format('HH'));
		this.hourEndMinute     = Number(moment(obj.end   ? this.end   : Event.clean.end  ).format('mm'));

		this.isPaid            = Boolean(obj.is_paid                || Event.clean.isPaid           );
		this.clientId          = Number(obj.client_id               || Event.clean.clientId         );
		this.clientName        = String(obj.client_name             || Event.clean.clientName       );
		this.equipmentId       = Number(obj.equipment_id            || Event.clean.equipmentId      );
		this.equipmentGroupId  = Number(obj.group_id                || Event.clean.equipmentGroupId );
		this.clientEquipment   = String(obj.client_equipment        || Event.clean.clientEquipment  );
		this.instructorId      = Number(obj.instructor_id           || Event.clean.instructorId     );
		this.instructorName    = String(obj.instructor_name         || Event.clean.instructorName   );
		this.lessonTypeId      = Number(obj.syllabus_lesson_type_id || Event.clean.lessonTypeId     );
		this.lessonId          = Number(obj.syllabus_lesson_id 		|| Event.clean.lessonId         );
		this.cancelTypeId      = Number(obj.cancel_type_id          || Event.clean.cancelTypeId     );
		this.cancelType        = String(obj.cancel_type_id ? this.getCancelTypeTest(obj.cancel_type_id) : '');
		this.cancellationNote  = String(obj.cancel_note             || Event.clean.cancellationNote );
		this.status            = Number(obj.status                  || Event.clean.status           );
		this.isFinal           = Number(obj.is_final                || Event.clean.isFinal          );
		this.isNew             = Number(obj.is_new                  || Event.clean.isNew            );
		this.name              = String(obj.name                    || Event.clean.name             );
		this.hobbs1Out         = Number(Number(obj.hobbs1_out ) ? (Number(obj.hobbs1_out )).toFixed(1) : Event.clean.hobbs1Out );
		this.hobbs2Out         = Number(Number(obj.hobbs2_out ) ? (Number(obj.hobbs2_out )).toFixed(1) : Event.clean.hobbs2Out );
		this.hobbs1In          = Number(Number(obj.hobbs1_in  ) ? (Number(obj.hobbs1_in  )).toFixed(1) : Event.clean.hobbs1In  );
		this.hobbs2In          = Number(Number(obj.hobbs2_in  ) ? (Number(obj.hobbs2_in  )).toFixed(1) : Event.clean.hobbs2In  );
		this.hobbsIn           = Number(Number(obj.hobbs_in   ) ? (Number(obj.hobbs_in   )).toFixed(1) : Event.clean.hobbsIn   );
		this.hobbs             = Number((this.hobbs1In - this.hobbs1Out ).toFixed(1) || Event.clean.hobbs );
		this.tach1Out          = Number(obj.tach1_out               || Event.clean.tach1Out         );
		this.tach2Out          = Number(obj.tach2_out               || Event.clean.tach2Out         );
		this.tach1In           = Number(obj.tach1_in                || Event.clean.tach1In          );
		this.tach2In           = Number(obj.tach2_in                || Event.clean.tach2In          );
		this.tachIn            = Number(obj.tach_in                 || Event.clean.tachIn           );
		this.preFlightPlanning = Number(obj.pre_flight_planning     || Event.clean.preFlightPlanning);
		this.postFlightReview  = Number(obj.post_flight_review      || Event.clean.postFlightReview );
		this.oilUsed           = Number(obj.oil_used                || Event.clean.oilUsed          );
		this.oilCost           = Number(obj.oil_cost                || Event.clean.oilCost          );
		this.fuel              = Number(obj.fuel                    || Event.clean.fuel             );
		this.landings          = Number(obj.landings                || Event.clean.landings         );
		this.notes             = String(obj.notes                   || Event.clean.notes            );
		this.noteUpdatedBy     = ( obj.note_updated_by   || Event.clean.noteUpdatedBy 	) as User;

		this.priceFlight       = Number(obj.price_flight            || Event.clean.priceFlight      );
		this.hasHeadset        = Number(obj.has_headset             || Event.clean.hasHeadset       );
		this.headsetPrice      = Number(obj.headset_price           || Event.clean.headsetPrice     );
		this.paymentMethod     = Number(obj.payment_method          || Event.clean.paymentMethod    );
		this.checkNumber       = Number(obj.check_number            || Event.clean.checkNumber      );
		this.cardType          = Number(obj.card_type               || Event.clean.cardType         );
		this.paymentAmount     = Number(obj.payment_amount          || Event.clean.paymentAmount    );
		this.hasIssues         = Boolean(
			this.lessonTypeId !== 2 && (obj.has_issues             || Event.clean.hasIssues)
		);
		this.pendingReservation  = Boolean(obj.pending_reservation  || Event.clean.pendingReservation  );
		this.canClientFly        = Boolean( obj.can_client_fly     !== null && obj.can_client_fly     !== undefined ? obj.can_client_fly     : Event.clean.canClientFly      );
		this.canInstructorFly    = Boolean( obj.instructor_id && obj.can_instructor_fly !== null && obj.can_instructor_fly !== undefined ? obj.can_instructor_fly : Event.clean.canInstructorFly  );
		this.missingInstructor   = Boolean( obj.missing_instructor  );
		this.lessonTitle         = String(obj.lesson_title          || Event.clean.lessonTitle         );
		this.estimatedFlightTime = Number(obj.estimated_flight_time || Event.clean.estimatedFlightTime );
		this.dpeName             = String(obj.dpe_name              || Event.clean.dpeName             );
		this.tailNumber          = String(obj.tail_number           || Event.clean.tailNumber          );
		this.createdBy           = String(obj.created_by            || Event.clean.createdBy           );
		this.checkOutBy          = String(obj.check_out_by          || Event.clean.checkOutBy          );
		this.checkInBy           = String(obj.check_in_by           || Event.clean.checkInBy           );
		this.cancelledBy         = String(obj.cancelled_by          || Event.clean.cancelledBy         );

		this.items   = (obj.items   || []).map((element) => new Item   ().parse(element));
		this.squawks = (obj.squawks || []).map((element) => new Squawk ().parse(element));
		this.crew    = (obj.crew    || []).map((element) => new User   ().parse(element));
		if ( obj.crew ) {
			this.crewIds = (obj.crew || []).map((element) => (element.user_id || element.id));
		}
		this.arrayNotes = (obj.array_notes || []).map((element:any) => new Note().parse(element));
		this.atLeastACrewMemberCannotFlyEquipment = Boolean(
			this.crew.length &&
			this.crew.some(client => !client.canClientFly)
		);

		this.updatedBy     = obj.updated_by      ? new User             ().parse(obj.updated_by      ) : new User             ();
		this.instructor    = obj.instructor      ? new User             ().parse(obj.instructor      ) : new User             ();
		this.noteUpdatedBy = obj.note_updated_by ? new User             ().parse(obj.note_updated_by ) : new User             ();
		this.client        = obj.client          ? new User             ().parse(obj.client          ) : new User             ();
		this.lesson        = obj.lesson          ? new Lesson           ().parse(obj.lesson          ) : new Lesson           ();
		this.equipment     = obj.equipment       ? new Equipment        ().parse(obj.equipment       ) : new Equipment        ();
		this.log           = obj.log             ? new Log              ().parse(obj.log             ) : new Log              ();
		this.credentials   = obj.credentials     ? new EventCredentials ().parse(obj.credentials     ) : new EventCredentials ();
		this.permissions   = {
			showInvoice       : false,
			showReleaseFlight : false,
			showCheckIn       : false,
			showDispatchSheet : false,
			showEditDetails   : false,
			showCancelFlight  : false,
			showMatMenu       : false,
		};

		this.checkRideOption = Number(obj.check_ride_options || Event.clean.checkRideOption );
		this.coursePassedCheckRideFile = String(obj.course_passed_check_ride_file || Event.clean.coursePassedCheckRideFile         );
		this.courseFailedCheckRideFile = String(obj.course_failed_check_ride_file || Event.clean.courseFailedCheckRideFile         );
		this.groundOrFlight = Number(obj.ground_or_flight || Event.clean.groundOrFlight );
		this.syllabusCourseId = Number(obj.syllabus_course_id || Event.clean.syllabusCourseId );
		this.missingEnrollment = Boolean(obj.missing_enrollment || Event.clean.missingEnrollment );

		/**
		 * Adding 32 hours to start of day.
		 * So a reservations can be released anytime between current day and
		 * the first 8 hours of next day.
		 *
		 * Must be able to check out maximum 24 before the start time
		 */
		let startToday32 = new Date();
		startToday32 = new Date(startToday32.setHours(0,0,0,0) + ( 32 * 3600000 ));
		// console.groupCollapsed(`event ${this.id}`);

		// console.log(`startToday32 --> `, startToday32);

		// const before24Hrs = moment(new Date()).subtract(1, "days").toDate();
		const before24Hrs = moment(this.start).subtract(1, "days").toDate();
		// console.log(`before24Hrs --> `, before24Hrs);
		const isStartBetweenBefore24HrsAndStartToday32 = moment(this.start).isBetween(before24Hrs, startToday32);
		// console.log(`isStartBetweenBefore24HrsAndStartToday32 --> `, isStartBetweenBefore24HrsAndStartToday32);
		const isCurrentTimeBetweenTimeOfReservation = moment(new Date()).isBetween(this.start, this.end);
		// console.log(`isCurrentTimeBetweenTimeOfReservation --> `, isCurrentTimeBetweenTimeOfReservation);

		this.canBeReleased = isStartBetweenBefore24HrsAndStartToday32 || isCurrentTimeBetweenTimeOfReservation;
		// console.log(`this.canBeReleased --> `, this.canBeReleased);

		// console.groupEnd();
		// ------------------------------- invoice -------------------------------
		this.invoiceHours = Number((this.hobbs1In - this.hobbs1Out).toFixed(1));

		this.isClientInactive = this.checkIsClientInactive(this);

		this.location = Number(obj.location || Event.clean.location );

		return this;
	}

	set(obj: IEvent) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = Event.clean[key];
		});

		return this;
	}

	clone() {
		return new Event().set(this);
	}


	// -------------------------------------------------- ANCHOR: helpers

	getCancelTypeTest(cancelTypeId: number): string {
		switch (cancelTypeId) {
		case 1:
			return 'Sick';
			break;

		case 2:
			return 'Maintenance';
			break;

		case 3:
			return 'Weather';
			break;

		case 5:
			return 'Work';
			break;

		case 4:
			return 'Other';
			break;

		default:
			return '';
			break;
		}
	}

	checkIsClientInactive(event: Event): boolean {
		let isClientInactive = false;
		if (event.crew && event.crew.length > 0) {
			event.crew.forEach(crewMember => {
				if (!crewMember.isActive) {
					isClientInactive = true;
				}
			});
		}
		else if (event.client.fullName) {
			if (!event.client.isActive) {
				isClientInactive = true;
			}
		}
		return isClientInactive;
	}

}
