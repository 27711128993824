import moment from 'moment';
import { GroupElement, IBackendGroupElement, IGroupElement } from './group-element.model';

export interface IBackendGroup {
	id?:                number;
	created_at?:        Date;
	updated_at?:        Date;

	name?:              string;
	description?:       string;
	type?:              number;
	currency?:          number;
	number_days?:       number;
	number_flights?:    number;
	number_hobbs?:      number;
	number_take_day?:   number;
	number_take_night?: number;
	number_land_day?:   number;
	landings_nights?:   number;
	elements?:          IBackendGroupElement[];
}

export interface IGroups {
	id?:            number;
	createdAt?:     Date;
	updatedAt?:     Date;

	name?:          string;
	description?:   string;
	type?:          number;
	currency?:      number;
	numberDays?:    number;
	numberFlights?: number;
	numberHobbs?:   number;
	takeOffsDay?:   number;
	takeOffsNight?: number;
	landingsDay?:   number;
	landingsNight?: number;
	elements?:      IGroupElement[];
}

export class Group implements IGroups {
	static readonly clean = Object.freeze(new Group());
	id = 0;
	createdAt?: Date;
	updatedAt?: Date;

	name                     = '';
	description              = '';
	type                     = 0;
	currency                 = 0;
	numberDays               = 0;
	numberFlights            = 0;
	numberHobbs              = 0;
	takeOffsDay              = 0;
	takeOffsNight            = 0;
	landingsDay              = 0;
	landingsNight            = 0;
	elements: GroupElement[] = [];

	parse(obj: IBackendGroup) {
		this.id = Number(obj.id || Group.clean.id);

		// this.createdAt = obj.created_at ? moment.utc(obj.created_at).toDate() : Group.clean.createdAt;
		// this.updatedAt = obj.updated_at ? moment.utc(obj.updated_at).toDate() : Group.clean.updatedAt;

		/**
		 * These moment.utc functions are behaving strangely, were an hour off :c
		 * so we're using offset to convert to local times
		 */
		const offset = Number(new Date().getTimezoneOffset() / 60);
		this.createdAt = obj.created_at ? moment(obj.created_at ).subtract(offset, 'hours').toDate() : Group.clean.createdAt ;
		this.updatedAt = obj.updated_at ? moment(obj.updated_at ).subtract(offset, 'hours').toDate() : Group.clean.updatedAt ;

		this.name          = String(obj.name              || Group.clean.name         );
		this.description   = String(obj.description       || Group.clean.description  );
		this.type          = Number(obj.type              || Group.clean.type         );
		this.currency      = Number(obj.currency          || Group.clean.currency     );
		this.numberDays    = Number(obj.number_days       || Group.clean.numberDays   );
		this.numberFlights = Number(obj.number_flights    || Group.clean.numberFlights);
		this.numberHobbs   = Number(obj.number_hobbs      || Group.clean.numberHobbs  );
		this.takeOffsDay   = Number(obj.number_take_day   || Group.clean.takeOffsDay  );
		this.takeOffsNight = Number(obj.number_take_night || Group.clean.takeOffsNight);
		this.landingsDay   = Number(obj.number_land_day   || Group.clean.landingsDay  );
		this.landingsNight = Number(obj.landings_nights   || Group.clean.landingsNight);
		this.elements      = (obj.elements || []).map((element) => new GroupElement().parse(element));

		return this;
	}

	set(obj: IGroups) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = Group.clean[key];
		});

		return this;
	}

	clone() {
		return new Group().set(this);
	}
}
