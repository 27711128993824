import moment from 'moment';

export interface IBackendLeasebackInsurancePayment {
	[key: string]   : any;
	created_at     ?: Date;
	updated_at     ?: Date;
	date           ?: Date;
	id             ?: number;
	leaseback_id   ?: number;
	amount         ?: number;
}

export interface ILeasebackInsurancePayment {
	[key: string]  : any;
	createdAt     ?: Date;
	updatedAt     ?: Date;
	date          ?: Date;
	id            ?: number;
	leasebackId   ?: number;
	amount        ?: number;
}

export class LeasebackInsurancePayment implements ILeasebackInsurancePayment {
	static readonly clean = Object.freeze(new LeasebackInsurancePayment());
	[key: string] : any;
	createdAt     ?: Date;
	updatedAt     ?: Date;
	date          ?: Date;
	id            = 0;
	leasebackId   = 0;
	amount        = 0;

	parse(obj: IBackendLeasebackInsurancePayment) {
		const offset   = Number(new Date().getTimezoneOffset() / 60);
		this.createdAt = obj.created_at ? moment(obj.created_at ).subtract(offset, 'hours').toDate() : LeasebackInsurancePayment.clean.createdAt ;
		this.updatedAt = obj.updated_at ? moment(obj.updated_at ).subtract(offset, 'hours').toDate() : LeasebackInsurancePayment.clean.updatedAt ;
		this.date      = obj.date       ? moment(obj.date       )                          .toDate() : LeasebackInsurancePayment.clean.date      ;
		this.id          = Number  (obj.id || LeasebackInsurancePayment.clean.id          );
		this.leasebackId = Number  (obj.id || LeasebackInsurancePayment.clean.leasebackId );
		this.amount      = Number  (obj.id || LeasebackInsurancePayment.clean.amount      );

		return this;
	}

	set(obj: ILeasebackInsurancePayment) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = LeasebackInsurancePayment.clean[key];
		});

		return this;
	}

	clone() {
		return new LeasebackInsurancePayment().set(this);
	}

}
