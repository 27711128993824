import { NgbPaginationNumber } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { Course, IBackendCourse, ICourse } from './course/course.model';
import { CredentialHistory, IBackendCredentialHistory, ICredentialHistory } from './credential-history.model';
import { IBackendRequirementFile, IRequirementFile, RequirementFile } from './requirement-file.model';

export interface IBackendRequirement {
	created_at                  ?: Date    ;
	updated_at                  ?: Date    ;
	id                          ?: number  ;
	user_id                     ?: number  ;
	name                        ?: string  ;
	client_type_ids             ?: number[];
	description                 ?: string  ;
	overridable                 ?: number  ;
	is_resolved                 ?: number  ;
	is_active                   ?: number  ;
	is_enabled                  ?: number  ;
	first_resolved              ?: number  ;
	document_template           ?: number  ;
	document_template_path      ?: string  ;
	document                    ?: string  ;
	type                        ?: '' | 'client' | 'course' | 'maintenance' | 'member' | 'fta'  ;
	role_ids_that_can_override  ?: string[]; // 'Administrator' | 'Instructor' | 'Maintenance' | 'Dispatch' | 'Other'
	picture_file_required       ?: number  ; // este está en los otros "rules"
	requires_to_fly             ?: number  ; // este está en los otros "rules"
	is_test_related             ?: string  ; // 'None' | 'Practical test' | 'Knowledge test'
	maintenance_type            ?: string  ; // 'Inspection' | 'AD' | 'Replacement'
	type_of_logbook_entry       ?: string  ; // 'Airframe' | 'Avionics' | 'Engine' | 'Propeller' | 'None'
	tracked_by_date             ?: string  ; // 'No tracking' | 'Specific date' | 'Days' | 'Months' | 'Years'
	tracked_by_date_amount      ?: number  ;
	tracked_by_time             ?: string  ; // 'No tracking' | 'Hobbs' | 'Tach' | 'TTAF'
	tracked_by_time_amount      ?: number  ;
	tracked_by_time_limit       ?: number  ;
	logbook_remarks_template    ?: string  ;
	member_role_ids             ?: number[]; // 'Administrator' | 'Instructor' | 'Maintenance' | 'Dispatch' | 'Other'
	due_to_expire               ?: number  ;
	expiration_type             ?: string  ; // On the date | Days | Calendar months | Calendar months - last day | Medical
	expiration_type_amount      ?: number  ;
	client_type_id              ?: number  ;
	role_id                     ?: number  ;
	selected                    ?: number  ;
	attempts                    ?: number  ;
	score                       ?: number  ;
	files                       ?: IBackendRequirementFile[];
	history                     ?: IBackendCredentialHistory[];
	rules                       ?: any;

	medical_class               ?: number  ;
	months_until_first          ?: number  ;
	months_until_second         ?: number  ;
	months_until_third          ?: number  ;
	remaining_first_class       ?: Date    ;
	remaining_second_class      ?: Date    ;
	remaining_third_class       ?: Date    ;

	days_remaining_date     ?: string;
	days_remaining          ?: number;
	time_remaining          ?: number;
	is_approved             ?: number;

	approved_by             ?: string;
	resolved_by             ?: string;
	fta_document_type       ?: string;
	revision                ?: string;
	client_subtype_ids      ?: number[];
	course                  ?: IBackendCourse;


	approved_date  ?: string;
	resolved_date  ?: string;
	revision_date  ?: string;
	expiration_date?: string;
	issued_date    ?: string;

  equipment_id ?: number;
  equipment_name                ?: string;
  syllabus_course_id	?: number;
}

export interface IRequirement {
	createdAt            ?: Date;
	updatedAt            ?: Date;
	id                   ?: number;
	userId               ?: number;
	name                 ?: string;
	clientTypeIds        ?: number[];
	description          ?: string;
	overridable          ?: boolean;
	isResolved           ?: boolean;
	isActive             ?: boolean;
	isEnabled            ?: boolean;
	firstResolved        ?: boolean;
	documentTemplate     ?: boolean;
	documentTemplatePath ?: string;
	document             ?: string;
	type                    ?: string;   // 'member' | 'client' | 'course' | 'maintenance'
	roleIdsThatCanOverride  ?: string[]; // 'Administrator' | 'Instructor' | 'Maintenance' | 'Dispatch' | 'Other'
	pictureFileRequired     ?: boolean;  // este está en los otros "rules"
	requiresToFly           ?: boolean;  // este está en los otros "rules"
	isTestRelated           ?: string;   // 'None' | 'Practical test' | 'Knowledge test'
	maintenanceType         ?: string;   // 'Inspection' | 'AD' | 'Replacement'
	typeOfLogbookEntry      ?: string;   // 'Airframe' | 'Avionics' | 'Engine' | 'Propeller' | 'None'
	trackedByDate           ?: string;   // 'No tracking' | 'Specific date' | 'Days' | 'Months' | 'Years'
	trackedByDateAmount     ?: number;
	trackedByTime           ?: string;   // 'No tracking' | 'Hobbs' | 'Tach' | 'TTAF'
	trackedByTimeAmount     ?: number;
	trackedByTimeLimit      ?: number;
	logbookRemarksTemplate  ?: string;
	memberRoleIds           ?: number[]; // 'Administrator' | 'Instructor' | 'Maintenance' | 'Dispatch' | 'Other'
	dueToExpire             ?: boolean;
	expirationType          ?: string;   // On the date | Days | Calendar months | Calendar months - last day | Medical
	expirationTypeAmount    ?: number;
	clientTypeId            ?: number;
	roleId                  ?: number;
	status                  ?: string;
	selected                ?: boolean;
	attempts                ?: number;
	score                   ?: number;
	files                   ?: IRequirementFile[];
	history                 ?: ICredentialHistory[];
	rules                   ?: any;

	medicalClass            ?: number;
	monthsUntilFirst        ?: number;
	monthsUntilSecond       ?: number;
	monthsUntilThird        ?: number;
	remainingFirstClass     ?: Date;
	remainingSecondClass    ?: Date;
	remainingThirdClass     ?: Date;

	daysRemainingDate     ?: Date  ;
	daysRemaining         ?: number;
	timeRemaining         ?: number;
	approval              ?: number;

	approvedBy            ?: string;
	resolvedBy            ?: string;
	ftaDocumentType       ?: string;
	revision              ?: string;
	clientSubtypeIds      ?: number[];
	course                ?: ICourse;

	approvedDate  ?: Date;
	resolvedDate  ?: Date;
	revisionDate  ?: Date;
	expirationDate?: Date;
	issuedDate    ?: Date;

  equipmentId ?: number;
  equipmentName ?: string;
  syllabusCourseId	?: number;
}

export class Requirement implements IRequirement {
	static readonly clean = Object.freeze(new Requirement());
	createdAt            ?: Date;
	updatedAt            ?: Date;
	id                   = 0;
	userId               = 0;
	name                 = '';
	clientTypeIds        = [];
	description          = '';
	overridable          = false;
	isResolved           = false;
	isActive             = false;
	isEnabled            = false;
	/** It is true when it was first resolved before */
	firstResolved        = true;
	documentTemplate     = false;
	documentTemplatePath = '';
	document             = '';
	type                   : '' | 'client' | 'course' | 'maintenance' | 'member' | 'fta' = '';
	roleIdsThatCanOverride = [];
	pictureFileRequired    = false;
	requiresToFly          = false;
	isTestRelated          = '';
	maintenanceType        = '';
	typeOfLogbookEntry     = '';
	trackedByDate          = '';
	trackedByDateAmount    = 0;
	trackedByTime          = '';
	trackedByTimeAmount    = 0;
	trackedByTimeLimit     = 0;
	logbookRemarksTemplate = '';
	memberRoleIds          = [];
	dueToExpire            = false;
	expirationType         = '';
	expirationTypeAmount   = 0;
	clientTypeId           = 0;
	roleId                 = 0;
	status                 = '';
	selected               = false;
	attempts               = 0;
	score                  = 0;
	files                  : RequirementFile[] = [];
	history                : CredentialHistory[] = [];
	rules                  = [];

	medicalClass           = 0;
	monthsUntilFirst       = 0;
	monthsUntilSecond      = 0;
	monthsUntilThird       = 0;
	remainingFirstClass    ?: Date;
	remainingSecondClass   ?: Date;
	remainingThirdClass    ?: Date;

	daysRemainingDate     ?: Date;
	daysRemaining         = 0;
	timeRemaining         = 0;
	/**
	 * * approval: 0 -> Pending to be resolved, can be uploaded
	 * * approval: 1 -> Approved, can be overridden
	 * * approval: 2 -> REJECTED, can be Re-uploaded
	 * * approval: 3 -> EXPIRED, can be uploaded
	 */
	approval              = 0;

	approvedBy            = '';
	resolvedBy            = '';
	/** '' | 'course' | 'school' */
	ftaDocumentType       = '';
	revision              = '';
	clientSubtypeIds      = [];
	course                : Course;

	approvedDate  ?: Date;
	resolvedDate  ?: Date;
	revisionDate  ?: Date;
	expirationDate?: Date;
	issuedDate    ?: Date;

	equipmentId ?: number;
	equipmentName ?: string;
	syllabusCourseId = 0;

	parse(obj: IBackendRequirement) {
		// console.log(`obj IBackendRequirement --> `, obj);
		// this.createdAt = obj.created_at ? moment.utc(obj.created_at).toDate() : Requirement.clean.createdAt;
		// this.updatedAt = obj.updated_at ? moment.utc(obj.updated_at).toDate() : Requirement.clean.updatedAt;
		// this.daysRemainingDate = obj.days_remaining_date ? moment.utc(obj.days_remaining_date).toDate() : Requirement.clean.daysRemainingDate;

		/**
		 * These moment.utc functions are behaving strangely, were an hour off :c
		 * so we're using offset to convert to local times
		 */

		const offset = Number(new Date().getTimezoneOffset() / 60);
		this.createdAt         = obj.created_at          ? moment(obj.created_at          ).subtract(offset, 'hours').toDate() : Requirement.clean.createdAt         ;
		this.updatedAt         = obj.updated_at          ? moment(obj.updated_at          ).subtract(offset, 'hours').toDate() : Requirement.clean.updatedAt         ;
		this.daysRemainingDate = obj.days_remaining_date ? moment(obj.days_remaining_date.slice(0, 10) )             .toDate() : Requirement.clean.daysRemainingDate ;
		this.daysRemaining        = Number (obj.days_remaining         || Requirement.clean.daysRemaining       );

		this.id                   = Number (obj.id                     || Requirement.clean.id                  );
		this.userId               = Number (obj.user_id                || Requirement.clean.userId              );
		this.name                 = String (obj.name                   || Requirement.clean.name                );
		this.clientTypeIds        =        (obj.client_type_ids        || Requirement.clean.clientTypeIds       );
		this.description          = String (obj.description            || Requirement.clean.description         );
		this.overridable          = Boolean((obj.overridable !== undefined && obj.overridable !== null) ? obj.overridable : Requirement.clean.overridable );
		this.isResolved           = Boolean((obj.is_resolved !== undefined && obj.is_resolved !== null) ? obj.is_resolved : Requirement.clean.isResolved );
		this.isActive             = Boolean((obj.is_active !== undefined && obj.is_active !== null) ? obj.is_active : Requirement.clean.isActive );
		this.isEnabled            = Boolean((obj.is_enabled !== undefined && obj.is_enabled !== null) ? obj.is_enabled : Requirement.clean.isEnabled );
		this.firstResolved        = Boolean('first_resolved' in obj ? obj.first_resolved : Requirement.clean.firstResolved );
		this.documentTemplate     = Boolean((obj.document_template !== undefined && obj.document_template !== null) ? obj.document_template : Requirement.clean.documentTemplate );
		this.documentTemplatePath = String (obj.document_template_path || Requirement.clean.documentTemplatePath);
		this.document             = String (obj.document               || Requirement.clean.document            );
		this.type                    =        (obj.type                        || Requirement.clean.type                   );
		this.roleIdsThatCanOverride  =        (obj.role_ids_that_can_override  || Requirement.clean.roleIdsThatCanOverride );
		this.pictureFileRequired     = Boolean((obj.picture_file_required !== undefined && obj.picture_file_required !== null) ? obj.picture_file_required : Requirement.clean.pictureFileRequired );
		this.requiresToFly           = Boolean((obj.requires_to_fly !== undefined && obj.requires_to_fly !== null) ? obj.requires_to_fly : Requirement.clean.requiresToFly );
		this.isTestRelated           = String (obj.is_test_related             || Requirement.clean.isTestRelated          );
		this.maintenanceType         = String (obj.maintenance_type            || Requirement.clean.maintenanceType        );
		this.typeOfLogbookEntry      = String (obj.type_of_logbook_entry       || Requirement.clean.typeOfLogbookEntry     );
		this.trackedByDate           = String (obj.tracked_by_date             || Requirement.clean.trackedByDate          );
		this.trackedByDateAmount     = Number (obj.tracked_by_date_amount      || Requirement.clean.trackedByDateAmount    );
		this.trackedByTime           = String (obj.tracked_by_time             || Requirement.clean.trackedByTime          );
		this.trackedByTimeAmount     = Number (obj.tracked_by_time_amount      || Requirement.clean.trackedByTimeAmount    );
		this.trackedByTimeLimit      = Number(Number (obj.tracked_by_time_limit       || Requirement.clean.trackedByTimeLimit     ).toFixed(1));
		this.logbookRemarksTemplate  = String (obj.logbook_remarks_template    || Requirement.clean.logbookRemarksTemplate );
		this.memberRoleIds           =        (obj.member_role_ids             || Requirement.clean.memberRoleIds          );
		this.dueToExpire             = Boolean((obj.due_to_expire !== undefined && obj.due_to_expire !== null) ? obj.due_to_expire : Requirement.clean.dueToExpire );
		this.expirationType          = String (obj.expiration_type             || Requirement.clean.expirationType         );
		this.expirationTypeAmount    = Number (obj.expiration_type_amount      || Requirement.clean.expirationTypeAmount   );
		this.clientTypeId            = Number (obj.client_type_id              || Requirement.clean.clientTypeId           );
		this.roleId                  = Number (obj.role_id                     || Requirement.clean.roleId                 );
		this.equipmentId             = Number (obj.equipment_id                || Requirement.clean.equipmentId            );
		this.equipmentName          = String (obj.equipment_name             || Requirement.clean.equipmentName         );
		this.syllabusCourseId             = Number (obj.syllabus_course_id                || Requirement.clean.syllabusCourseId            );

		if ( obj.is_active && obj.is_enabled ) {
			this.status = obj.is_resolved ? 'Complete' : 'Incomplete';
		} else {
			this.status = obj.is_active ? 'Disabled' : 'Not active';
		}

		if (this.status === 'Incomplete' && (this.daysRemaining < 0 || ('time_remaining') in obj && obj.time_remaining <= 0 )) {
			this.status = 'Expired';
		}

		this.selected                = Boolean((obj.selected !== undefined && obj.selected !== null) ? obj.selected : Requirement.clean.selected );
		this.attempts                = Number (obj.attempts                     || Requirement.clean.attempts                 );
		this.score                   = Number (obj.score                        || Requirement.clean.score                    );
		this.files                   = (obj.files   || []).map((item) => new RequirementFile   ().parse(item));
		this.history                 = (obj.history || []).map((item) => new CredentialHistory ().parse(item));
		this.rules                   = (obj.rules   || Requirement.clean.rules);

		this.medicalClass            = Number (obj.medical_class                || Requirement.clean.medicalClass             );
		this.monthsUntilFirst        = Number (obj.months_until_first           || Requirement.clean.monthsUntilFirst         );
		this.monthsUntilSecond       = Number (obj.months_until_second          || Requirement.clean.monthsUntilSecond        );
		this.monthsUntilThird        = Number (obj.months_until_third           || Requirement.clean.monthsUntilThird         );
		this.remainingFirstClass     = obj.remaining_first_class  ? moment(obj.remaining_first_class  ).subtract(offset, 'hours').toDate() : Requirement.clean.remainingFirstClass  ;
		this.remainingSecondClass    = obj.remaining_second_class ? moment(obj.remaining_second_class ).subtract(offset, 'hours').toDate() : Requirement.clean.remainingSecondClass ;
		this.remainingThirdClass     = obj.remaining_third_class  ? moment(obj.remaining_third_class  ).subtract(offset, 'hours').toDate() : Requirement.clean.remainingThirdClass  ;

		this.timeRemaining    = Number(Number(obj.time_remaining    || Requirement.clean.timeRemaining   ).toFixed(1));
		this.approval         = Number(Number(obj.is_approved       || Requirement.clean.approval        ).toFixed(1));

		this.approvedBy       = String(obj.approved_by        || Requirement.clean.approvedBy       );
		this.resolvedBy       = String(obj.resolved_by        || Requirement.clean.resolvedBy       );
		this.ftaDocumentType  = String(obj.fta_document_type  || Requirement.clean.ftaDocumentType  );
		this.revision         = String(obj.revision           || Requirement.clean.revision         );
		this.clientSubtypeIds =       (obj.client_subtype_ids || Requirement.clean.clientSubtypeIds );
		this.course           = obj.course ? new Course().parse(obj.course ) : new Course();

		this.approvedDate            = obj.approved_date ? moment(obj.approved_date).toDate() : Requirement.clean.approvedDate;
		this.resolvedDate            = obj.resolved_date ? moment(obj.resolved_date).toDate() : Requirement.clean.resolvedDate;
		this.revisionDate            = obj.revision_date ? moment(obj.revision_date).toDate() : Requirement.clean.revisionDate;
		this.expirationDate          = obj.expiration_date ? moment(obj.expiration_date).toDate() : Requirement.clean.expirationDate;
		this.issuedDate              = obj.issued_date     ? moment(obj.issued_date    ).toDate() : Requirement.clean.issuedDate    ;

    this.equipmentId             = Number (obj.equipment_id           || Requirement.clean.equipmentId );
    this.equipmentName         = String(obj.equipment_name           || Requirement.clean.equipmentName );

		return this;
	}

	set(obj: IRequirement) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = Requirement.clean[key];
		});

		return this;
	}

	clone() {
		return new Requirement().set(this);
	}
}

export type CredentialVM = 
	Omit<Requirement, 'expirationDate' | 'issuedDate' | 'status' | 'isEnabled' | 'files' | 'parse' | 'set' | 'clear' | 'clone'> & 
	{
		expiration_date: Requirement['expirationDate'];
		issued_date?: Requirement['issuedDate'];
		status: Requirement['isResolved'];
		appliesToCurrentUser: boolean;
		is_enabled: Requirement['isEnabled'];
		requirement_id: Requirement['id'];
		clientName: string;
		client_id: number;
		fileList: RequirementFile[];
		medical: boolean;
		doc: RequirementFile[];
		hasDocumentTemplate: boolean;
		logs: any;
		needsExpirationDate?: boolean;
		needsIssuedDate?: boolean;
	};
