// import moment from 'moment';

export interface IBackendReservation {
	// created_at              ?: Date;
	// updated_at              ?: Date;
	id                      ?: number;
	syllabus_lesson_type_id ?: number;
	group_id                ?: number;
	equipment_type_id       ?: number;
	equipment_class_id      ?: number;
	client_type_id          ?: number;
	conditions              ?: number;
	block_time              ?: number;
	flight_time             ?: number;
	syllabus_lesson_id      ?: number;
	syllabus_lesson_title     ?: string;
	reservation_number     ?: string;
}

export interface IReservation {
	// createdAt        ?: Date;
	// updatedAt        ?: Date;
	id               ?: number;
	lessonTypeId     ?: number;
	groupId          ?: number;
	equipmentTypeId  ?: number;
	equipmentClassId ?: number;
	clientTypeId     ?: number;
	conditions       ?: number;
	blockTime        ?: number;
	flightTime       ?: number;
	syllabusLessonId ?: number;
	syllabusLessonTitle     ?: string;
	reservationNumber     ?: string;

}

export class Reservation implements IReservation {
	static readonly clean = Object.freeze(new Reservation());
	// createdAt?: Date;
	// updatedAt?: Date;
	id               = 0;
	lessonTypeId     = 0;
	groupId          = 0;
	equipmentTypeId  = 0;
	equipmentClassId = 0;
	clientTypeId     = 0;
	conditions       = 0;
	blockTime        = 0;
	flightTime       = 0;
	syllabusLessonId = 0;
	syllabusLessonTitle = '';
	reservationNumber   = '';

	parse(obj: IBackendReservation) {
		// this.createdAt        = obj.created_at ? moment.utc(obj.created_at).toDate() : Reservation.clean.createdAt;
		// this.updatedAt        = obj.updated_at ? moment.utc(obj.updated_at).toDate() : Reservation.clean.updatedAt;
		this.id               = Number(obj.id                      || Reservation.clean.id              );
		this.lessonTypeId     = Number(obj.syllabus_lesson_type_id || Reservation.clean.lessonTypeId    );
		this.groupId          = Number(obj.group_id                || Reservation.clean.groupId         );
		this.equipmentClassId = Number(obj.equipment_class_id      || Reservation.clean.equipmentClassId);
		this.equipmentTypeId  = Number(obj.equipment_type_id       || Reservation.clean.equipmentTypeId );
		this.clientTypeId     = Number(obj.client_type_id          || Reservation.clean.clientTypeId    );
		this.conditions       = Number(obj.conditions              || Reservation.clean.conditions      );
		this.blockTime        = Number(obj.block_time              || Reservation.clean.blockTime       );
		this.flightTime       = Number(obj.flight_time             || Reservation.clean.flightTime      );
		this.syllabusLessonId       = Number(obj.syllabus_lesson_id             || Reservation.clean.syllabusLessonId      );
		this.syllabusLessonTitle       = String(obj.syllabus_lesson_title             || Reservation.clean.syllabusLessonTitle      );
		this.reservationNumber       = String(obj.reservation_number             || Reservation.clean.reservationNumber      );
		return this;
	}

	set(obj: IReservation) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = Reservation.clean[key];
		});

		return this;
	}

	clone() {
		return new Reservation().set(this);
	}
}
