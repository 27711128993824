import moment from 'moment';
import { IBackendReservation, IReservation, Reservation } from './reservation.model';
import { IBackendSchedomaticReservation, ISchedomaticReservation, SchedomaticReservation } from './schedomatic-reservation.model';
import { IBackendTask, ITask, Task } from './task.model';

export interface IBackendLesson {
	created_at?:              Date;
	updated_at?:              Date;
	id?:                      number;
	instructor?:              string;
	syllabus_course_id?:      number;
	syllabus_stage_id?:       number;
	syllabus_phase_id?:       number;
	syllabus_lesson_type_id?: number;
	equipment_class_id?:      number;
	equipment_type_id?:       number;
	is_king?:                 number;
	abbreviation?:            string;
	title?:                   string;
	description?:             string;
	objective?:               string;
	where_to_go?:             string;
	how_to_go?:               string;
	planned_deviations?:      string;
	planned_malfunctions?:    string;
	purpose_pressures?:       string;
	risks?:                   string;
	group_id?:                number;
	block_time?:              number;
	flight_time?:             number;
	instrument?:              number;
	ground_time?:             number;
	day_night?:               number;
	flights_required?:        number;
	tasks_count?:             number;
	course_name?:             string;
	stage_name?:              string;
	phase_name?:              string;
	schedule_options_141:     number;
	schedule_options_61:      number;

	schedule_options?:        IBackendReservation[];
	reservations?:            IBackendSchedomaticReservation[];
	tasks?:                   IBackendTask[];

	status?: string;

	airman_check?: number;
}

export interface ILesson {
	createdAt?:          Date;
	updatedAt?:          Date;
	id?:                 number;
	instructor?:         string;
	courseId?:           number;
	stageId?:            number;
	phaseId?:            number;
	typeId?:             number;
	equipmentClassId?:   number;
	equipmentTypeId?:    number;
	isKing?:             number;
	abbreviation?:       string;
	title?:              string;
	description?:        string;
	objective?:          string;
	whereToGo?:          string;
	howToGo?:            string;
	plannedDeviations?:  string;
	plannedMalfunctions?:string;
	purposePressures?:   string;
	risks?:              string;
	groupId?:            number;
	blockTime?:          number;
	flightTime?:         number;
	instrument?:         number;
	groundTime?:         number;
	dayNight?:           number;
	flightsRequired?:    number;
	tasksCount?:         number;
	courseName?:         string;
	stageName?:          string;
	scheduleOptions141:  number;
	scheduleOptions61:   number;

	scheduleOptions?:       IReservation[];
	scheduleOptionsArr61?:  IReservation[];
	scheduleOptionsArr141?: IReservation[];
	reservations?:          ISchedomaticReservation[];
	tasks?:                 ITask[];

	phaseName?: string;

	status?: string;
	airmanCheck?: number;
}

/**
 * Lessons are named "Scenarios" in King courses
 */
export class Lesson implements ILesson {
	static readonly clean = Object.freeze(new Lesson());
	createdAt?: Date;
	updatedAt?: Date;
	id                   = 0;
	/** added to use lesson as an option in select component */
	value                = 0;
	instructor           = '';
	courseId             = 0;
	stageId              = 0;
	phaseId              = 0;
	typeId               = 0;
	equipmentClassId     = 0;
	equipmentTypeId      = 0;
	isKing               = 0;
	abbreviation         = '';
	title                = '';
	description          = '';
	objective            = '';
	whereToGo            = '';
	howToGo              = '';
	plannedDeviations    = '';
	plannedMalfunctions  = '';
	purposePressures     = '';
	risks                = '';
	groupId              = 0;
	blockTime            = 0;
	flightTime           = 0;
	instrument           = 0;
	groundTime           = 0;
	dayNight             = 0;
	flightsRequired      = 0;
	tasksCount           = 0;
	courseName           = '';
	stageName            = '';
	scheduleOptions141   = 0;
	scheduleOptions61    = 0;
	phaseName = '';

	/** Used Sched-o-matic & Course section "Schedule Planning" */
	scheduleOptions       : Reservation[]            = [];
	scheduleOptionsArr141 : Reservation[]            = [];
	scheduleOptionsArr61  : Reservation[]            = [];
	reservations          : SchedomaticReservation[] = [];
	tasks                 : Task[]                   = [];

	status = '';

	airmanCheck = 0;

	parse(obj: IBackendLesson) {
		// this.createdAt            = obj.created_at ? moment.utc(obj.created_at).toDate() : Lesson.clean.createdAt;
		// this.updatedAt            = obj.updated_at ? moment.utc(obj.updated_at).toDate() : Lesson.clean.updatedAt;
		
		/**
		 * These moment.utc functions are behaving strangely, were an hour off :c
		 * so we're using offset to convert to local times
		 */
		const offset = Number(new Date().getTimezoneOffset() / 60);
		this.createdAt = obj.created_at ? moment(obj.created_at ).subtract(offset, 'hours').toDate() : Lesson.clean.createdAt ;
		this.updatedAt = obj.updated_at ? moment(obj.updated_at ).subtract(offset, 'hours').toDate() : Lesson.clean.updatedAt ;


		this.id                   = Number(obj.id                           || Lesson.clean.id                  );
		this.value                = Number(obj.id                           || Lesson.clean.id                  );
		this.instructor           = String(obj.instructor                   || Lesson.clean.instructor          );
		this.courseId             = Number(obj.syllabus_course_id           || Lesson.clean.courseId            );
		this.stageId              = Number(obj.syllabus_stage_id            || Lesson.clean.stageId             );
		this.phaseId              = Number(obj.syllabus_phase_id            || Lesson.clean.phaseId             );
		this.typeId               = Number(obj.syllabus_lesson_type_id      || Lesson.clean.typeId              );
		this.equipmentClassId     = Number(obj.equipment_class_id           || Lesson.clean.equipmentClassId    );
		this.equipmentTypeId      = Number(obj.equipment_type_id            || Lesson.clean.equipmentTypeId     );
		this.isKing               = Number(obj.is_king === 1 ? obj.is_king : Lesson.clean.isKing                );
		this.abbreviation         = String(obj.abbreviation                 || Lesson.clean.abbreviation        );
		this.title                = String(obj.title                        || Lesson.clean.title               );
		this.description          = String(obj.description                  || Lesson.clean.description         ); // (?)
		this.objective            = String(obj.objective                    || Lesson.clean.objective           );
		this.whereToGo            = String(obj.where_to_go                  || Lesson.clean.whereToGo           );
		this.howToGo              = String(obj.how_to_go                    || Lesson.clean.howToGo             );
		this.plannedDeviations    = String(obj.planned_deviations           || Lesson.clean.plannedDeviations   );
		this.plannedMalfunctions  = String(obj.planned_malfunctions         || Lesson.clean.plannedMalfunctions );
		this.purposePressures     = String(obj.purpose_pressures            || Lesson.clean.purposePressures    );
		this.risks                = String(obj.risks                        || Lesson.clean.risks               );
		this.groupId              = Number(obj.group_id                     || Lesson.clean.groupId             );
		this.blockTime            = Number(obj.block_time                   || Lesson.clean.blockTime           );
		this.flightTime           = Number(obj.flight_time                  || Lesson.clean.flightTime          );
		this.instrument           = Number(obj.instrument                   || Lesson.clean.instrument          );
		this.groundTime           = Number(obj.ground_time                  || Lesson.clean.groundTime          );
		this.dayNight             = Number(obj.day_night                    || Lesson.clean.dayNight            );
		this.flightsRequired      = Number(obj.flights_required             || Lesson.clean.flightsRequired     );
		this.tasksCount           = Number(obj.tasks_count                  || Lesson.clean.tasksCount          );
		this.scheduleOptions141   = Number(obj.schedule_options_141         || Lesson.clean.scheduleOptions141  );
		this.scheduleOptions61    = Number(obj.schedule_options_61          || Lesson.clean.scheduleOptions61   );
		this.courseName           = String(obj.course_name                  || Lesson.clean.courseName          );
		this.stageName            = String(obj.stage_name || obj.phase_name || Lesson.clean.stageName           );
		this.phaseName 			  = String(obj.phase_name || Lesson.clean.phaseName           );
		this.scheduleOptions      = (obj.schedule_options || []).map((item) => new Reservation()           .parse(item));
		this.reservations         = (obj.reservations     || []).map((item) => new SchedomaticReservation().parse(item));
		this.tasks                = (obj.tasks            || []).map((item) => new Task().parse(item));
		this.status = String(obj.status || Lesson.clean.status           );
		this.airmanCheck    = Number(obj.airman_check          || Lesson.clean.airmanCheck   );
		return this;
	}

	set(obj: ILesson) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = Lesson.clean[key];
		});

		return this;
	}

	clone() {
		return new Lesson().set(this);
	}
}
