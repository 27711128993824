import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

import { User } from '../../models/user.model';
import { AuthenticationService, USER_STORAGE_KEYS } from '../auth/authentication.service';
import { HelpersService } from '../helpers.service';

@Injectable({
	providedIn: 'root',
})
export class LoginService {

	public user$ = new BehaviorSubject<User>(null);
	apiUrl = environment.apiUrl;


	constructor(
		public auth: AuthenticationService,
		private _http: HttpClient,
		private _router: Router,
		private _helpers: HelpersService,
	) { }


	login(username: string, password: string) {
		const params = { username, password };
		return this._http
			.post(this.apiUrl + 'users/in', params, { observe: 'response' })
			.pipe(map((data: any) => {

				if (data.body && data.body.data && data.body.data.id && data.body.data.is_active === 0) {
					const user = new User();
					user.id = -1;
					return {
						user: user,
						locations: data.body.locations
					}
				} else {
					this.user$.next(data);
					this.auth.token = data.body.data.token || '';
					const user = new User().parse(data.body.data || {});
					// console.log(`auth user = user; 3`);
					this.auth.user = user;
					return {
						user: user,
						locations: data.body.locations
					}
				}
			}, (err) => {
				console.error(err);
			}));
	}

	verifySession() {
		if (this.isLogged()) {

			const headers = new HttpHeaders()
				.set('Authorization', 'Bearer ' + localStorage.getItem(USER_STORAGE_KEYS.SESSION_TOKEN || ''))
				.set('refreshToken', localStorage.getItem(USER_STORAGE_KEYS.REFRESH_TOKEN || ''));

			this._http.get(this.apiUrl + `users/${(localStorage.getItem(USER_STORAGE_KEYS.USER_ID || ''))}/verify`, { headers })
				.subscribe((data: any) => {
					this.user$.next(data.data.user);
					if (localStorage.getItem('INPUT_LAST_URL')) {
						this._router.navigate([localStorage.getItem('INPUT_LAST_URL')]);
					} else {
						this._router.navigate(['/app/home/dashboard']);
					}
				}, (error: any) => {
					console.error('session error', error);
				});

		}
	}

	/** Log out. */
	public logOut() {
		return this._http.get(this.apiUrl + `users/out`)
			.pipe(map((data: any) => {
				this.localLogOut();
				return data;
			}));
	}

	/** Logout without sending a request. */
	public localLogOut() {
		this._router.navigate(['login']);
		this.user$.next(null);
		this.auth.clear();
		sessionStorage.clear();
	}

	isLogged() {
		return localStorage.getItem(USER_STORAGE_KEYS.USER_ID) !== null
			&& localStorage.getItem(USER_STORAGE_KEYS.SESSION_TOKEN) != null;
	}

	// ----------------------------------- RESET ----------------------------------

	checkPasswordRecoveryToken(token: string) {
		return this._http.post(environment.apiUrl + 'resetpassword/' + token, {}).pipe(map(() => {
			return true;
		}, (err: any) => {
			console.error(err);
			return false;
		}));
	}

	updatePasswordAndPin(
		token: string,
		password: string,
		pin: number
	) {
		return this._http.put(environment.apiUrl + 'resetpassword/' + token, { password, pin: this._helpers.updatePin(pin) })
			.pipe(map((data: any) => {
				// console.log(data); 
				// this.user$.next(data.body);
				// sessionStorage.setItem(this.keys.USERID, data.body._id);
				// sessionStorage.setItem(this.keys.SESSION_TOKEN, data.body.token);
				// sessionStorage.setItem(this.keys.REFRESH_TOKEN, data.body.refreshToken);
				return data;
			}, (err) => {
				console.error(err);
			}));
	}


}
