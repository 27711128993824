import moment from 'moment';

export interface IBackendStatement {
	[key: string]  : any;
	created_at    ?: Date;
	updated_at    ?: Date;
	id            ?: number;
}

export interface IStatement {
	[key: string]  : any;
	createdAt     ?: Date;
	updatedAt     ?: Date;
	id            ?: number;
}

export class Statement implements IStatement {
	static readonly clean = Object.freeze(new Statement());
	[key: string]  : any;
	createdAt     ?: Date;
	updatedAt     ?: Date;
	id            = 0;

	parse(obj: IBackendStatement) {
		this.createdAt = obj.created_at ? moment.utc(obj.created_at ).toDate() : Statement.clean.createdAt ;
		this.updatedAt = obj.updated_at ? moment.utc(obj.updated_at ).toDate() : Statement.clean.updatedAt ;
		this.id        = Number(obj.id || Statement.clean.id );

		return this;
	}

	set(obj: IStatement) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = Statement.clean[key];
		});

		return this;
	}

	clone() {
		return new Statement().set(this);
	}
}
