import moment from 'moment';
import { IAutocompleteOption } from '../shared/inputs/autocomplete-input/autocomplete-input.component';
import { Group, IBackendGroup, IGroups } from './group.model';
import { IBackendItem, IItem, Item } from './item.model';
import { IBackendRequirement, IRequirement, Requirement } from './requirement.model';

export interface IBackendEquipment {
	id         ?: number;
	created_at ?: Date;
	updated_at ?: Date;

	is_active ?: boolean;

	simulator_type_id      ?: number ;
	governing_approval_id  ?: number ;
	loa_expiration_date    ?: Date   ;
	loa_document_path      ?: string ;
	owned_by_external      ?: string ;
	owner_id               ?: number ;
	owner_name             ?: string ;
	engine_manufacturer    ?: string ;
	propeller_manufacturer ?: string ;

	equipment_name          ?: string;
	equipment_type_id       ?: number;
	equipment_class_id      ?: number;
	equipment_subclass_id   ?: number[];
	manufacturer            ?: string;
	model                   ?: string;
	tail_number             ?: string;
	serial_number           ?: string;
	hobbs_field_count       ?: number;
	hobbs1                  ?: number;
	hobbs2                  ?: number;
	tach_field_count        ?: number;
	tach1                   ?: number;
	tach2                   ?: number;

	maintenance_tracked_by  ?: string;
	empty_weight            ?: number;
	moment                  ?: string;
	ttaf                    ?: number;
	ttaf_start              ?: number;
	engine_smoh             ?: number;
	ett                     ?: number;
	engine_serial_number    ?: string;
	engine_model            ?: string;
	propeller_smoh          ?: number;
	ptt                     ?: number;
	propeller_serial_number ?: number;
	propeller_model         ?: string;
	hourly_rate             ?: number;
	fuel_burn_per_hour      ?: number;
	fuel_price              ?: number;

	currency         ?: number;
	number_days      ?: number;
	number_flights   ?: number;
	number_hobbs     ?: number;
	number_take_day  ?: number;
	number_take_night?: number;
	landings_nights  ?: number;
	number_land_day  ?: number;

	credentials?: IBackendRequirement[];
	groups?:       IBackendGroup[];

	items ?: IBackendItem[];
	location ?:number;
	previous_tach_meter ?: number;
}

export interface IEquipment {
	id?: number;
	createdAt?: Date;
	updatedAt?: Date;

	isActive ?: boolean;

	simulatorTypeId       ?: number ;
	governingApprovalId   ?: number ;
	loaExpirationDate     ?: Date   ;
	loaDocument           ?: string ;
	ownedByExternal       ?: string ;
	ownerId               ?: number ;
	ownerName             ?: string ;
	engineManufacturer    ?: string ;
	propellerManufacturer ?: string ;

	equipmentName         ?: string;
	equipmentTypeId       ?: number;
	equipmentClassId      ?: number;
	equipmentSubclassId   ?: number[];
	manufacturer          ?: string;
	model                 ?: string;
	tailNumber            ?: string;
	serialNumber          ?: string;
	hobbsFieldCount       ?: number;
	hobbs1                ?: number;
	hobbs2                ?: number;
	tachFieldCount        ?: number;
	tach1                 ?: number;
	tach2                 ?: number;

	maintenanceTrackedBy  ?: string;
	emptyWeight           ?: number;
	moment                ?: number;
	ttaf                  ?: number;
	ttafStart             ?: number;
	engineSmoh            ?: number;
	ett                   ?: number;
	engineSerialNumber    ?: string;
	engineModel           ?: string;
	propellerSmoh         ?: number;
	ptt                   ?: number;
	propellerSerialNumber ?: number;
	propellerModel        ?: string;
	hourlyRate            ?: number;
	fuelBurnPerHour       ?: number;
	fuelPrice             ?: number;

	currency     ?: number;
	numberDays   ?: number;
	numberFlights?: number;
	numberHobbs  ?: number;
	takeOffsDay  ?: number;
	takeOffsNight?: number;
	landingsDay  ?: number;
	landingsNight?: number;

	requirements?: IRequirement[];
	groups?:       IGroups[];

	items ?: IItem[];
	location ?:number;
	previousTachMeter ?:number;
}

export class Equipment implements IEquipment {
	static readonly clean = Object.freeze(new Equipment());
	id                  = 0;
	createdAt           ?: Date;
	updatedAt           ?: Date;

	isActive = true;

	simulatorTypeId     = 0;
	governingApprovalId = 0;
	loaExpirationDate   ?: Date;
	loaDocument         = '';
	/**
	 * * "No"
	 * * "Client"
	 * * "Company"
	 * * "Client under company"
	 */
	ownedByExternal     = '';
	/**
	 * If "Client" or "Client under company" is selected,
	 * an input should appear to choose the client (Ninja user)
	 * with which the equipment is going to be related
	 */
	ownerId               = 0;
	ownerName             = '';
	ownerInfo             : IAutocompleteOption;
	engineManufacturer    = '';
	propellerManufacturer = '';

	equipmentName       = '';
	equipmentTypeId     = 0;
	/**
	 * * 1: Single Engine, Land -(ASEL)
	 * * 2: Multi Engine, Land -(AMEL)
	 * * 3: Single Engine, Sea -(ASES)
	 * * 4: Multi Engine, Sea -(AMES)
	 */
	equipmentClassId    = 0;
	equipmentSubclassId = [];
	manufacturer        = '';
	model               = '';
	tailNumber          = '';
	serialNumber          = '';
	hobbsFieldCount     = 0;
	hobbs1              = 0;
	hobbs2              = 0;
	tachFieldCount      = 0;
	tach1               = 0;
	tach2               = 0;

	maintenanceTrackedBy  = '';
	emptyWeight           = 0;
	moment                = 0;
	ttaf                  = 0;
	ttafStart             = 0;
	engineSmoh            = 0;
	ett                   = 0;
	engineSerialNumber    = '';
	engineModel           = '';
	propellerSmoh         = 0;
	ptt                   = 0;
	propellerSerialNumber = 0;
	propellerModel        = '';
	hourlyRate            = 0;
	fuelBurnPerHour       = 0;
	/** Hardcoded, this must be defined on school, priority 3 */
	fuelPrice             = 0;

	currency                 = 0;
	numberDays               = 0;
	numberFlights            = 0;
	numberHobbs              = 0;
	takeOffsDay              = 0;
	takeOffsNight            = 0;
	landingsDay              = 0;
	landingsNight            = 0;

	requirements: Requirement[] = [];
	groups:       Group[]       = [];

	items : Item[] = [];
	location = 0;
	previousTachMeter = 0;

	parse(obj: IBackendEquipment) {
		this.id = Number(obj.id || Equipment.clean.id);

		// this.createdAt         = obj.created_at          ? moment.utc(obj.created_at          ).toDate() : Equipment.clean.createdAt         ;
		// this.updatedAt         = obj.updated_at          ? moment.utc(obj.updated_at          ).toDate() : Equipment.clean.updatedAt         ;
		// this.loaExpirationDate = obj.loa_expiration_date ? moment.utc(obj.loa_expiration_date ).toDate() : Equipment.clean.loaExpirationDate ;

		/**
		 * These moment.utc functions are behaving strangely, were an hour off :c
		 * so we're using offset to convert to local times
		 */
		const offset = Number(new Date().getTimezoneOffset() / 60);
		this.createdAt         = obj.created_at          ? moment(obj.created_at         ).subtract(offset, 'hours').toDate() : Equipment.clean.createdAt        ;
		this.updatedAt         = obj.updated_at          ? moment(obj.updated_at         ).subtract(offset, 'hours').toDate() : Equipment.clean.updatedAt        ;
		this.loaExpirationDate = obj.loa_expiration_date ? moment(obj.loa_expiration_date).subtract(offset, 'hours').toDate() : Equipment.clean.loaExpirationDate;

		this.isActive = Boolean('is_active' in obj ? obj.is_active : Equipment.clean.isActive );

		this.simulatorTypeId       = Number(obj.simulator_type_id      || Equipment.clean.simulatorTypeId       );
		this.governingApprovalId   = Number(obj.governing_approval_id  || Equipment.clean.governingApprovalId   );
		this.loaDocument           = String(obj.loa_document_path      || Equipment.clean.loaDocument           );
		this.ownedByExternal       = String(obj.owned_by_external      || Equipment.clean.ownedByExternal       );
		this.ownerId               = Number(obj.owner_id               || Equipment.clean.ownerId               );
		this.ownerName             = String(obj.owner_name             || Equipment.clean.ownerName             );
		this.engineManufacturer    = String(obj.engine_manufacturer    || Equipment.clean.engineManufacturer    );
		this.propellerManufacturer = String(obj.propeller_manufacturer || Equipment.clean.propellerManufacturer );
		this.equipmentName         = String(obj.model + ' ' + obj.tail_number);
		this.equipmentTypeId       = Number(obj.equipment_type_id      || Equipment.clean.equipmentTypeId       );
		this.equipmentClassId      = Number(obj.equipment_class_id     || Equipment.clean.equipmentClassId      );
		this.equipmentSubclassId   =       (obj.equipment_subclass_id  || Equipment.clean.equipmentSubclassId   );
		this.manufacturer          = String(obj.manufacturer           || Equipment.clean.manufacturer          );
		this.model                 = String(obj.model                  || Equipment.clean.model                 );
		this.tailNumber            = String(obj.tail_number            || Equipment.clean.tailNumber            );
		this.serialNumber          = String(obj.serial_number          || Equipment.clean.serialNumber          );
		this.hobbsFieldCount       = Number(obj.hobbs_field_count      || Equipment.clean.hobbsFieldCount       );
		this.hobbs1                = Number(obj.hobbs1                 || Equipment.clean.hobbs1                );
		this.hobbs2                = Number(obj.hobbs2                 || Equipment.clean.hobbs2                );
		this.tachFieldCount        = Number(obj.tach_field_count       || Equipment.clean.tachFieldCount        );
		this.tach1                 = Number(obj.tach1                  || Equipment.clean.tach1                 );
		this.tach2                 = Number(obj.tach2                  || Equipment.clean.tach2                 );

		this.maintenanceTrackedBy  = String(obj.maintenance_tracked_by  || Equipment.clean.maintenanceTrackedBy );
		this.emptyWeight           = Number(obj.empty_weight            || Equipment.clean.emptyWeight          );
		this.moment                = Number(obj.moment                  || Equipment.clean.moment               );
		this.ttaf                  = Number(obj.ttaf                    || Equipment.clean.ttaf                 );
		this.ttafStart             = Number(obj.ttaf_start              || Equipment.clean.ttafStart            );
		this.engineSmoh            = Number(obj.engine_smoh             || Equipment.clean.engineSmoh           );
		this.ett                   = Number(obj.ett                     || Equipment.clean.ett                  );
		this.engineSerialNumber    = String(obj.engine_serial_number    || Equipment.clean.engineSerialNumber   );
		this.engineModel           = String(obj.engine_model            || Equipment.clean.engineModel          );
		this.propellerSmoh         = Number(obj.propeller_smoh          || Equipment.clean.propellerSmoh        );
		this.ptt                   = Number(obj.ptt                     || Equipment.clean.ptt                  );
		this.propellerSerialNumber = Number(obj.propeller_serial_number || Equipment.clean.propellerSerialNumber);
		this.propellerModel        = String(obj.propeller_model         || Equipment.clean.propellerModel       );
		this.hourlyRate            = Number(obj.hourly_rate             || Equipment.clean.hourlyRate           );
		this.fuelBurnPerHour       = Number(obj.fuel_burn_per_hour      || Equipment.clean.fuelBurnPerHour      );
		// this.fuelPrice             = Number(obj.fuel_price              || Equipment.clean.fuelPrice            );
		this.fuelPrice             = 4.22;

		this.currency      = Number(obj.currency          || Equipment.clean.currency     );
		this.numberDays    = Number(obj.number_days       || Equipment.clean.numberDays   );
		this.numberFlights = Number(obj.number_flights    || Equipment.clean.numberFlights);
		this.numberHobbs   = Number(obj.number_hobbs      || Equipment.clean.numberHobbs  );
		this.takeOffsDay   = Number(obj.number_take_day   || Equipment.clean.takeOffsDay  );
		this.takeOffsNight = Number(obj.number_take_night || Equipment.clean.takeOffsNight);
		this.landingsDay   = Number(obj.number_land_day   || Equipment.clean.landingsDay  );
		this.landingsNight = Number(obj.landings_nights   || Equipment.clean.landingsNight);

		this.requirements  = (obj.credentials || []).map((task) => new Requirement().parse(task));
		this.groups        = (obj.groups      || []).map((task) => new Group().parse(task));
		this.items         = (obj.items       || []).map((item)=> new Item().parse(item));

		this.ownerInfo = {value: this.ownerId, text: this.ownerName};

		this.location = Number(obj.location   || Equipment.clean.location);
		this.previousTachMeter = Number(obj.previous_tach_meter   || Equipment.clean.previousTachMeter);
		
		return this;
	}

	set(obj: IEquipment) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = Equipment.clean[key];
		});

		return this;
	}

	clone() {
		return new Equipment().set(this);
	}
}
