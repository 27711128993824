export interface IBackendLogHold {
	times: string;
	location: string;
}

export interface ILogHold{
	times: string;
	location: string;
}

export class LogHold implements ILogHold {
	static readonly clean = Object.freeze(new LogHold());
	times = '';
	location = '';

	parse(obj: IBackendLogHold) {
		this.times = String(obj.times || LogHold.clean.times);
		this.location = String(obj.location || LogHold.clean.location);
		return this;
	}

	set(obj: ILogHold) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = LogHold.clean[key];
		});
		return this;
	}

	clone() {
		return new LogHold().set(this);
	}
}