{
	
	"instructorsAvailabilityScheduleView": true,
	"instructorsAvailabilityScheduleCreateEdit": false,
	
	"loaRequestView": false,
	"loaRequestAccceptOrDeny": false,
	"graduationRequestView": true,
	"graduationRequestAccceptOrDeny": false,
	"checkRideRequestView": true,
	"checkRideRequestAccceptOrDeny": false,

	"viewAllReservations": true,
	"viewSelfReservations": true,
	"viewReservationOfAssignedStudents": true,
	"schedOMatic": true,
	"availabilityFinder": true,

	"reservationsCreateOwn": true,
	"reservationsEditOwn": true,
	"reservationsCancelOwn": true,
	"reservationsCreateForOthers": true,
	"reservationsEditForOthers": true,
	"reservationsCancelForOthers": true,
	"reservationsCreateForAssignedStudents": true,
	"reservationsEditForAssignedStudents": true,
	"reservationsCancelForAssignedStudents": true,
	"reservationsCheckOutOther": true,
	"reservationsCheckInOther": true,
	"reservationsCheckOutOwn": true,
	"reservationsCheckInOwn": true,
	"reservationsCheckOutAssignedStudents": true,
	"reservationsCheckInAssignedStudents": true,

	"reservationsDispatchSheet": true,
	"reservationsGenerateInvoices": true,
	"reservationsViewInvoices": true,

	"usersCreateClient": true,
	"usersCreateTeamMember": false,
	"usersViewAllClientList": true,
	"usersViewAllTeamMemberList": true,
	"usersViewOnlyAsignedClientList": false,
	"usersViewOnlySelfClientOrTeamMember": false,

	"clientViewGeneralInfo": true,

	"clientDeleteDisable": true,
	"clientEditUpdate": true,
	"clientEditSelf": true,
	"clientEnroll": true,
	"clientViewRecord": true,
	"clientRequestGraduation": true,
	"clientRequestCheckRide": true,

	"clientCredentialsView": true,
	"clientCredentialsAdd": true,
	"clientCredentialsResolve": true,
	"clientCredentialsDisableEnable": true,

	"clientCurrencyCheckoutsEnableDisable": false,
	"clientCurrencyCheckoutsView": true,

	"clientFlightLogAdd": false,
	"clientFlightLogEdit": false,
	"clientFlightLogDelete": false,
	"clientFlightLogView": true,

	"clientLessonLogEdit": false,
	"clientLessonLogView": true,

	"teamMemberUsersGeneralView": true,
	"teamMemberUsersDeleteDisable": false,
	"teamMemberUsersEditUpdate": false,
	"teamMemberUsersEditSelf": true,

	"teamMemberUserCredentialsView": true,
	"teamMemberUserCredentialsAdd": false,
	"teamMemberUserCredentialsResolve": false,
	"teamMemberUserCredentialsDisableEnable": false,

	"teamMemberUserCurrencyCheckoutsEnableDisable": false,
	"teamMemberUserCurrencyCheckoutsView": true,

	"teamMemberFlightLogAddOwnEntry": false,
	"teamMemberFlightLogEditOwnEntry": false,
	"teamMemberFlightLogViewAllEntries": true,




	"coursesCreate": false,
	"coursesEdit": false,
	"coursesDisable": false,
	"coursesViewDetails": true,
	"coursesCheckRideFailReasonsDropdowns": true,

	"equipmentCreate": false,
	"equipmentEdit": false,
	"equipmentDisable": false,
	"equipmentViewDetails": true,
	"equipmentCreateSquawks": true,
	"equipmentResolveSquawks": false,
	"equipmentViewSquawks": true,
	"equipmentLeaseback": false,

	"equipmentCredentialsView": true,
	"equipmentCredentialsAdd": false,
	"equipmentCredentialsResolve": false,
	"equipmentCredentialsDisableEnable": false,




	"credentialsCreate": false,
	"credentialsEdit": false,
	"credentialsDisable": false,
	"credentialsDelete": false,
	"credentialsViewDetails": true,

	"groupsCreateEquipmentGroup": false,
	"groupsEditEquipmentGroup": false,
	"groupsDeleteEquipmentGroup": false,
	"groupsViewEquipmentGroup": true,
	"groupsCreateInstructorGroup": false,
	"groupsEditInstructorGroup": false,
	"groupsDeleteInstructorGroup": false,
	"groupsViewInstructorGroup": true,

	"ftaStudentsViewStudentList": true,

	"ftaComplianceFromFtaToTheUniversityUpload": true,
	"ftaComplianceFromFtaToTheUniversityResolve": true,
	"ftaComplianceFromFtaToTheUniversityDisable": false,
	"ftaComplianceFromFtaToTheUniversityViewDocuments": true,

	"universityComplianceFromTheUniversityToTheFtaAcknowledgementMarkAsUnread": true,
	"universityComplianceFromTheUniversityToTheFtaViewDocuments": true,

	"ftaAccountInfoViewAccountInfo": false,
	"ftaAccountInfoEditAccountInfo": false,

	"settingsEditGeneralSettings": false,
	"settingsEditPermissions": false,

	"billingAccountingSettings": false,

	"gradingGradeStudent": false
}