import moment from 'moment';

export interface IBackendSquawk {
	id            ?: number;
	created_at    ?: Date;
	updated_at    ?: Date;
	issued_date   ?: Date;
	resolved_date ?: Date;
	actions           ?: string;
	notes              ?: string;
	name              ?: string;
	description       ?: string;
	equipment_id      ?: number;
	event_id          ?: number;
	is_resolved       ?: number;
	issued_by         ?: number;
	required_to_fly   ?: number;
	resolved_by       ?: number;
	type_of_squawk    ?: string;
	type_of_issue     ?: string;
	sub_type_of_issue ?: string;
}

export interface ISquawks {
	id           ?: number;
	createdAt    ?: Date;
	updatedAt    ?: Date;
	issuedDate   ?: Date;
	resolvedDate ?: Date;
	actions        ?: string;
	notes           ?: string;
	name           ?: string;
	description    ?: string;
	equipmentId    ?: number;
	eventId        ?: number;
	isResolved     ?: number;
	issuedBy       ?: number;
	requiredToFly  ?: number;
	resolvedBy     ?: number;
	typeOfSquawk   ?: string;
	typeOfIssue    ?: string;
	subTypeOfIssue ?: string;
}

export class Squawk implements ISquawks {
	static readonly clean = Object.freeze(new Squawk());
	id = 0;
	createdAt    ?: Date;
	updatedAt    ?: Date;
	issuedDate   ?: Date;
	resolvedDate ?: Date;

	// type_of_squawk = 'Airworthiness' || 'Informational';
	// type_of_issue = 'Airframe' || 'Engine' || 'Avionics/Navigation' || 'Cosmetic';
	// subTypesOfIssue = 'PFD', || 'MFD', || 'STB Instruments', || 'Autopilot', || 'Lighting', || 'Database', || 'Other';

	actions        = '';
	notes           = '';
	name           = '';
	description    = '';
	equipmentId    = 0;
	eventId        = 0;
	isResolved     = 0;
	issuedBy       = 0;
	requiredToFly  = 0;
	resolvedBy     = 0;
	typeOfSquawk   = '';
	typeOfIssue    = '';
	subTypeOfIssue = '';

	parse(obj: IBackendSquawk) {
		this.id = Number(obj.id || Squawk.clean.id);

		// this.createdAt = obj.created_at ? moment.utc(obj.created_at).toDate() : Squawk.clean.createdAt;
		// this.updatedAt = obj.updated_at ? moment.utc(obj.updated_at).toDate() : Squawk.clean.updatedAt;

		/**
		 * These moment.utc functions are behaving strangely, were an hour off :c
		 * so we're using offset to convert to local times
		 */
		const offset = Number(new Date().getTimezoneOffset() / 60);
    this.createdAt = obj.created_at ? moment(obj.created_at).toDate() : Squawk.clean.createdAt ;
    this.updatedAt = obj.updated_at ? moment(obj.updated_at ).toDate() : Squawk.clean.updatedAt ;
		//this.createdAt = obj.created_at ? moment(obj.created_at ).subtract(offset, 'hours').toDate() : Squawk.clean.createdAt ;
		//this.updatedAt = obj.updated_at ? moment(obj.updated_at ).subtract(offset, 'hours').toDate() : Squawk.clean.updatedAt ;


		this.actions        = String(obj.actions           || Squawk.clean.actions       );
		this.notes           = String(obj.notes              || Squawk.clean.notes          );
		this.name           = String(obj.name              || Squawk.clean.name          );
		this.description    = String(obj.description       || Squawk.clean.description   );
		this.equipmentId    = Number(obj.equipment_id      || Squawk.clean.equipmentId   );
		this.eventId        = Number(obj.event_id          || Squawk.clean.eventId       );
		this.isResolved     = Number(obj.is_resolved       || Squawk.clean.isResolved    );
		this.issuedBy       = Number(obj.issued_by         || Squawk.clean.issuedBy      );
		this.requiredToFly  = Number(obj.required_to_fly   || Squawk.clean.requiredToFly );
		this.resolvedBy     = Number(obj.resolved_by       || Squawk.clean.resolvedBy    );
		this.typeOfSquawk   = String(obj.type_of_squawk    || Squawk.clean.typeOfSquawk  );
		this.typeOfIssue    = String(obj.type_of_issue     || Squawk.clean.typeOfIssue   );
		this.subTypeOfIssue = String(obj.sub_type_of_issue || Squawk.clean.subTypeOfIssue);

		return this;
	}

	set(obj: ISquawks) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = Squawk.clean[key];
		});

		return this;
	}

	clone() {
		return new Squawk().set(this);
	}
}
