import moment from 'moment';

export interface IBackendLeasebackTier {
	[key: string]   : any;
	created_at     ?: Date;
	updated_at     ?: Date;
	date           ?: Date;
	id             ?: number;
	leaseback_id   ?: number;
	value          ?: number;
	type           ?: string;
	end            ?: any;
	start          ?: any;
}

export interface ILeasebackTier {
	[key: string]  : any;
	createdAt     ?: Date;
	updatedAt     ?: Date;
	id            ?: number;
	leasebackId   ?: number;
	value         ?: number;
	type          ?: string;
	end           ?: any;
	start         ?: any;
}

export class LeasebackTier implements ILeasebackTier {
	static readonly clean = Object.freeze(new LeasebackTier());
	[key: string] : any;
	createdAt     ?: Date;
	updatedAt     ?: Date;
	id            = 0;
	leasebackId   = 0;
	value         = 0;
	type          = '';
	/** Can be number or Date depending on type */
	end           = undefined;
	/** Can be number or Date depending on type */
	start         = undefined;

	parse(obj: IBackendLeasebackTier) {
		const offset     = Number(new Date().getTimezoneOffset() / 60);
		this.createdAt   = obj.created_at ? moment(obj.created_at ).subtract(offset, 'hours').toDate() : LeasebackTier.clean.createdAt ;
		this.updatedAt   = obj.updated_at ? moment(obj.updated_at ).subtract(offset, 'hours').toDate() : LeasebackTier.clean.updatedAt ;
		this.id          = Number (obj.id    || LeasebackTier.clean.id          );
		this.leasebackId = Number (obj.id    || LeasebackTier.clean.leasebackId );
		this.value       = Number (obj.value || LeasebackTier.clean.value       );
		this.type        = String (obj.type  || LeasebackTier.clean.type        );

		/**
		 * Parsing `start` & `end` depending on tier's type
		 */
		if (this.type === 'Dates range') {
			this.end   = obj.end   ? moment(obj.end   ).subtract(offset, 'hours').toDate() : LeasebackTier.clean.end   ;
			this.start = obj.start ? moment(obj.start ).subtract(offset, 'hours').toDate() : LeasebackTier.clean.start ;
		} else {
			this.end   = Number (obj.end   || LeasebackTier.clean.end   );
			this.start = Number (obj.start || LeasebackTier.clean.start );
		}

		return this;
	}

	set(obj: ILeasebackTier) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = LeasebackTier.clean[key];
		});

		return this;
	}

	clone() {
		return new LeasebackTier().set(this);
	}

}
