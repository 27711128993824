// import moment from 'moment';
import { IBackendUser, IUser, User } from '../user.model';
import { Equipment, IBackendEquipment, IEquipment } from '../equipment.model';


export interface IBackendReservationHour {
	// created_at              ?: Date;
	// updated_at              ?: Date;

	disabled                 ?: number;
	from                     ?: string;
	start_hour               ?: string;
	to                       ?: string;
	end_hour                 ?: string;
	equipment_selected_index ?: number;
	instructor_id            ?: number;
	instructor               ?: IBackendUser;
	equipment_id             ?: number;
	equipment                ?: IBackendEquipment[];
	hoursId 				 ?: number[];
}

export interface IReservationHour {
	// createdAt        ?: Date;
	// updatedAt        ?: Date;

	disabled               ?: boolean;
	startHour              ?: string;
	endHour                ?: string;
	equipmentSelectedIndex ?: number;
	instructorId           ?: number;
	instructor             ?: IUser;
	equipmentId            ?: number;
	equipment              ?: IEquipment[];
	hoursId 			   ?: number[];
}

export class ReservationHour implements IReservationHour {
	static readonly clean = Object.freeze(new ReservationHour());
	// createdAt?: Date;
	// updatedAt?: Date;

	disabled                 = false;
	startHour                = '';
	endHour                  = '';
	equipmentSelectedIndex   = 0;
	instructorId             = 0;
	instructor               : User;
	equipmentId              = 0;
	equipment  : Equipment[] = [];
	hoursId 				 = [];

	parse(obj: IBackendReservationHour) {
		// this.createdAt        = obj.created_at ? moment.utc(obj.created_at).toDate() : ReservationHour.clean.createdAt;
		// this.updatedAt        = obj.updated_at ? moment.utc(obj.updated_at).toDate() : ReservationHour.clean.updatedAt;

		// console.log( `obj --> `, obj );

		this.disabled               = Boolean(obj.disabled                || ReservationHour.clean.disabled                          );
		this.startHour              = String(obj.from || obj.start_hour   || ReservationHour.clean.startHour                         );
		this.endHour                = String(obj.to   || obj.end_hour     || ReservationHour.clean.endHour                           );
		this.equipmentSelectedIndex = Number(obj.equipment_selected_index || ReservationHour.clean.equipmentSelectedIndex            );
		this.instructorId           = Number(obj.instructor_id            || obj.instructor?.id || ReservationHour.clean.instructorId);
		this.equipmentId            = Number(obj.equipment_id             || ReservationHour.clean.equipmentId                       );
		this.equipment              = (obj.equipment || []).map((element) => new Equipment().parse(element));
		this.hoursId                = (obj.hoursId || ReservationHour.clean.hoursId);
		if (obj.instructor) {
			this.instructor = new User().parse(obj.instructor);
		}

		return this;
	}

	set(obj: IReservationHour) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = ReservationHour.clean[key];
		});

		return this;
	}

	clone() {
		return new ReservationHour().set(this);
	}
}
