import moment from "moment";

export interface IBackendNote{
	created_at: Date;
	event_id  : number
	id        : number;
	note_by   : string;
	notes     : string;
	updated_at: Date;
	user_id   : number;
}

export interface INote {
	createdAt: Date;
	eventId  : number
	id       : number;
	noteBy   : string;
	notes    : string;
	updatedAt: Date;
	userId   : number;
}

export class Note implements INote {
	static readonly clean = Object.freeze(new Note());
	createdAt: Date;
	eventId  = 0;
	id       = 0;
	noteBy   = '';
	notes    = '';
	updatedAt: Date;
	userId   = 0;

	parse(obj: IBackendNote) {

		/**
		 * These moment.utc functions are behaving strangely, were an hour off :c
		 * so we're using offset to convert to local times
		 */
		const offset = Number(new Date().getTimezoneOffset() / 60);
		//this.createdAt	= 		 obj.created_at ? moment(obj.created_at).subtract(offset, 'hours').toDate() : Note.clean.createdAt;
		//this.updatedAt	= 		 obj.updated_at ? moment(obj.updated_at).subtract(offset, 'hours').toDate() : Note.clean.updatedAt;
    this.createdAt	= 		 obj.created_at ? moment(obj.created_at).toDate() : Note.clean.createdAt;
		this.updatedAt	= 		 obj.updated_at ? moment(obj.updated_at).toDate() : Note.clean.updatedAt;

		this.eventId  	= Number(obj.event_id     || Note.clean.eventId );
		this.id       	= Number(obj.id           || Note.clean.id      );
		this.noteBy   	= String(obj.note_by      || Note.clean.noteBy  );
		this.notes    	= String(obj.notes        || Note.clean.notes   );
		this.userId   	= Number(obj.user_id      || Note.clean.userId  );

		return this;
	}

	set(obj: INote) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = Note.clean[key];
		});

		return this;
	}

	clone() {
		return new Note().set(this);
	}
}
