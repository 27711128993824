// import moment from 'moment';

export interface IBackendGroupElement {
	id?:   number;
	type?: string;
}

export interface IGroupElement {
	id?:   number;
	type?: string;
}

export class GroupElement implements IGroupElement {
	static readonly clean = Object.freeze(new GroupElement());
	id   = 0;
	type = '';

	parse(obj: IBackendGroupElement) {
		this.id   = Number(obj.id   || GroupElement.clean.id  );
		this.type = String(obj.type || GroupElement.clean.type);

		return this;
	}

	set(obj: IGroupElement) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = GroupElement.clean[key];
		});

		return this;
	}

	clone() {
		return new GroupElement().set(this);
	}
}
