import moment from 'moment';
import { IBackendPivot, IPivot, Pivot } from './pivot.model';

export interface IBackendUserCourse {
	created_at        ?: Date;
	updated_at        ?: Date;
	start_date        ?: Date;
	end_date          ?: Date;
	check_ride_date   ?: Date;
	status_updated_at ?: Date;

	id                 ?: number;
	faa_course_type    ?: number;
	abbreviation       ?: string;
	description        ?: string;
	title              ?: string;
	current_stage      ?: string;
	status             ?: number;
	progress           ?: number;
	total_flight_time  ?: number;
	customer_course_id ?: number;
	king_id            ?: number;
	is_king            ?: number;
	status_updated_by  ?: string;
	checkride_status   ?: number;

	pivot             ?: IBackendPivot;
	doc_status ?: string;
	enrollment_doc ?: string;
	graduation_doc ?: string;
	//instructor_id ?: number;

	pool ?: string;
	wishlist ?: string;
}

export interface IUserCourse {
	createdAt       ?: Date;
	updatedAt       ?: Date;
	startDate       ?: Date;
	endDate         ?: Date;
	checkRideDate   ?: Date;
	statusUpdatedAt ?: Date;

	id                ?: number;
	faaUserCourseType ?: number;
	abbreviation      ?: string;
	description       ?: string;
	title             ?: string;
	currentStage      ?: string;
	status            ?: number;
	progress          ?: number;
	totalFlightTime   ?: number;
	customerCourseId  ?: number;
	kingId            ?: number;
	isKing            ?: boolean;
	statusUpdatedBy   ?: string;
	checkrideStatus   ?: number;

	pivot             ?: IPivot;
	docStatus ?: string;
	enrollmentDoc ?: string;
	graduationDoc ?: string;
	//instructorId ?: number;
	pool ?: string;
	wishlist ?: string;
}

export class UserCourse implements IUserCourse {
	static readonly clean = Object.freeze(new UserCourse());
	createdAt       ?: Date;
	updatedAt       ?: Date;
	startDate       ?: Date;
	endDate         ?: Date;
	checkRideDate   ?: Date;
	statusUpdatedAt ?: Date;
	docStatus ?: string;
	enrollmentDoc ?: string;
	graduationDoc ?: string;
	//instructorId ?: number;

	id                = 0;
	faaUserCourseType = 0;
	abbreviation      = '';
	description       = '';
	title             = '';
	currentStage      = '';
	status            = 0;
	progress          = 0;
	totalFlightTime   = 0;
	customerCourseId  = 0;
	kingId            = 0;
	isKing            = false;
	statusUpdatedBy   = '';
	checkrideStatus   = 0;

	pivot             : Pivot;
	pool = '';
	wishlist = '';


	parse(obj: IBackendUserCourse) {
		// this.createdAt     = obj.created_at ? moment.utc(obj.created_at     ).toDate() : UserCourse.clean.createdAt    ;
		// this.updatedAt     = obj.updated_at ? moment.utc(obj.updated_at     ).toDate() : UserCourse.clean.updatedAt    ;
		// this.startDate     = obj.updated_at ? moment.utc(obj.start_date     ).toDate() : UserCourse.clean.startDate    ;
		// this.endDate       = obj.updated_at ? moment.utc(obj.end_date       ).toDate() : UserCourse.clean.endDate      ;
		// this.checkRideDate = obj.updated_at ? moment.utc(obj.check_ride_date).toDate() : UserCourse.clean.checkRideDate;

		/**
		 * These moment.utc functions are behaving strangely, were an hour off :c
		 * so we're using offset to convert to local times
		 */
		const offset = Number(new Date().getTimezoneOffset() / 60);
		this.createdAt       = obj.created_at        ? moment(obj.created_at        ).subtract(offset, 'hours').toDate() : UserCourse.clean.createdAt       ;
		this.updatedAt       = obj.updated_at        ? moment(obj.updated_at        ).subtract(offset, 'hours').toDate() : UserCourse.clean.updatedAt       ;
		this.startDate       = obj.start_date        ? moment(obj.start_date        ).subtract(offset, 'hours').toDate() : UserCourse.clean.startDate       ;
		this.endDate         = obj.end_date          ? moment(obj.end_date          ).subtract(offset, 'hours').toDate() : UserCourse.clean.endDate         ;
		this.checkRideDate   = obj.check_ride_date   ? moment(obj.check_ride_date   ).subtract(offset, 'hours').toDate() : UserCourse.clean.checkRideDate   ;
		this.statusUpdatedAt = obj.status_updated_at ? moment(obj.status_updated_at ).subtract(offset, 'hours').toDate() : UserCourse.clean.statusUpdatedAt ;

		this.id                = Number(obj.id                 || UserCourse.clean.id                );
		this.faaUserCourseType = Number(obj.faa_course_type    || UserCourse.clean.faaUserCourseType );
		this.abbreviation      = String(obj.abbreviation       || UserCourse.clean.abbreviation      );
		this.description       = String(obj.description        || UserCourse.clean.description       );
		this.title             = String(obj.title              || UserCourse.clean.title             );
		this.currentStage      = String(obj.current_stage      || UserCourse.clean.currentStage      );
		this.status            = Number(obj.status             || UserCourse.clean.status            );
		if (obj.progress) {
			this.progress = Number(
				(obj.progress * 100).toFixed(1)
			);
		} else {
			this.progress = Number( UserCourse.clean.progress );
		}
		this.totalFlightTime   = Number  (obj.total_flight_time  || UserCourse.clean.totalFlightTime   );
		this.customerCourseId  = Number  (obj.customer_course_id || UserCourse.clean.customerCourseId  );
		this.kingId            = Number  (obj.king_id            || UserCourse.clean.kingId            );
		this.isKing            = Boolean ('is_king' in obj ? obj.is_king : UserCourse.clean.isKing     );
		this.statusUpdatedBy   = String  (obj.status_updated_by  || UserCourse.clean.statusUpdatedBy   );
		this.checkrideStatus   = Number  (obj.checkride_status   || UserCourse.clean.checkrideStatus   );

		if (obj.pivot) {this.pivot = new Pivot().parse(obj.pivot);}
		this.docStatus = obj.doc_status ? String (obj.doc_status): UserCourse.clean.docStatus ;
		this.enrollmentDoc = obj.enrollment_doc ? String (obj.enrollment_doc): UserCourse.clean.enrollmentDoc ;
		this.graduationDoc = obj.graduation_doc ? String (obj.graduation_doc): UserCourse.clean.graduationDoc ;

		this.pool   = String  (obj.pool  || UserCourse.clean.pool   );
		this.wishlist   = String  (obj.wishlist  || UserCourse.clean.wishlist   );
		return this;
	}

	set(obj: IUserCourse) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = UserCourse.clean[key];
		});

		return this;
	}

	clone() {
		return new UserCourse().set(this);
	}
}
