{

	"instructorsAvailabilityScheduleView": true,
	"instructorsAvailabilityScheduleCreateEdit": true,
	
	"loaRequestView": true,
	"loaRequestAccceptOrDeny": true,
	"graduationRequestView": true,
	"graduationRequestAccceptOrDeny": true,
	"checkRideRequestView": true,
	"checkRideRequestAccceptOrDeny": true,

	"viewAllReservations": true,
	"viewSelfReservations": true,
	"viewReservationOfAssignedStudents": true,
	"schedOMatic": true,
	"availabilityFinder": true,

	"reservationsCreateOwn": true,
	"reservationsEditOwn": true,
	"reservationsCancelOwn": true,
	"reservationsCreateForOthers": true,
	"reservationsEditForOthers": true,
	"reservationsCancelForOthers": true,
	"reservationsCreateForAssignedStudents": true,
	"reservationsEditForAssignedStudents": true,
	"reservationsCancelForAssignedStudents": true,
	"reservationsCheckOutOther": true,
	"reservationsCheckInOther": true,
	"reservationsCheckOutOwn": true,
	"reservationsCheckInOwn": true,
	"reservationsCheckOutAssignedStudents": true,
	"reservationsCheckInAssignedStudents": true,

	"reservationsDispatchSheet": true,
	"reservationsGenerateInvoices": true,
	"reservationsViewInvoices": true,

	"usersCreateClient": true,
	"usersCreateTeamMember": true,
	"usersViewAllClientList": true,
	"usersViewAllTeamMemberList": true,
	"usersViewOnlyAsignedClientList": false,
	"usersViewOnlySelfClientOrTeamMember": false,

	"clientViewGeneralInfo": true,

	"clientDeleteDisable": true,
	"clientEditUpdate": true,
	"clientEditSelf": true,
	"clientEnroll": true,
	"clientViewRecord": true,
	"clientRequestGraduation": true,
	"clientRequestCheckRide": true,

	"clientCredentialsView": true,
	"clientCredentialsAdd": true,
	"clientCredentialsResolve": true,
	"clientCredentialsDisableEnable": true,

	"clientCurrencyCheckoutsEnableDisable": true,
	"clientCurrencyCheckoutsView": true,

	"clientFlightLogAdd": true,
	"clientFlightLogEdit": true,
	"clientFlightLogDelete": true,
	"clientFlightLogView": true,

	"clientLessonLogEdit": true,
	"clientLessonLogView": true,

	"teamMemberUsersGeneralView": true,
	"teamMemberUsersDeleteDisable": true,
	"teamMemberUsersEditUpdate": true,
	"teamMemberUsersEditSelf": true,

	"teamMemberUserCredentialsView": true,
	"teamMemberUserCredentialsAdd": true,
	"teamMemberUserCredentialsResolve": true,
	"teamMemberUserCredentialsDisableEnable": true,

	"teamMemberUserCurrencyCheckoutsEnableDisable": true,
	"teamMemberUserCurrencyCheckoutsView": true,

	"teamMemberFlightLogAddOwnEntry": true,
	"teamMemberFlightLogEditOwnEntry": true,
	"teamMemberFlightLogViewAllEntries": true,




	"coursesCreate": true,
	"coursesEdit": true,
	"coursesDisable": true,
	"coursesViewDetails": true,
	"coursesCheckRideFailReasonsDropdowns": true,

	"equipmentCreate": true,
	"equipmentEdit": true,
	"equipmentDisable": true,
	"equipmentViewDetails": true,
	"equipmentCreateSquawks": true,
	"equipmentResolveSquawks": true,
	"equipmentViewSquawks": true,
	"equipmentLeaseback": false,

	"equipmentCredentialsView": true,
	"equipmentCredentialsAdd": true,
	"equipmentCredentialsResolve": true,
	"equipmentCredentialsDisableEnable": true,




	"credentialsCreate": true,
	"credentialsEdit": true,
	"credentialsDisable": true,
	"credentialsDelete": true,
	"credentialsViewDetails": true,

	"groupsCreateEquipmentGroup": true,
	"groupsEditEquipmentGroup": true,
	"groupsDeleteEquipmentGroup": true,
	"groupsViewEquipmentGroup": true,
	"groupsCreateInstructorGroup": true,
	"groupsEditInstructorGroup": true,
	"groupsDeleteInstructorGroup": true,
	"groupsViewInstructorGroup": true,

	"ftaStudentsViewStudentList": true,

	"ftaComplianceFromFtaToTheUniversityUpload": true,
	"ftaComplianceFromFtaToTheUniversityResolve": true,
	"ftaComplianceFromFtaToTheUniversityDisable": true,
	"ftaComplianceFromFtaToTheUniversityViewDocuments": true,

	"universityComplianceFromTheUniversityToTheFtaAcknowledgementMarkAsUnread": true,
	"universityComplianceFromTheUniversityToTheFtaViewDocuments": true,

	"ftaAccountInfoViewAccountInfo": true,
	"ftaAccountInfoEditAccountInfo": true,

	"settingsEditGeneralSettings": false,
	"settingsEditPermissions": false,

	"billingAccountingSettings": false,

	"gradingGradeStudent": true
}