import { Checkout, IBackendCheckout, ICheckout } from './checkouts.model';

export interface IBackendUserCheckouts {
	courses    ?: IBackendCheckout[];
	equipments ?: IBackendCheckout[];
	generic    ?: IBackendCheckout[];
}

export interface IUserCheckouts {
	courses    ?: ICheckout[];
	equipments ?: ICheckout[];
	generic    ?: ICheckout[];
}

export class UserCheckouts implements IUserCheckouts {
	static readonly clean = Object.freeze(new UserCheckouts());
	courses   : Checkout[] = [];
	equipments: Checkout[] = [];
	generic   : Checkout[] = [];

	parse(obj: IBackendUserCheckouts) {
		this.courses    = (obj.courses    || []).map((item) => new Checkout().parse(item));
		this.equipments = (obj.equipments || []).map((item) => new Checkout().parse(item));
		this.generic    = (obj.generic    || []).map((item) => new Checkout().parse(item));


		return this;
	}

	set(obj: IUserCheckouts) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = UserCheckouts.clean[key];
		});

		return this;
	}

	clone() {
		return new UserCheckouts().set(this);
	}
}
