import moment from 'moment';

export interface IBackendTask {
	created_at?: Date;
	updated_at?: Date;
	last_record?: Date;
	id?: number;
	task_id?: number;
	king_id?: number;
	approach_group?: string;
	desired_outcome?: number;
	grade?: number;
	grading_scale_id?: number;
	name?: string;
	type?: string;
	description?: string;
	approach?: number;
	hold?: number;
	ir?: number;
	landing?: number;
	status?: string;
	is_passed: boolean;
	lesson_title?: string;
	flight_or_ground?: number;
}

export interface ITask {
	createdAt?: Date;
	updatedAt?: Date;
	lastRecord?: Date;
	id?: number;
	task_id?: number;
	king_id?: number;
	approachGroup?: string;
	desiredOutcome?: number;
	grade?: number;
	gradingScaleId?: number;
	name?: string;
	type?: string;
	description?: string;
	approach?: number;
	hold?: number;
	ir?: number;
	landing?: number;
	status?: string;
	isPassed: boolean;
	lessonTitle?: string;
	flightOrGround?: number;
}

export class Task implements ITask {
	static readonly clean = Object.freeze(new Task());
	createdAt?: Date;
	updatedAt?: Date;
	lastRecord?: Date;
	id               =  0;
	task_id          =  0;
	king_id          =  0;
	approachGroup    =  '';
	desiredOutcome   =  0;
	grade            =  0;
	gradingScaleId   =  0;
	name             =  '';
	type             =  '';
	description      =  '';
	approach         =  0;
	hold             =  0;
	ir               =  0;
	landing          =  0;
	status           =  '';
	isPassed         = false;
	lessonTitle      = '';
	flightOrGround 	 = 1;

	parse(obj: IBackendTask) {
		// this.createdAt = obj.created_at ? moment.utc(obj.created_at).toDate() : Task.clean.createdAt;
		// this.updatedAt = obj.updated_at ? moment.utc(obj.updated_at).toDate() : Task.clean.updatedAt;

		/**
		 * These moment.utc functions are behaving strangely, were an hour off :c
		 * so we're using offset to convert to local times
		 */
		const offset = Number(new Date().getTimezoneOffset() / 60);
		this.createdAt = obj.created_at ? moment(obj.created_at ).subtract(offset, 'hours').toDate() : Task.clean.createdAt ;
		this.updatedAt = obj.updated_at ? moment(obj.updated_at ).subtract(offset, 'hours').toDate() : Task.clean.updatedAt ;


		this.lastRecord = obj.last_record ? moment(obj.last_record).toDate() : Task.clean.lastRecord;
		this.id               = Number(obj.id               || Task.clean.id             );
		this.task_id          = Number(obj.task_id               || Task.clean.task_id             );
		this.king_id          = Number(obj.king_id               || Task.clean.king_id             );
		this.approachGroup    = String(obj.approach_group   || Task.clean.approachGroup  );
		this.desiredOutcome   = Number(obj.desired_outcome  || Task.clean.desiredOutcome );
		this.grade  		  = Number(obj.grade  || Task.clean.grade );
		this.gradingScaleId   = Number(obj.grading_scale_id || Task.clean.gradingScaleId );
		this.name             = String(obj.name             || Task.clean.name           );
		this.type             = String(obj.type             || Task.clean.type           );
		this.description      = String(obj.description      || Task.clean.description    );
		this.approach         = Number(obj.approach         || Task.clean.approach       );
		this.hold             = Number(obj.hold             || Task.clean.hold           );
		this.ir               = Number(obj.ir               || Task.clean.ir             );
		this.landing          = Number(obj.landing          || Task.clean.landing        );
		this.status           = String((obj.status ? obj.status === 'not_attempt' ? 'not attempt' : obj.status : Task.clean.status));
		this.isPassed         = Boolean(obj.is_passed          || Task.clean.isPassed        );
		this.lessonTitle      = String(obj.lesson_title      || Task.clean.lessonTitle    );
		this.flightOrGround   = Number(obj.flight_or_ground || Task.clean.flightOrGround);
		return this;
	}

	set(obj: ITask) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = Task.clean[key];
		});

		return this;
	}

	clone() {
		return new Task().set(this);
	}
}
