import moment from 'moment';

export interface IBackendAssets {

	id             ?: number;
	created_at  ?: Date;
	updated_at  ?: Date;
  note        ?: string;

	is_active   ?: boolean;

  resource_type  ?: number;
  manufacturer   ?: string;
  model          ?: string;
  serial_number  ?: string;
  asset_number   ?: string;
  location       ?: number;
  assigned_to    ?: number;
  description    ?: string;

	//credentials?: IBackendRequirement[];
	//groups?:       IBackendGroup[];

	//items ?: IBackendItem[];
}

export interface IAssets {

	id          ?: number;
	createdAt  ?: Date;
	updatedAt  ?: Date;
  note       ?: string;

	isActive    ?: boolean;

  resourceType ?: number;
  manufacturer ?: string;
  model        ?: string;
  serialNumber ?: string;
  assetNumber  ?: string;
  location     ?: number;
  assignedTo   ?: number;
  description  ?: string;

	//credentials?: IBackendRequirement[];
	//groups?:       IBackendGroup[];

	//items ?: IBackendItem[];
}

export class Assets implements IAssets {
  static readonly clean = Object.freeze(new Assets());

  id = 0;
	createdAt ?: Date;
	updatedAt ?: Date;
  note = '';

	isActive = true;

  resourceType = 0;
  manufacturer = '';
  model= '';
  serialNumber = '';
  assetNumber = '';
  location = 0;
  assignedTo = 0;
  description = '';

  parse (obj: IBackendAssets) {
  	this.id = Number(obj.id || Assets.clean.id);

  	/**
		 * These moment.utc functions are behaving strangely, were an hour off :c
		 * so we're using offset to convert to local times
		 */
  	const offset = Number(new Date().getTimezoneOffset() / 60);
  	this.createdAt = obj.created_at ? moment(obj.created_at).subtract(offset, 'hours').toDate() : Assets.clean.createdAt;
  	this.updatedAt = obj.updated_at ? moment(obj.updated_at).subtract(offset, 'hours').toDate() : Assets.clean.updatedAt;
  	this.note      = String(obj.note || Assets.clean.note);
  	this.isActive = Boolean('is_active' in obj ? obj.is_active : Assets.clean.isActive );

  	this.resourceType = Number(obj.resource_type || Assets.clean.resourceType);
  	this.manufacturer = String(obj.manufacturer || Assets.clean.manufacturer);
  	this.model = String(obj.model || Assets.clean.model);
  	this.serialNumber = String(obj.serial_number || Assets.clean.serialNumber);
  	this.assetNumber = String(obj.asset_number || Assets.clean.assetNumber);
  	this.location = Number(obj.location || Assets.clean.location);
  	this.assignedTo = Number(obj.assigned_to || Assets.clean.assignedTo);
  	this.description = String(obj.description || Assets.clean.description);

  	return this;
  }

  set(obj: IAssets) {
  	Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

  	return this;
  }

  clear() {
  	Object.entries(this).forEach(([key]) => {
  		this[key] = Assets.clean[key];
  	});

  	return this;
  }

  clone() {
  	return new Assets().set(this);
  }
}



