import { IBackendRequirement, IRequirement, Requirement } from './requirement.model';

export interface IBackendEventCredentials {
	client      ?: IBackendRequirement[];
	course      ?: IBackendRequirement[];
	maintenance ?: IBackendRequirement[];
	member      ?: IBackendRequirement[];
	instructor  ?: IBackendRequirement[];
	equipment  ?: IBackendRequirement[];
}

export interface IEventCredentials {
	client      ?: IRequirement[];
	course      ?: IRequirement[];
	maintenance ?: IRequirement[];
	member      ?: IRequirement[];
}

export class EventCredentials implements IEventCredentials {
	static readonly clean = Object.freeze(new EventCredentials());
	client      ?: Requirement[] = [];
	course      ?: Requirement[] = [];
	maintenance ?: Requirement[] = [];
	member      ?: Requirement[] = [];

	parse(obj: IBackendEventCredentials) {
		this.client      = (obj.client      || []).map((item) => new Requirement().parse(item));
		this.course      = (obj.course      || []).map((item) => new Requirement().parse(item));
		this.maintenance = (obj.maintenance || obj.equipment  || []).map((item) => new Requirement().parse(item));
		this.member      = (obj.member      || obj.instructor || []).map((item) => new Requirement().parse(item));

		return this;
	}

	set(obj: IEventCredentials) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = EventCredentials.clean[key];
		});

		return this;
	}

	clone() {
		return new EventCredentials().set(this);
	}
}
