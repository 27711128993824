export interface IBackendLogApproach {
	times: string;
	location: string;
	approach_type_id: string;
	runway_number: string;
}

export interface ILogApproach{
	times: string;
	location: string;
	approach_type_id: string | number;
	runway_number?: string;
}

export class LogApproach implements ILogApproach {
	static readonly clean = Object.freeze(new LogApproach());
	times = '';
	location = '';
	approach_type_id = '';
	runway_number = '';

	parse(obj: IBackendLogApproach) {
		this.times = String(obj.times || LogApproach.clean.times);
		this.location = String(obj.location || LogApproach.clean.location);
		this.approach_type_id = String(obj.approach_type_id || LogApproach.clean.approach_type_id);
		this.runway_number = String(obj.runway_number || LogApproach.clean.runway_number);
		return this;
	}

	set(obj: ILogApproach) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = LogApproach.clean[key];
		});
		return this;
	}

	clone() {
		return new LogApproach().set(this);
	}
}