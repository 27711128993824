export interface IBackendLogTask {
	id: number;
	task_id?: number;
	king_id?: number;
	name: string;
	grade?: number;
	description?: string;
	desired_outcome: number;
	grading_scale_id: number;
	hold: number;
	ir: number;
	approach: number;
	landing: number;
	approach_group: string;
	is_passed: boolean;
	lesson_title?: string;
}

export interface ILogTask{
	id: number;
	task_id?: number;
	king_id?: number;
	name: string;
	grade?: number;
	description?: string;
	desired_outcome: number;
	grading_scale_id: number;
	hold: number;
	ir: number;
	approach: number;
	landing: number;
	approach_group: string;
	is_passed: boolean;
	lessonTitle?: string;
}

export class LogTask implements ILogTask {
	static readonly clean = Object.freeze(new LogTask());
	id = 0;
	task_id = 0;
	king_id = 0;
	name = '';
	grade = 0;
	description = '';
	desired_outcome = 0;
	grading_scale_id = 0;
	hold = 0;
	ir = 0;
	approach = 0;
	landing = 0;
	approach_group = '';
	is_passed = false;
	lessonTitle = '';

	parse(obj: IBackendLogTask) { 
		this.id = Number(obj.id || LogTask.clean.id);
		this.task_id = Number(obj.task_id || LogTask.clean.task_id);
		this.king_id = Number(obj.king_id || LogTask.clean.king_id);
		this.name = String(obj.name || LogTask.clean.name);
		this.grade = Number(obj.grade || LogTask.clean.grade);
		this.ir = Number(obj.ir || LogTask.clean.ir);
		this.hold = Number(obj.hold || LogTask.clean.hold);
		this.approach = Number(obj.approach || LogTask.clean.approach);
		this.landing = Number(obj.landing || LogTask.clean.landing);
		this.description = String(obj.description || LogTask.clean.description);
		this.desired_outcome = Number(obj.desired_outcome || LogTask.clean.desired_outcome);
		this.grading_scale_id = Number(obj.grading_scale_id || LogTask.clean.grading_scale_id);
		this.approach_group = String(obj.approach_group || LogTask.clean.approach_group);
		this.is_passed = Boolean(obj.is_passed || LogTask.clean.is_passed);
		this.lessonTitle = String(obj.lesson_title || LogTask.clean.lessonTitle);

		return this;
	}

	set(obj: ILogTask) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = LogTask.clean[key];
		});
		return this;
	}

	clone() {
		return new LogTask().set(this);
	}
}