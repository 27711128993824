import { IBackendRate, IRate, Rate } from "./rate-model";

export interface IBackendLessonType {
	id                      : number;
	tax_id                  : number;
	name                    : string;
	qb_item_id              : number;
	rate_set_id             : number;
	syllabus_lesson_type_id : number;
	rates                   : IBackendRate[];
}

export interface ILessonType  {
	id                   : number;
	taxId                : number;
	name                 : string;
	qbItemId             : number;
	rateSetId            : number;
	syllabusLessonTypeId : number;
	rates                : IRate[];
}

export class LessonType  implements ILessonType  {
	static readonly clean = Object.freeze(new LessonType ());
	id                   = 0;
	taxId                = 0;
	name                 = '';
	qbItemId             = 0;
	rateSetId            = 0;
	syllabusLessonTypeId = 0;
	rates : Rate[]       = [];	

	parse(obj: IBackendLessonType) {
		this.id                   = Number(obj.id                       || LessonType .clean.id                   );
		this.taxId                = Number(obj.tax_id                   || LessonType .clean.taxId                );
		this.name                 = String(obj.name                     || LessonType .clean.name                 );
		this.qbItemId             = Number(obj.qb_item_id               || LessonType .clean.qbItemId             );
		this.rateSetId            = Number(obj.rate_set_id              || LessonType .clean.rateSetId            );
		this.syllabusLessonTypeId = Number(obj.syllabus_lesson_type_id  || LessonType .clean.syllabusLessonTypeId );
		this.rates                =       (obj.rates  || []).map((rate)=> new Rate().parse(rate));
		return this;
	}

	set(obj: ILessonType ) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = LessonType.clean[key];
		});

		return this;
	}

	clone() {
		return new LessonType().set(this);
	}
}