import moment from "moment";
import { environment } from "src/environments/environment";

const IMAGE_FORMATS = ["jpg", "png", "gif", "jpeg"];

export interface IBackendRequirementFile {
  created_at?: Date;
  updated_at?: Date;

  id?: number;
  client_requirement_id?: number;
  equipment_requirement_id?: number;
  file_path?: string;
  batch?: string | null;
}

export interface IRequirementFile {
  createdAt?: Date;
  updatedAt?: Date;

  id?: number;
  clientRequirementId?: number;
  equipmentRequirementId?: number;
  /** Batch ID for multiple files uploaded simultaneously. */
  batch: string | null;
	/** Is file image? */
	isImage: boolean;
	/** Full URL of file. */
  fileUrl?: string;
  filePath?: string;
  fileName?: string;
}

export class RequirementFile implements IRequirementFile {
  static readonly clean = Object.freeze(new RequirementFile());
  createdAt?: Date;
  updatedAt?: Date;

  id = 0;
  clientRequirementId = 0;
  equipmentRequirementId = 0;
	isImage = false;
	fileUrl = "";
  filePath = "";
  fileName = "";
  batch: string | null = null;

  parse(obj: IBackendRequirementFile) {
    /**
     * These moment.utc functions are behaving strangely, were an hour off :c
     * so we're using offset to convert to local times
     */
    const offset = Number(new Date().getTimezoneOffset() / 60);
    this.createdAt = obj.created_at
      ? moment(obj.created_at).subtract(offset, "hours").toDate()
      : RequirementFile.clean.createdAt;
    this.updatedAt = obj.updated_at
      ? moment(obj.updated_at).subtract(offset, "hours").toDate()
      : RequirementFile.clean.updatedAt;

    this.id = Number(obj.id || RequirementFile.clean.id);
    this.clientRequirementId = Number(
      obj.client_requirement_id || RequirementFile.clean.clientRequirementId
    );
    this.equipmentRequirementId = Number(
      obj.equipment_requirement_id ||
        RequirementFile.clean.equipmentRequirementId
    );
    this.batch = obj.batch;
    this.filePath = String(obj.file_path || RequirementFile.clean.filePath);
    if (obj.file_path) {
      this.fileName = this.filePath.split('/').pop();

			const fileExtension = this.fileName.split('.').pop();
			this.isImage = IMAGE_FORMATS.includes(fileExtension);

			const fileBaseUrl = this.isImage ? environment.apiUrl : environment.apiAssetsUrl;
			this.fileUrl = fileBaseUrl + this.filePath;
    }
    return this;
  }

  set(obj: IRequirementFile) {
    Object.entries(obj).forEach(([key]) => (this[key] = obj[key]));

    return this;
  }

  clear() {
    Object.entries(this).forEach(([key]) => {
      this[key] = RequirementFile.clean[key];
    });

    return this;
  }

  clone() {
    return new RequirementFile().set(this);
  }
}
