import moment from 'moment';

export interface IBackendCredentialHistory {
	start_date             ?: Date;
	end_date               ?: Date;
	specific_date          ?: Date;
	updated_at             ?: Date;
	full_name              ?: string;
	current_hobbs1         ?: number;
	current_hobbs2         ?: number;
	current_tach1          ?: number;
	current_tach2          ?: number;
	previous_hobbs1        ?: number;
	previous_hobbs2        ?: number;
	previous_tach1         ?: number;
	previous_tach2         ?: number;
	equipment_id           ?: number;
	requirement_id         ?: number;
	user_id                ?: number;
	picture_file           ?: string;
	logbook_remarks        ?: string;
}

export interface ICredentialHistory {
	startDate            ?: Date;
	endDate              ?: Date;
	specificDate         ?: Date;
	resolvedAt           ?: Date;
	resolvedBy           ?: string;
	currentHobbs1        ?: number;
	currentHobbs2        ?: number;
	currentTach1         ?: number;
	currentTach2         ?: number;
	previousHobbs1       ?: number;
	previousHobbs2       ?: number;
	previousTach1        ?: number;
	previousTach2        ?: number;
	equipmentId          ?: number;
	credentialId         ?: number;
	pictureFile          ?: string;
	logbookRemarks       ?: string;
}

export class CredentialHistory implements ICredentialHistory {
	static readonly clean = Object.freeze(new CredentialHistory());
	startDate            ?: Date;
	endDate              ?: Date;
	specificDate         ?: Date;
	resolvedAt           ?: Date;
	resolvedBy           = '';
	currentHobbs1        = 0 ;
	currentHobbs2        = 0 ;
	currentTach1         = 0 ;
	currentTach2         = 0 ;
	previousHobbs1       = 0 ;
	previousHobbs2       = 0 ;
	previousTach1        = 0 ;
	previousTach2        = 0 ;
	equipmentId          = 0 ;
	credentialId         = 0 ;
	pictureFile          = '';
	logbookRemarks       = '';
	pictureFileIsImage          = false;

	parse(obj: IBackendCredentialHistory) {
		// const offset = Number(new Date().getTimezoneOffset() / 60);
		this.startDate            = obj.start_date    ? moment    (obj.start_date    ).toDate() : CredentialHistory.clean.startDate    ;
		this.endDate              = obj.end_date      ? moment    (obj.end_date      ).toDate() : CredentialHistory.clean.endDate      ;
		this.specificDate         = obj.specific_date ? moment    (obj.specific_date ).toDate() : CredentialHistory.clean.specificDate ;
		this.resolvedAt           = obj.updated_at    ? moment.utc(obj.updated_at    ).toDate() : CredentialHistory.clean.resolvedAt   ;
		// this.resolvedAt           = obj.updated_at ? moment.utc(obj.updated_at ).subtract(offset, 'hours').toDate() : CredentialHistory.clean.resolvedAt ;
		this.resolvedBy           = String('full_name'              in obj && obj.full_name              !== null && obj.full_name              !== undefined ? obj.full_name              : CredentialHistory.clean.resolvedBy           );
		this.currentHobbs1        = Number('current_hobbs1'         in obj && obj.current_hobbs1         !== null && obj.current_hobbs1         !== undefined ? obj.current_hobbs1         : CredentialHistory.clean.currentHobbs1        );
		this.currentHobbs2        = Number('current_hobbs2'         in obj && obj.current_hobbs2         !== null && obj.current_hobbs2         !== undefined ? obj.current_hobbs2         : CredentialHistory.clean.currentHobbs2        );
		this.currentTach1         = Number('current_tach1'          in obj && obj.current_tach1          !== null && obj.current_tach1          !== undefined ? obj.current_tach1          : CredentialHistory.clean.currentTach1         );
		this.currentTach2         = Number('current_tach2'          in obj && obj.current_tach2          !== null && obj.current_tach2          !== undefined ? obj.current_tach2          : CredentialHistory.clean.currentTach2         );
		this.previousHobbs1       = Number('previous_hobbs1'        in obj && obj.previous_hobbs1        !== null && obj.previous_hobbs1        !== undefined ? obj.previous_hobbs1        : CredentialHistory.clean.previousHobbs1       );
		this.previousHobbs2       = Number('previous_hobbs2'        in obj && obj.previous_hobbs2        !== null && obj.previous_hobbs2        !== undefined ? obj.previous_hobbs2        : CredentialHistory.clean.previousHobbs2       );
		this.previousTach1        = Number('previous_tach1'         in obj && obj.previous_tach1         !== null && obj.previous_tach1         !== undefined ? obj.previous_tach1         : CredentialHistory.clean.previousTach1        );
		this.previousTach2        = Number('previous_tach2'         in obj && obj.previous_tach2         !== null && obj.previous_tach2         !== undefined ? obj.previous_tach2         : CredentialHistory.clean.previousTach2        );
		this.equipmentId          = Number('equipment_id'           in obj && obj.equipment_id           !== null && obj.equipment_id           !== undefined ? obj.equipment_id           : CredentialHistory.clean.equipmentId          );
		this.credentialId         = Number('requirement_id'         in obj && obj.requirement_id         !== null && obj.requirement_id         !== undefined ? obj.requirement_id         : CredentialHistory.clean.credentialId         );
		this.pictureFile          = String('picture_file'           in obj && obj.picture_file           !== null && obj.picture_file           !== undefined ? obj.picture_file           : CredentialHistory.clean.pictureFile          );
		this.logbookRemarks       = String('logbook_remarks'        in obj && obj.logbook_remarks        !== null && obj.logbook_remarks        !== undefined ? obj.logbook_remarks        : CredentialHistory.clean.logbookRemarks       );

		const imageFormats = ['.jpg', '.png', '.gif', 'jpeg'];
		const pictureFileFormat = this.pictureFile.slice(-4);
		this.pictureFileIsImage          = Boolean(imageFormats.includes(pictureFileFormat));

		return this;
	}

	set(obj: ICredentialHistory) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = CredentialHistory.clean[key];
		});

		return this;
	}

	clone() {
		return new CredentialHistory().set(this);
	}
}
